import {
  Button,
  Col,
  message,
  Row,
  Typography,
  notification,
  Modal,
  Form,
} from 'antd';
import React, { FC, useEffect, useState } from 'react';
import useMintRequestPopup, { Props, ReceivedProps } from './hook';
import styles from './styles.module.scss';

import COPY_ICON from '../../../statics/images/icon-copy.svg';
import { useCallback } from 'react';

import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useProvider,
  useWaitForTransaction,
} from 'wagmi';
import { BigNumber, BigNumberish, ethers } from 'ethers';

import BcTokenAbi from "../../../common/BCToken.json";
import { MINT_REQUEST_HEX } from '../../../common/constants';
import { BcTokenFactory } from '../../../typechain/BcTokenFactory';
import { calCulateFlexAmount } from '../../../common/utils';

interface FormMintRequest {
  tokenType: string;
  amount: string;
  claimID: string;
  userName: number;
}

interface ButtonProps {
  contractAddress: string;
  value: BigNumberish;
  isMintRequest: boolean;
  claimId: string;
  claimAmount: BigNumber;
  mintRequested: boolean;
  setOpenMintRequestPopup: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMintStatus: () => Promise<void>;
}

const MintRequestButton: FC<ButtonProps> = (props) => {
  const { address } = useAccount();
  // const isValidMintAddress = ethers.utils.isAddress(props.address);
  const { config: configMintRequest } = usePrepareContractWrite({
    address: `0x${process.env.REACT_APP_COMMON_ADDRESS!.substring(2)}`,
    abi: BcTokenAbi.abi,
    functionName: 'mint_request',
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [
      props.claimId,
      // props.address,
      ethers.utils.parseUnits(props?.value.toString(), 18),
      props.claimAmount,
    ],
    onError(error: any) {
      console.log('error', error);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setErrorMessage("");
      console.log('ok');
    },
  });
  const {
    data,
    write,
    isLoading: writeLoading,
    reset,
    ...rest
  } = useContractWrite(configMintRequest);

  const { isSuccess, isLoading: processLoading,  } =
    useWaitForTransaction({
      hash: data?.hash,
    });

  const mint = useCallback(() => {    
    if (address && write) {
      write();
    }
  }, [address, write]);

  useEffect(() => {
    if (isSuccess) {
      props.setOpenMintRequestPopup(false);
      reset()
    }
  }, [isSuccess, props]);

  return (
    <>
      {!props.isMintRequest ? <p>* You are not in Mint Request List</p> : null}

      <Button
        onClick={mint}
        disabled={!props.isMintRequest || writeLoading || processLoading || props.mintRequested}
        loading={writeLoading || processLoading}
        style={{ color: 'black' }}
      >
        {props.mintRequested ? "Requested" : "Send Mint Request"}
      </Button>
    </>
  );
};


const MintRequestPopupLayout: FC<Props> = (props) => {
  const { openMintRequestPopup, claim, survey, setOpenMintRequestPopup } = props;
  const { address } = useAccount();

  const [messageApi, contextHolderMessage] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  // const [open, setOpen] = useState(false);

  const provider = useProvider();
  const [mintRequested, setMintRequested] = useState<boolean>(false);
  const [isMintRequest, setIsMintRequest] = useState<boolean>(false);
  const checkMintRequestRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(process.env.REACT_APP_COMMON_ADDRESS!, provider).hasRole(
        MINT_REQUEST_HEX,
        address
      );
      setIsMintRequest(role);
    }
  }, [address, provider]);

  const fetchMintStatus = useCallback(async () => {
    const data = await BcTokenFactory.connect(process.env.REACT_APP_COMMON_ADDRESS!, provider).get_mint_by_claim_id(claim?.claim_id);
    if (data[1]) {
      setMintRequested(true);
    } else {
      setMintRequested(false);
    }
  }, [claim, provider]);

  useEffect(() => {
    fetchMintStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMintRequestPopup, claim]);

  useEffect(() => {
    checkMintRequestRole();
    // getMintStatus();
  }, [address, checkMintRequestRole, provider]);

  const [amountFlex, setAmountFlex] = useState<BigNumber>();

  const getFlexAmount = useCallback(async () => {
    const data = await calCulateFlexAmount(
      survey?.total_celsius_earn_balances || 0
    );
    setAmountFlex(
      BigNumber.from((data * 1000).toFixed(0)).mul(BigNumber.from(10).pow(15))
    );
  }, [survey?.total_celsius_earn_balances]);

  useEffect(() => {
    getFlexAmount();
  }, [getFlexAmount]);

  return (
    <Row className={styles.container}>
      <Modal
        title={<Typography.Title level={2}>Mint request</Typography.Title>}
        open={openMintRequestPopup}
        onCancel={() => setOpenMintRequestPopup(false)}
        width="min(360px, calc(100% - 32px))"
        centered
        footer={false}
        className={styles['modal-container']}
      >
        <Form
          layout="vertical"
          className={styles['form-container']}
          form={form}
          onFinish={async (values: FormMintRequest) => {
            try {
            } catch (error: any) {
              api.error({
                message: `Error`,
                description: error.message,
                placement: 'top',
              });
            }
          }}
        >
          <Row>
            <Col span={24}>
              <Row
                style={{ borderBottom: '1px solid #E2E2E2', padding: '16px 0' }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Token type</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>Common Token</Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                style={{ borderBottom: '1px solid #E2E2E2', padding: '16px 0' }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Total Claim</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>
                    $
                    {survey?.total_celsius_earn_balances?.toFixed(
                      3
                    ) || 0}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                style={{ borderBottom: '1px solid #E2E2E2', padding: '16px 0' }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Claim ID</Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false}>
                    {claim?.claim_id}&nbsp;
                    <Button
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (claim?.claim_id) {
                          navigator.clipboard.writeText(
                            claim?.claim_id
                          );
                          messageApi.info('Copied ' + claim?.claim_id);
                        }
                      }}
                      style={{ padding: 0 }}
                    >
                      <img src={COPY_ICON} alt="COPY_ICON" />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* <Col span={24}>
              <Row
                style={{ borderBottom: '1px solid #E2E2E2', padding: '16px 0' }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Reduce claim Fee</Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false}>
                    {(amountFlex?.div(BigNumber.from(10).pow(15)).toNumber() ||
                      0) / 1000}{' '}
                    OX
                  </Row>
                </Col>
              </Row>
            </Col> */}
            {/* <Col span={24}>
              <Row
                style={{ padding: '20px 0' }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>User Email</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>
                    {survey?.celsius_email}
                  </Typography.Text>
                </Col>
              </Row>
            </Col> */}
          </Row>
          <Form.Item
            className={styles['form-container__item']}
            style={{ marginTop: 10 }}
          >
            <MintRequestButton
              value={survey?.total_celsius_earn_balances || 0}              
              contractAddress={process.env.REACT_APP_COMMON_ADDRESS!}
              isMintRequest={isMintRequest}
              claimId={claim?.claim_id || ''}
              fetchMintStatus={fetchMintStatus}
              mintRequested={mintRequested}
              setOpenMintRequestPopup={setOpenMintRequestPopup}
              claimAmount={amountFlex || BigNumber.from(0)}
            ></MintRequestButton>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

const MintRequestPopup: FC<ReceivedProps> = (props) => (
  <MintRequestPopupLayout {...useMintRequestPopup(props)} />
);

export default MintRequestPopup;

import {Collapse, Typography} from 'antd';
import useAccountBalance, { Props, ReceivedProps } from './hook';
import styles from './styles.module.scss';
import Balances from './Balances';
import TxHistory from './TxHistory';
import AccountStatement from './AccountStatement';
import Remarks from './Remarks';
import UpdatePrefrisk from './UpdatePrefrisk';
import NoteList from '../../../../components/NoteList';
import CryptoIcon from '../../../../components/CryptoIcon';

const AccountBalancesLayout: React.FC<Props> = ({
  balanceData,
  prices,
  txData,
  updateLevelDetailStatus,
  isAssigned,
  surveyData,
  exportAgreement,
  preview,
  setPreview,
  claimData,
  updateManualPrefRisk,
  prefRisk,
}) => {
  const accounts_and_balances_remark = claimData && {
    id: 1,
    date: 'Jan 17, 2023',
    text: claimData?.accounts_and_balances_remark?.text,
    pdf: claimData?.accounts_and_balances_remark?.files[0],
  };
  return (
    <div className={styles.container}>
      {/* BALANCES
      <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Balances</Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <Balances
            balanceData={balanceData}
            prices={prices}
            isAssigned={isAssigned}
            updateLevelDetailStatus={updateLevelDetailStatus}
            surveyData={surveyData}
          />
        </div>
      </div> */}
   
              <div
                className={
                  "divide-y divide-grey-100 sm:divide-y-0 " +
                  styles.table_balance
                }
              >
                {surveyData?.ftx_claim_data?.length ? (
                  surveyData?.ftx_claim_data?.map((item: any, index: number) => {
                    const icon = prices
                      .find((price: any) => price.coin === item.tokenName)
                      ?.coin?.toLowerCase();

                    return (
                      <div key={index} className="py-4 sm:py-0">
                        <div className="flex items-center">
                          {!!icon && (
                            <div className="mr-2">
                              <CryptoIcon name={icon} />
                            </div>
                          )}
                          <div className="text-sm font-semibold">
                            {item.tokenName}&nbsp;&nbsp;
                          </div>
                        </div>
                        <div className="mt-2.5 sm:mt-2 text-xs">
                          {(+item.Quantity).toFixed(12)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>N/A</p>
                )}
              </div>

      {/* Account statement */}
      {/* <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Account Statement</Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <AccountStatement
            surveyData={surveyData}
            setPreview={setPreview}
          />
        </div>
      </div> */}

      {/* TxHistory */}
      {/* <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Tx History</Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <TxHistory
              txData={txData}
              prices={prices}
              isAssigned={isAssigned}
              updateLevelDetailStatus={updateLevelDetailStatus}
              surveyData={surveyData}
              exportAgreement={exportAgreement}
              setPreview={setPreview}
          />
        </div>
      </div> */}

      {/* Calculated Preference risk */}
      {/* <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>
            Manually updated Preference Risk
          </Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <UpdatePrefrisk
            prefRisk={prefRisk}
            claimData={claimData}
            surveyData={surveyData}
            updateManualPrefRisk={updateManualPrefRisk}
            updateLevelDetailStatus={updateLevelDetailStatus}
            isAssigned={isAssigned}
          />
        </div>
      </div> */}

      {/* Remarks */}
      {/* <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Remarks</Typography.Text>
        </div>

        {(
          accounts_and_balances_remark &&
          (accounts_and_balances_remark.text !== undefined || accounts_and_balances_remark.pdf !== undefined)) && <NoteList
            dataList={accounts_and_balances_remark || {}}
            onDelete={() => {
              updateLevelDetailStatus('accounts_and_balances_remark', null);
            }}
          />}

        <div className={styles['block__content']}>
          <Remarks
            updateLevelDetailStatus={updateLevelDetailStatus}
            name='accounts_and_balances_remark'
            claimData={claimData}
          />
        </div>
      </div> */}
    </div>
  );
};

const AccountBalances: React.FC<ReceivedProps> = () => (
  <AccountBalancesLayout {...useAccountBalance()} />
);

export default AccountBalances;

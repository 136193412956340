export type ReceivedProps = Record<string, any>;

const useCelsiusDashboard = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useCelsiusDashboard>;

export default useCelsiusDashboard;

import { Typography, Tag, Col, Row, Divider, Tooltip } from 'antd';

import styles from './styles.module.scss';

// import { adminAPI, baseURL } from "../../../api/admin";
import DOUBTFUL_ICON from '../../../../statics/images/icon-doubtful.svg';
import REJECTED_ICON from '../../../../statics/images/icon-rejected.svg';
import VERIFIED_ICON from '../../../../statics/images/icon-verified.svg';
import { useContext, useMemo } from 'react';
import { omit } from '../../../../common/utils';
import { DetailContext } from '../DetailContextProvider';
import { BsInfoCircle } from 'react-icons/bs';

type Props = {};

const ClaimInformation: React.FC<Props> = () => {
  const { surveyData, claimData } = useContext(DetailContext);

  const data = useMemo(() => {
    return omit(
      {
        ...claimData,
        ...surveyData,
      },
      [
        'status', // auto admin status
        'celsius_account_acquired', // lvl 1 admin status
        'admin_status', // lvl 2 admin status
        'level3_details_check_1', // lv3 combined admin status
        // 'level3_details_check_2', // lv3 combined admin status
        // 'level3_details_check_3', // lv3 combined admin status
        // 'level3_details_check_4', // lv3 combined admin status
        'agreement_status', // legal admin status,
        'claim_type', // claim type
        'total_celsius_earn_balances', // total claim amount
        'total_celsius_custody_balances', // total claim amount
        'total_celsius_witheld_balances', // total claim amount
        'total_celsius_borrow_balances', // total claim amount
        'account_preference_risk', //auto risk
        'manual_preference_risk',
        'current_status',
        'level3_status',
        'note'
      ]
    );
  }, [surveyData, claimData]);

  console.log({data_test: data});
  return (
    <div className={styles.container}>
      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Auto Admin Status
        </Typography.Text>
        <div>
          {data && data['status'] === 'Approved' || data['status'] === 'SPV1' ? (
            <>
              <Col>
                <img src={VERIFIED_ICON} alt={'STATUS_ICON'} /> Approved
              </Col>
            </>
          ) : (
            <>
              <Col>
                <img src={REJECTED_ICON} alt={'STATUS_ICON'} /> Rejected
              </Col>
            </>
          )}
          {!!data?.note?.length && (
              <Row
                wrap={false}
                align={'middle'}
                style={{ position: 'relative' }}
              >
                <Typography.Text>
                  {JSON.parse(data?.note)?.join(', ')}
                </Typography.Text>
              
              </Row>
            )}
        </div>
      </div>
      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Level 1 Admin Status
        </Typography.Text>
        <div>
          {data && (
            <Row gutter={6} align="middle">
              <Tag
                style={{
                  ...(!data['celsius_account_acquired'] && {
                    background: '#BCBCBC',
                    border: '1px solid #BCBCBC',
                    borderRadius: 36,
                    color: '#FFFFFF',
                    padding: '5px 12px',
                  }),
                }}
              >
                {data['celsius_account_acquired'] ?? 'Pending'}
              </Tag>
            </Row>
          )}
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Level 2 Admin Status
        </Typography.Text>
        <div>
          {data && (
            <Row gutter={8} align="middle">
              {data['admin_status'] === 'Approved' && (
                <>
                  <Col>
                    <img src={VERIFIED_ICON} alt={'STATUS_ICON'} />
                  </Col>
                  <Col>
                    <Typography.Text>
                      {data['admin_status']}
                    </Typography.Text>
                  </Col>
                </>
              )}
              {data['admin_status'] === 'Rejected' && (
                <>
                  <Col>
                    <img src={REJECTED_ICON} alt={'STATUS_ICON'} />
                  </Col>
                  <Col>
                    {/* className=
                    {styles['information-block__description']} */}
                    <Typography.Text
                      className={
                        styles['information-block__description']
                      }
                    >
                      {data['admin_status']}
                    </Typography.Text>
                  </Col>
                </>
              )}
              {data['admin_status'] !== 'Rejected' &&
                data['admin_status'] !== 'Approved' && (
                  <>
                    <Col>
                      <Tag
                        style={{
                          background: '#BCBCBC',
                          border: '1px solid #BCBCBC',
                          borderRadius: 36,
                          color: '#FFFFFF',
                          padding: '5px 12px',
                        }}
                      >
                        Pending
                      </Tag>
                    </Col>
                  </>
                )}
            </Row>
          )}
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Level 3 Admin Status
        </Typography.Text>
        <div>
          {data && (
            <Row gutter={8} align="middle">
              {data.level3_status === 'level3_approved' ? (
                <>
                  <Col>
                    <img src={VERIFIED_ICON} alt={'STATUS_ICON'} />
                  </Col>
                  <Col>
                    <Typography.Text>Approved</Typography.Text>
                  </Col>
                </>
              ) : data.level3_status === 'level3_rejected' ? (
                <>
                  <Col>
                    <img src={REJECTED_ICON} alt={'STATUS_ICON'} />
                  </Col>
                  <Col>
                    <Typography.Text
                      className={
                        styles['information-block__description']
                      }
                    >
                      Rejected
                    </Typography.Text>
                  </Col>
                </>
              ) : data.level3_status === 'level3_doubtful' ? (
                <>
                  <Col>
                    <img src={DOUBTFUL_ICON} alt={'STATUS_ICON'} />
                  </Col>
                  <Col>
                    <Typography.Text
                      className={
                        styles['information-block__description']
                      }
                    >
                      Doubtful
                    </Typography.Text>
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <Tag
                      style={{
                        background: '#BCBCBC',
                        border: '1px solid #BCBCBC',
                        borderRadius: 36,
                        color: '#FFFFFF',
                        padding: '5px 12px',
                      }}
                    >
                      Pending
                    </Tag>
                  </Col>
                </>
              )}
            </Row>
          )}
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Legal Admin Status
        </Typography.Text>
        <div>
          {
            (!data || !data['agreement_status']) && (
              <>
                <Col>
                  <Tag
                    style={{
                      background: '#BCBCBC',
                      border: '1px solid #BCBCBC',
                      borderRadius: 36,
                      color: '#FFFFFF',
                      padding: '5px 12px',
                    }}
                  >
                    Pending
                  </Tag>
                </Col>
              </>
            )
          }
          {data && data['agreement_status'] && (
            <Row gutter={6} align="middle"
              style={{
                fontWeight: "bold"
              }}
            >
              {data['agreement_status']}
            </Row>
          )}
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Claim Type
        </Typography.Text>
        <div>
          {data && (
            <Row gutter={6} align="middle"
              style={{
                fontWeight: "bold"
              }}
            >
              Earn
            </Row>
          )}
        </div>
      </div>

      <Divider
        style={{
          width: '100%',
          margin: '25px -20px',
        }}
      />

      <div className={styles['amount-container']}>
        <div className={styles['information-block']}>
          <Typography.Text
            className={styles['information-block__label']}
          >
            Eligible Claim Amount in USD
          </Typography.Text>
          <div>
            <Typography.Text
              className={styles['information-block__description']}
            >
              ${data?.['total_celsius_earn_balances'] ? data?.['total_celsius_earn_balances'].toFixed(2) : 'N/A'}
            </Typography.Text>
          </div>
        </div>

        <div className={styles['information-block']}>
          <Typography.Text
            className={styles['information-block__label']}
          >
            Auto Preference Risk
          </Typography.Text>
          <div>
            <Typography.Text
              className={styles['information-block__description']}
            >
              {data?.['account_preference_risk'] ?? 'N/A'}
            </Typography.Text>
          </div>
        </div>

        <div className={styles['information-block']}>
          <Typography.Text
            className={styles['information-block__label']}
          >
            Calculated Preference Risk
          </Typography.Text>
          <div>
            <Typography.Text
              className={styles['information-block__description']}
            >
              {data?.manual_preference_risk ?? 'N/A'}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimInformation;

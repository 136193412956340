import React, { FC } from 'react';
import styles from './index.module.scss';
import { Button, Checkbox, Input } from 'antd';

type Step2Type = {
  onComplete: () => any;
  onBack: () => any;
  claim: any,
  survey: any,
  setCelsiusPassword: any,
  setAuthenDisabled: any,
}

const Step2:FC<Step2Type> = ({ onComplete, onBack, setAuthenDisabled, setCelsiusPassword }) => {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          Celsius Account
        </div>
        <div className={styles.contentSubTitle}>
          <span>
            Thanks for signing the Agreement.We’re almost there.
          </span>
          <span>
            Please sign into your Celsius account and disable 2-factor authentication.
          </span>
        </div>

        <div className={styles.formItem}>
          <div className={styles.formLabel}>Your Celsius Email <span className={styles.formLabelRequired}>*</span></div>
          <div className={styles.formValue}>
            <Input className={styles.formInput} name="input" value="hello@gmail.com" disabled />
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Please enter your Celsius Password <span className={styles.formLabelRequired}>*</span></div>
          <div className={styles.formSubTitle}>Please do not change your password after this</div>
          <div className={styles.formValue}>
            <Input className={styles.formInput} name="input" placeholder='Your password' onChange={(e) => {
              setCelsiusPassword(e.target.value)
            }}/>
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Disable any 2FA in your Celsius Account <span className={styles.formLabelRequired}>*</span></div>
          <div className={styles.formSubTitle}>Please do not enable 2FA again</div>
          <div className={styles.formValue}>
            <Checkbox onChange={(e) => setAuthenDisabled(e.target.checked)}>Yes</Checkbox>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button onClick={onBack} className={styles.back}>Back</Button>
        <Button onClick={onComplete} className={styles.submit}>Complete</Button>
      </div>
    </>
  )
}

export default Step2;

import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";

import styles from "./index.module.scss";
import filter_styles from "./Filter.module.scss";
import IconSearch from "../../../statics/images/icon-search.svg";
import TableHead from "./TableHead";
import TableRow from "./TableRow";
import { Input } from "antd";
import { adminAPI } from "../../../api/admin";

function TableClaimData() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState<number>(5);
  const [claimsCount, setClaimCount] = useState(0);
  const [claims, setClaims] = useState([]);

  const get_claims = async (page: number) => {
    const token = localStorage.getItem("token");
    let ret = await adminAPI("post", "/claims", {
      token,
      // limit: 25,
      // offset: 0,
      // contain_rejected: 0
    });
    console.log("ret", ret);
    if (ret.status === "FAILED" || !ret.status) {
      alert("Something wrong with your request");
    } else {
      setClaims(ret.message);
    }
  };
  const get_claims_count = async () => {
    let ret = await adminAPI("post", "/claimsCount", {
      contain_rejected: 0,
    });
    console.log(ret);
    if (ret.status === "FAILED" || !ret.status) {
      alert("Something wrong with your request");
    } else {
      setClaimCount(ret.message);
    }
  };

  const onChange: PaginationProps["onChange"] = (pageNumber) => {
    //console.log('Page: ', pageNumber);
    get_claims(pageNumber);
    setCurrentPage(pageNumber);
  };

  const search = async (keywords: string) => {
    console.log(keywords);
    const token = localStorage.getItem("token");
    let ret = await adminAPI("post", "/claims", {
      token,
      limit: 10,
      offset: 0,
      contain_rejected: 0,
      keywords,
    });
    console.log(ret);
    if (!ret.message) {
      alert("Something wrong with your request");
    } else {
      setClaims(ret.message);
    }
  };

  useEffect(() => {
    get_claims(1);
    get_claims_count();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <div className={filter_styles.wrapper}>
        <div className={filter_styles.search}>
          <Input
            // eslint-disable-next-line jsx-a11y/alt-text
            suffix={<img src={IconSearch} width={20} />}
            onChange={(e) => search(e.target.value.trim())}
            placeholder="Search for claim via ID here..."
            size="large"
          />
        </div>
        {/*<div className={filter_styles.filter}>
          <div className={classNames(filter_styles.filterItem, filter_styles.filterItemPc)}>
            <label>Filter by</label>
            <Select
              size='large'
              placeholder="Filter by"
              options={[
                { value: 'Claim Status', label: 'Jack' },
                { value: 'Bankruptcy Case', label: 'Lucy' },
              ]}
            />
          </div>
          <div className={classNames(filter_styles.filterItem, filter_styles.filterItemPc)}>
            <label>Sort by</label>
            <Select
              size='large'
              placeholder="Sort by"
              options={[
                { value: 'Claim Status', label: 'Jack' },
                { value: 'Bankruptcy Case', label: 'Lucy' },
              ]}
            />
          </div>

          <div className={classNames(filter_styles.filterItem, filter_styles.filterItemMb)}>
            <Button size='large'>
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#101828" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Button>
          </div>
          <div className={classNames(filter_styles.filterItem, filter_styles.filterItemMb)}>
            <Button size='large'>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.75 13.75L6.25 16.25L3.75 13.75" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.25 3.75V16.25" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.25 6.25L13.75 3.75L16.25 6.25" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.75 16.25V3.75" stroke="#111111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Button>
          </div>

        </div>*/}
      </div>
      <div className={styles.table}>
        <TableHead />
        {claims.map((claim, index) => (
          <TableRow claim={claim} index={index} />
        ))}
        <div className={styles.pagination}>
          <label>
            Show {claimsCount > 0 ? (currentPage - 1) * pageSize + 1 : 0} to{" "}
            {(currentPage - 1) * pageSize + claims.length} of {claimsCount}{" "}
            entries
          </label>
          <Pagination
            defaultCurrent={currentPage}
            total={claimsCount}
            defaultPageSize={pageSize}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
}

TableClaimData.propTypes = {};

export default TableClaimData;

import { Button, Form, Input, notification, Row } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adminAPI } from "../../api/admin";

// import { getFlexPriceAverange } from "../../common/utils";

import styles from "./index.module.scss";

interface FormLogin {
  email: string;
  password: string;
}

function LoginPage() {
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (values: FormLogin) => {
    try {
      // console.log("Flex price data: ", await getFlexPriceAverange());

      let response = await adminAPI("post", "/login", values);

      if (!response.status || response.status === "FAILED") {
        throw new Error(response.message);
      } else {
        const user = response.message;
        localStorage.setItem("token", user.token);
        localStorage.setItem("email", user.email);
        navigate("/admin");
      }
    } catch (error: any) {
      api.error({
        message: `Login Notification`,
        description: error.message,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");
      if (email && token) {
        const response = await adminAPI("post", "/verify", {
          email,
          token,
        });

        if (response.status === "OK") {
          navigate("/admin", { replace: true });
        }
      }
    })();
  }, [navigate]);

  return (
    <div className={styles.main}>
      {/* <Link to="/claim">
        <Button size='large' className={styles.btnLogin}>
          Sign in with
          <img src={LogoOPNX} alt="sign in with OPNX" width={50} height={18} />
        </Button>
      </Link> */}
      {contextHolder}
      <Form
        layout="vertical"
        className={styles["form-container"]}
        onFinish={onFinish}
      >
        <Row className={styles["form-container__item"]}>
          <Title level={2}>Sign in to Admin</Title>
        </Row>
        <Form.Item
          className={styles["form-container__item"]}
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Email is not a valid email!" },
          ]}
        >
          <Input autoComplete="username" />
        </Form.Item>
        <Form.Item
          className={styles["form-container__item"]}
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password autoComplete="current-password" />
        </Form.Item>
        <Form.Item className={styles["form-container__item"]}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

LoginPage.propTypes = {};

export default LoginPage;

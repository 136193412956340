import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useContext, useState } from "react";
import { DetailContext } from "../DetailContextProvider";

export type ReceivedProps = {};

const useSettings = () => {
  const {
    updateCelsiusAccountStatus,
    updateAdminStatus,
    claimData,
    handleCreation,
    isClaimIdExist,
    address,
    updateLevelDetailStatus,
    updateAgreementStatus,
    setRefresh,
    surveyData,
    adminlevel,
    openMintRequestPopup,
    setOpenMintRequestPopup,
    handleReschedule
  } = useContext(DetailContext);
  const [claimModal, setClaimModal] = useState<boolean>(false);

  return {
    level: adminlevel,
    claimData,
    updateCelsiusAccountStatus,
    updateAdminStatus,
    handleCreation,
    openMintRequestPopup,
    isClaimIdExist,
    address,
    updateLevelDetailStatus,
    surveyData,
    updateAgreementStatus,
    claimModal,
    setRefresh,
    setClaimModal,
    setOpenMintRequestPopup,
    handleReschedule
  };
};

export type Props = ReturnType<typeof useSettings>;

export default useSettings;

 const theme = {
  colorPrimary: '#037C7C',
  colorError: '#B11C18',
  colorTextBase: '#111111',
  colorTextSecondary: '#666666'

  // fontSizeXL: 60,
  // fontSizeLG: 48,
  // fontSizeSM: 30,
}

export default theme;

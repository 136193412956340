import {Typography, message, Divider, Select} from 'antd';

import styles from './styles.module.scss';

// import { adminAPI, baseURL } from "../../../api/admin";
import { useContext, useMemo } from 'react';
import { omit } from '../../../../common/utils';
import { DetailContext } from '../DetailContextProvider';
import VERIFIED_ICON from "../../../../statics/images/icon-verified.svg";
import REJECTED_ICON from "../../../../statics/images/icon-rejected.svg";
import DOUBTFUL_ICON from "../../../../statics/images/icon-doubtful.svg";

const ClaimGeneralInformation: React.FC = () => {
    const { isAssigned, updateLevelDetailStatus } = useContext(DetailContext);
  const { surveyData, claimData } = useContext(DetailContext);
  const data = useMemo(() => {
    return omit(
      {
        ...claimData,
        ...surveyData,
      },
      [
        'claim_id',
        'user_type',
        'attention',
        'legal_name',
        'is_usa',
        'country',
        'phone_number',
        'celsius_address',
        'celsius_email',
        'creditors_name',
        'is_soa_amount_agreed',
        'name_appear_poc',
        'creditors_name',
        'claim_link'
      ]
    );
  }, [claimData, surveyData]);
  const [messageApi] = message.useMessage();
  
  const nameAsClaim = useMemo(
    () =>
      data?.is_soa_amount_agreed ==='Yes' ? data?.creditors_name : (data?.name_appear_poc),
    [data]
  );

  return (
    <div className={styles.container}>
      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Claim ID
        </Typography.Text>

        <div>
          {
            <div>
              <div>
                <Typography.Text
                  className={styles['information-block__description']}
                >
                  {data['claim_id']}
                </Typography.Text>
              </div>
            </div>
          }
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Individual or a Corporation
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {data['user_type']}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Attention
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {(data['attention']) ? data['attention'] : 'N/A'}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Preferred Communication Email
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {surveyData?.contact_email}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          US person/US Corporation
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {data['is_usa']}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Country of Residency
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {data['country']}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Physical Address
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {data['celsius_address']}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Registered Celsius Email
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {data['celsius_email']}
          </Typography.Text>
        </div>
      </div>

      <div className={styles['information-block']}>
        <Typography.Text
          className={styles['information-block__label']}
        >
          Phone Number
        </Typography.Text>
        <div>
          <Typography.Text
            className={styles['information-block__description']}
          >
            {data['phone_number']}
          </Typography.Text>
        </div>
      </div>

      <Divider
          style={{
            width: '100%',
            margin: '25px -20px',
          }}
      />
        <div className={styles['information-block']}>
            <Typography.Text
                className={styles['information-block__label']}
            >
                Legal Name as per Claim Info
            </Typography.Text>

            <div>
                {
                    <div>
                        <div>
                            <Typography.Text
                                className={styles['information-block__description']}
                            >
                                {nameAsClaim}
                            </Typography.Text>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className={styles['information-block']}>
            <Typography.Text
                className={styles['information-block__label']}
            >
                Legal Name as per KYC
            </Typography.Text>
            <div>
              <div
                  className={styles['information-block__label']}
              >
                  {data['legal_name']}
              </div>
            </div>
        </div>
        {!!data['claim_link'] && <div className={styles['information-block']}>
            <Typography.Text
                className={styles['information-block__label']}
            >
                Schedule F Line 
            </Typography.Text>
            <div>
              <div
                  className={styles['information-block__label']}
              >
                  {data['claim_link']}
              </div>
            </div>
        </div>}
        <div className={styles['information-block']}>
        <Typography.Text
                className={styles['information-block__label']}
            >
                Status
            </Typography.Text>

            <div>
                {
                    <div>
                        <Select
                            placeholder="Select"
                            className={styles.select}
                            onChange={async (value) => {
                                const updateSurveyData = {
                                    ...surveyData,
                                    level3_legal_name_as_kyc_check: value,
                                };
                                await updateLevelDetailStatus(
                                    "survey",
                                    JSON.stringify(updateSurveyData)
                                );
                            }}
                            value={surveyData?.level3_legal_name_as_kyc_check}
                            disabled={!isAssigned}
                        >
                            <Select.Option value="Approved">
                                <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
                                &nbsp;Approved
                            </Select.Option>
                            <Select.Option value="Rejected">
                                <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
                                &nbsp;Rejected
                            </Select.Option>
                            <Select.Option value="Doubtful">
                                <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
                                &nbsp;Doubtful
                            </Select.Option>
                        </Select>
                    </div>
                }
            </div>
        </div>

    </div>
  );
};

export default ClaimGeneralInformation;

import React, { FC } from 'react';
import styles from './index.module.scss';
import ClapImg from '../../statics/images/clap.svg';
import { Button } from 'antd';

type StepResultType = {
  onPreview: () => any;
}

const StepResult: FC<StepResultType> = ({ onPreview }) => {
  return (
    <div className={styles.result}>
      <img src={ClapImg} alt="Clap" />
      <div className={styles.resultText}>
        Thanks! We’ll update as soon as control of the account has been transfffered.
      </div>
      <div className={styles.buttons}>
        <Button onClick={onPreview} className={styles.submit}>Preview survey again</Button>
      </div>
    </div>
  )
}

export default StepResult

export type ReceivedProps = Record<string, any>;

const useMintRequestPopup = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useMintRequestPopup>;

export default useMintRequestPopup;

import { Button, Typography } from "antd";
import styles from "./styles.module.scss";
import TRASH_ICON from "../../statics/images/icon-trash.svg";
import DOWNLOAD_ICON from "../../statics/images/icon-download.svg";
import { baseURL } from "../../api/admin";

type Props = {
  dataList: {
    id: string | number;
    date: string | number;
    text: string;
    pdf?: any;
  };
  withContainer?: boolean;
  onDelete?: () => void;
};

const NoteList: React.FC<Props> = ({
  dataList,
  withContainer = true,
  onDelete,
}) => {
  console.log(dataList);
  return (
    <div className={withContainer ? styles.container : ""}>
      <>
        <div key={dataList?.id} className={styles.list}>
          <div className={styles.information}>
            <Typography.Text className={styles.date}>
              {dataList?.date}
            </Typography.Text>
            <Typography.Text className={styles.description}>
              {dataList?.text}
            </Typography.Text>
            {dataList?.pdf && (
              <Button
                className={"file-action"}
                type="link"
                // onClick={exportAgreement}
                href={
                  baseURL +
                  "/files?name=" +
                  encodeURIComponent(dataList?.pdf) +
                  "&token=" +
                  localStorage.getItem("token")
                }
                target="_blank"
                rel="noreferrer"
              >
                <img src={DOWNLOAD_ICON} alt="DOWNLOAD_ICON" />
                {
                  dataList?.pdf?.split("/")[
                    dataList?.pdf?.split("/").length - 1
                  ]
                }
              </Button>
            )}
          </div>
          <div className={styles.actionContainer}>
            <Button
              className={styles.action}
              type="link"
              onClick={() => onDelete && onDelete()}
            >
              <img src={TRASH_ICON} alt="TRASH_ICON" />
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};

export default NoteList;

import { ClaimData, ScheduleFLine, SurveyData } from "../DetailContextProvider";

export type ReceivedProps = {
  claim: ClaimData;
  survey: SurveyData;
  celciusScheduleData: ScheduleFLine;
};

const useClaimAgreeement = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useClaimAgreeement>;

export default useClaimAgreeement;

import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import styles from "./index.module.scss";
import { adminAPI } from "../../api/admin";

export function StatsPage() {
  const [statsData, setStatsData] = useState<any>({});

  const getData = async () => {
    const res = await adminAPI("post", "/getAllStats");
    console.log(res, "resres");
    if (res.status === "OK") {
      setStatsData(res.message);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles["stats-page"]}>
      <Typography.Title level={3}>All Stats</Typography.Title>
      <Row gutter={[16, 24]}>
        <Col className="gutter-row" span={8}>
          <div className={styles["block-item"]}>
            <div className={styles["title"]}>Total settled claims</div>
            <div className={styles["description"]}>
              {statsData?.claimCelsius + statsData?.claimFTX || 0}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles["block-item"]}>
            <div className={styles["title"]}>Total settled CELSIUS claims</div>
            <div className={styles["description"]}>
              {statsData?.claimCelsius || 0}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles["block-item"]}>
            <div className={styles["title"]}>Total settled FTX claims</div>
            <div className={styles["description"]}>
              {statsData?.claimFTX || 0}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles["block-item"]}>
            <div className={styles["title"]}>Total settled claim amount</div>
            <div className={styles["description"]}>
              $
              {Number(statsData?.claimAmountFTX?.toFixed(3)) +
                Number(statsData?.claimAmountCelsius?.toFixed(3)) || 0}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles["block-item"]}>
            <div className={styles["title"]}>
              Total settled CELSIUS claim amount
            </div>
            <div className={styles["description"]}>
              ${statsData?.claimAmountCelsius?.toFixed(3) || 0}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div className={styles["block-item"]}>
            <div className={styles["title"]}>
              Total settled FTX claim amount
            </div>
            <div className={styles["description"]}>
              ${statsData?.claimAmountFTX?.toFixed(3) || 0}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

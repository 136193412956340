import { Tag } from "antd";
import { RxDotFilled } from "react-icons/rx";

export const CheckingTxt = ({ text }: any) => {
  const color = text === "Yes" || text === true ? "#13a1a1" : "#CF352A";
  return (
    <Tag
      className="outline"
      style={{
        color: color,
        border: "1px solid #E2E2E2",
        borderRadius: 36,
        padding: "5px 12px",
      }}
    >
      <RxDotFilled style={{paddingTop: '3px'}} color={color} />
      {text || "No"}
    </Tag>
  );
};

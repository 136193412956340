import React, { useState, useMemo, useCallback, useEffect } from "react";
// import PropTypes from 'prop-types'
import { Space, Typography, Steps } from "antd";

import $ from "jquery";

import { Converter } from "showdown";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
// import "survey-core/defaultV2.min.css";

import { json } from "./json";
import LogoCelsius from "../../statics/images/logo-celsius.svg";
import Clap from "../../statics/images/clap.svg";
import styles from "./index.module.scss";
// import { FunctionFactory } from "survey-core";
import { adminAPI, baseURL } from "../../api/admin";
import { classNames } from "../../common/utils";

// const description = 'This is a description.';
const items = [
  {
    title: "Step 01",
  },
  {
    title: "Step 02",
  },
  {
    title: "Step 03",
  },
  {
    title: "Step 04",
  },
];
//
// function getCoinName(params) {
//   //console.log(params);
//   let panel = this.survey.getQuestionByName(params[0]);
//   console.log(panel);
//   let dropdown = panel.templateValue.elementsValue[0];
//   console.log(dropdown);
//   let selectedItem = dropdown.choices.find(x=>x.selected);
//   console.log(selectedItem)
//   return "123";
//   // let dropdown = this.survey.getQuestionByName(params[0]);
//   // let selectedItem = dropdown.choices.find(x=>x.selected);
//   // if(!!selectedItem)
//   //   return selectedItem.text;
// }
//
// FunctionFactory.Instance.register("getCoinName", getCoinName);

// eslint-disable-next-line react-hooks/exhaustive-deps
const survey = new Model(json);
// eslint-disable-next-line react-hooks/exhaustive-deps
var converter = new Converter();

function ClaimPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);

  const alertResults = useCallback(async (sender: any) => {
    const results = JSON.stringify(sender.data);
    console.log(results);
    //generate Claim ID and submit to API
    const claim_id = Math.random().toString(36).substring(2, 14);
    let ret = await adminAPI("post", "/submitClaim", {
      claim_id,
      survey: results,
      claim_type: "celsius",
    });
    console.log(ret);
    if (ret.status === "OK") {

      setIsSuccess(true);

     } else {
      alert("Something wrong with your request");
    }
  }, []);

  useEffect(() => {
    // let celsius_balances1 = survey.getQuestionByName('celsius_balances1');
    // // console.log(celsius_balances1)
    // let coin_dropdown = celsius_balances1.templateElements[0];
    // //console.log(coin_dropdown)
    // // celsius_balances1.onItemValuePropertyChanged.add(function (sender, options) {
    // //         console.log(options);
    // // });
    // survey
    // .onValueChanged
    // .add(function (sender, options) {
    //     if(options.name == "celsius_balances1") {
    //         console.log(options)
    //         console.log(options.question.templateValue.elementsValue[0]);
    //         let dropdown = options.question.templateValue.elementsValue[0];
    //         let selectedItem = dropdown.choices.find(x=>x.selected);
    //         console.log(selectedItem);
    //
    //         //console.log(coin_dropdown.selectedChoices.map(item => item.coin));
    //         //
    //         // console.log(survey.getQuestionByName[options.name]);
    //         // let coin_dropdown = survey.getQuestionByName[options.name].templateElements[0];
    //         // console.log(coin_dropdown.title,coin_dropdown.value);
    //         // console.log(coin_dropdown);
    //         // console.log(coin_dropdown.getPlainData());
    //         // coin_dropdown.onPropertyValueChanging(function (coin_sender, coin_options) {
    //         //   console.log(coin_options);
    //         // })
    //         // var originalItem = coin_dropdown.getItemByValue(options.question.visibleChoices, options.value).originalItem;
    //         // console.log(originalItem);
    //     }
    // });
    survey.onTextMarkdown.add(function (survey, options) {
      //convert the markdown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });
    survey.onCurrentPageChanged.add((sender, options) => {
      setCurrentStep(options.newCurrentPage.num - 1);
    });
    survey.onComplete.add(alertResults);
    survey.onUploadFiles.add(function (survey, options) {
      console.log("onUploadFiles");
      options.files.forEach(function (file: any) {
        console.log(file);
        var formData = new FormData();
        formData.append("file", file);
        $.ajax({
          url: baseURL + "/upload",
          type: "POST",
          success: function (data: any) {
            var content =
              baseURL +
              "/files/" +
              data.message.replace("Uploaded the file successfully: ", "");
            options.callback("success", [
              {
                file: file,
                content: content,
              },
            ]);
          },
          error: function () {},
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SurveyComp = useMemo(
    () => (
      <Survey
        model={survey}
        questionErrorLocation="top"
        // eslint-disable-next-line react-hooks/exhaustive-deps
      />
    ),
    []
  );

  return (
    <div className={classNames("container", styles.main)}>
      <div className={styles.content}>
        {isSuccess ? (
          <div className={styles.success}>
            <img src={Clap} alt="clap" width={60} />
            <Typography.Title level={2}>
              Thank you for your request. <br />
              We will contact you via provided email.
            </Typography.Title>
            <Typography.Title level={2}></Typography.Title>
          </div>
        ) : (
          <>
            <Space
              direction="vertical"
              align="center"
              size={12}
              className={styles.pageHeader}
            >
              <img src={LogoCelsius} width={90} height={36} alt="celsius" />
              <Typography.Title level={2} className={styles.title}>
                Celsius Claim Questionaire
              </Typography.Title>
              <Typography.Text type="secondary" className={styles.description}>
                Lorem ipsum, or lipsum as it is sometimes known, is dummy text
                used in laying out print, graphic or web designs. The passage is
                attributed to an unknown ...
              </Typography.Text>
            </Space>

            <div className={styles.steps}>
              <Steps
                current={currentStep}
                labelPlacement="vertical"
                items={items}
              />
            </div>

            <div className={styles.card}>{SurveyComp}</div>
          </>
        )}
      </div>
    </div>
  );
}

ClaimPage.propTypes = {};

export default ClaimPage;

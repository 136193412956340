export const json = {
  // "title": "Celsius Claim Process",
  // "logo": "https://uploads-ssl.webflow.com/5f9a1900790900e2b7f25ba1/61013576bc0d7d98f1e66828_celsius-network-logo.png",
  // "logoWidth": "250px",
  // "logoHeight": "100px",
  // "logoPosition": "right",
  "completedHtml": "<h3>Thank you for your request. We will contact you via provided email.</h3>",
  "showQuestionNumbers": "off",
  "pages": [
    {
      "name": "personal_details",
      "elements": [
        {
          "type": "text",
          "name": "contact_email",
          "title": "Preferred Communication Email",
          "isRequired": true,
          "inputType": "email"
        },
        {
          "type": "dropdown",
          "allowClear": false,
          "name": "user_type",
          "title": "Are you an individual or corporate?",
          "isRequired": true,
          "choices": [
            "Individual",
            "Corporate"
          ]
        },
        {
          "type": "text",
          "name": "legal_name",
          "title": "Legal Name",
          "isRequired": true
        },
        {
          "type": "dropdown",
          "allowClear": false,
          "name": "is_usa",
          "title": "Are you a US person?",
          "isRequired": true,
          "choices": [
            "Yes",
            "No"
          ]
        },
        {
          "type": "dropdown",
          "allowClear": false,
          "name": "country",
          "title": "Country of Residency",
          "isRequired": true,
          "choicesByUrl": {
            "url": "https://surveyjs.io/api/CountriesExample"
          }
        }
      ],
      "title": "Personal Details"
    },
    {
      "name": "celsius_details",
      "elements": [
        {
          "type": "text",
          "name": "celsius_email",
          "title": "Celsius Email",
          "isRequired": true,
          "inputType": "email"
        },
        {
          "type": "text",
          "name": "celsius_address",
          "title": "Address registered with Celsius",
          "isRequired": true
        },
        {
          "type": "paneldynamic",
          "name": "celsius_balances",
          "title": "Enter Your Celsius Account Balances i.e Your Claim Amount",
          "isRequired": true,
          "templateElements": [
            {
              "type": "dropdown",
              "name": "Coin",
              "title": "Coin",
              "allowClear": false,
              "isRequired": true,
              "choicesByUrl": {
                "url": "https://heimdallrwa.com/prices.json",
                "titleName": "coin",
                "valueName": "price"
              }
            },
            {
              "type": "text",
              "name": "Quantity",
              "startWithNewLine": false,
              "title": "Quantity",
              "isRequired": true,
              "inputType": "number"
            },
            {
              "type": "expression",
              "name": "price",
              "startWithNewLine": false,
              "title": "USD Value as of July 13, 2022",
              "isRequired": true,
              "expression": "{panel.Coin}"
            },
            {
              "type": "expression",
              "name": "Claim Value",
              "title": "Claim Value",
              "expression": "{panel.Quantity} * {panel.price}",
              "displayStyle": "currency"
            }
          ]
        },
        {
          "type": "expression",
          "name": "totalClaim",
          "title": "Total Claim Value",
          "expression": "sumInArray({celsius_balances}, 'Claim Value')",
          "displayStyle": "currency"
        },
        {
          "type": "checkbox",
          "name": "celsius_relationship",
          "title": "What was your relatioship with Celsius? ( Select all that apply to you )",
          "isRequired": true,
          "choices": [
            "Depositor",
            "Vendor",
            "Employee",
            "Consultant",
            "Family of Employee",
            "Others"
          ]
        },
        {
          "type": "paneldynamic",
          "name": "celsius_total_withdrawns",
          "title": "Total Funds Withdrawn from Celsius between April 13, 2022 & July 13, 2022",
          "templateElements": [
            {
              "type": "dropdown",
              "name": "Coin",
              "title": "Coin",
              "allowClear": false,
              "isRequired": true,
              "choicesByUrl": {
                "url": "https://heimdallrwa.com/prices.json",
                "titleName": "coin",
                "valueName": "price"
              }
            },
            {
              "type": "text",
              "name": "Quantity",
              "startWithNewLine": false,
              "title": "Quantity",
              "isRequired": true,
              "inputType": "number"
            },
            {
              "type": "expression",
              "name": "price",
              "startWithNewLine": false,
              "title": "USD Value as of July 13, 2022",
              "isRequired": true,
              "expression": "{panel.Coin}"
            },
            {
              "type": "expression",
              "name": "Withdrawn Value",
              "title": "Withdrawn Value",
              "expression": "{panel.Quantity} * {panel.price}",
              "displayStyle": "currency"
            }
          ]
        },
        {
          "type": "expression",
          "name": "totalWithdrawn",
          "title": "Total Withdrawn Value",
          "expression": "sumInArray({celsius_total_withdrawns}, 'Withdrawn Value')",
          "displayStyle": "currency"
        },
        {
          "type": "paneldynamic",
          "name": "celsius_total_deposits",
          "title": "Total Funds deposited to Celsius between April 13, 2022 & July 13, 2022",
          "templateElements": [
            {
              "type": "dropdown",
              "name": "Coin",
              "title": "Coin",
              "allowClear": false,
              "isRequired": true,
              "choicesByUrl": {
                "url": "https://heimdallrwa.com/prices.json",
                "titleName": "coin",
                "valueName": "price"
              }
            },
            {
              "type": "text",
              "name": "Quantity",
              "startWithNewLine": false,
              "title": "Quantity",
              "isRequired": true,
              "inputType": "number"
            },
            {
              "type": "expression",
              "name": "price",
              "startWithNewLine": false,
              "title": "USD Value as of July 13, 2022",
              "isRequired": true,
              "expression": "{panel.Coin}"
            },
            {
              "type": "expression",
              "name": "Deposit Value",
              "title": "Deposit Value",
              "expression": "{panel.Quantity} * {panel.price}",
              "displayStyle": "currency"
            }
          ]
        },
        {
          "type": "expression",
          "name": "totalDeposit",
          "title": "Total Deposit Value",
          "expression": "sumInArray({celsius_total_deposits}, 'Deposit Value')",
          "displayStyle": "currency"
        },
        {
          "type": "file",
          "name": "celsius_statement",
          "title": "Upload latest Celsius Account Statement from your Celsius account",
          "isRequired": true,
          "storeDataAsText": false
        },
        {
          "type": "file",
          "name": "celsius_transactions",
          "title": "Account Activity (90-day pre-bankruptcy transaction history)",
          "isRequired": true,
          "storeDataAsText": false
        },
        {
          "type": "dropdown",
          "name": "celsius_vote",
          "title": "How did you cast your vote for the Celsius restructuring plan?",
          "isRequired": true,
          "allowClear": false,
          "choices": [
            "Voted in Favour",
            "Object the Plan",
            "No Action"
          ]
        },
      ],
      "title": "Celsius Details"
    },
    {
      "name": "stretto_celsius_details",
      "elements": [
        {
          "type": "dropdown",
          "name": "is_soa_available",
          "title": "Does your claim form part of the latest schedule of assets available on the administrator's portal? (https://cases.stretto.com/celsius/)",
          "isRequired": true,
          "allowClear": false,
          "choices": [
            "Yes",
            "No"
          ]
        },
        {
          "type": "dropdown",
          "name": "claim_schedule",
          "visibleIf": "{is_soa_available} = 'Yes'",
          "title": "Is your claim scheduled as disputed, contingent, or unliquidated",
          "requiredIf": "{is_soa_available} = 'Yes'",
          "allowClear": false,
          "choices": [
            "Yes",
            "No"
          ]
        },
        {
          "type": "dropdown",
          "name": "claim_offset",
          "visibleIf": "{is_soa_available} = 'Yes'",
          "title": "Does this claim has any offset?",
          "requiredIf": "{is_soa_available} = 'Yes'",
          "allowClear": false,
          "choices": [
           "Yes",
           "No"
          ]
        },
        {
          "type": "text",
          "name": "claim_link",
          "visibleIf": "{is_soa_available} = 'Yes'",
          "title": "Share the PDF link from the administrator's website and provide the page number where your claim is enumerated",
          "requiredIf": "{is_soa_available} = 'Yes'"
        },
        {
          "type": "dropdown",
          "name": "is_soa_amount_agreed",
          "visibleIf": "{is_soa_available} = 'Yes'",
          "title": "Do you agree with the claim amount as published in the schedule of assets?",
          "requiredIf": "{is_soa_available} = 'Yes'",
          "allowClear": false,
          "choices": [
            "Yes",
            "No"
          ]
        }
      ],
      "title": "Stretto/Celsius Details"
    },
    {
      "name": "stretto_celsius_details_2",
      "elements": [
        {
          "type": "dropdown",
          "name": "is_poc_filed",
          "title": "Have you filed a proof of claim?",
          "allowClear": false,
          "isRequired": true,
          "choices": [
            "Yes",
            "No"
          ]
        },
        {
          "type": "dropdown",
          "allowClear": false,
          "name": "is_poc_general_bar",
          "visibleIf": "{is_poc_filed} = 'Yes'",
          "title": "Did you file the proof of claim before 5pm ET on February 9, 2023, or the extended General Bar date?",
          "requiredIf": "{is_poc_filed} = 'Yes'",
          "choices": [
            "Yes",
            "No"
          ]
        },
        {
          "type": "dropdown",
          "allowClear": false,
          "name": "objection_filed_against_poc",
          "visibleIf": "{is_poc_filed} = 'Yes'",
          "title": "Are you aware of any objections filed or due to be filed against your Proof of Claim?",
          "choices": [
            "Yes",
            "No"
          ]
        },
        {
          "type": "text",
          "name": "poc_link",
          "visibleIf": "{is_poc_filed} = 'Yes'",
          "title": "Share the PDF link from the administrator's website that corresponds to your submitted proof of claim"
        }
      ],
      "visibleIf": "{is_soa_available} = 'No' or {is_soa_amount_agreed} = 'No'",
      "title": "Stretto/Celsius Details"
    },
    {
      "name": "page1",
      "elements": [
        {
          "type": "checkbox",
          "name": "stretto_message",
          "title": "Have you forwarded the e-mail onto: verifications@heimdallrwa.com",
          "choices": [
            {
              "value": "sent_email",
              "text": "I have done this"
            }
          ]
        }
      ],
      "title": "Get Official Admin Email",
      "description": "Visit [https://case.stretto.com/celsius/file-a-claim#](https://case.stretto.com/celsius/file-a-claim#) Enter your email registered with Celsius to get details about your claims from Stretto, forward the same email to : verifications@heimdallrwa.com"
    }
  ]
};

import { Button, Divider, Select, Typography } from "antd";
import styles from "./styles.module.scss";
import NoteList from "../../../../components/NoteList";
import DOUBTFUL_ICON from "../../../../statics/images/icon-doubtful.svg";
import REJECTED_ICON from "../../../../statics/images/icon-rejected.svg";
import VERIFIED_ICON from "../../../../statics/images/icon-verified.svg";
import EYE_ICON from "../../../../statics/images/icon-eye.svg";
import DOWNLOAD_ICON from "../../../../statics/images/icon-download.svg";
import { useContext, useMemo } from "react";
import { DetailContext } from "../DetailContextProvider";
import { omit } from "../../../../common/utils";
import Remarks from "../AccountBalance/Remarks";
import moment from "moment";
import { CheckingTxt } from "../../FTXDetail/CheckingTxt";
import { baseURL } from "../../../../api/admin";
const ProofOfClaim = () => {
  const {
    claimData,
    // preview,
    setPreview,
    surveyData,
    updateLevelDetailStatus,
    isAssigned,
    // exportAgreement,
  } = useContext(DetailContext);

  const proof_of_claim_remark = claimData && {
    id: 1,
    date: "Jan 17, 2023",
    text: claimData?.proof_of_claim_remark?.text,
    pdf: claimData?.proof_of_claim_remark?.files[0],
  };
  // console.log({proof_of_claim_remark: claimData});

  const data = useMemo(() => {
    return omit(
      {
        ...claimData,
        ...surveyData,
      },
      [
        "is_poc_filed",
        "objection_filed_against_poc",
        "is_poc_general_bar",
        "poc_file_number",
        "poc_file",
        "poc_link",
        "name_appear_poc"
      ]
    );
  }, [surveyData, claimData]);
  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["block"]}>
          <Typography.Text className={styles["block__title"]}>
            Notice of Transfer Filed
          </Typography.Text>
          <Typography.Text className={styles["block__desc"]}>
            <CheckingTxt text={data?.is_poc_filed} />
          </Typography.Text>
        </div>
        {/* <div className={styles["block"]}>
          <Typography.Text className={styles["block__title"]}>
            Date Filed
          </Typography.Text>
          <Typography.Text className={styles["block__desc"]}>
            {data["is_poc_general_bar"]
              ? moment(data["is_poc_general_bar"]).format("DD/MM-YYYY - HH:mm")
              : "N/A"}
          </Typography.Text>
        </div> */}
        
        <div className={styles["block"]}>
          <Typography.Text className={styles["block__title"]}>
          Objections Against the Notice
          </Typography.Text>
          <Typography.Text className={styles["block__desc"]}>
            <CheckingTxt text={data?.is_poc_registered} />
          </Typography.Text>
        </div>
        <div className={styles["block"]}>
          <Typography.Text className={styles["block__title"]}>
            Status
          </Typography.Text>
          <div className={styles["block__desc"]}>
            <Select
              placeholder="Select"
              className={styles.select}
              onChange={async (value) => {
                const updateSurveyData = {
                  ...surveyData,
                  level3_proof_of_claim_check: value,
                };

                await updateLevelDetailStatus(
                  "survey",
                  JSON.stringify(updateSurveyData)
                );
              }}
              value={surveyData?.level3_proof_of_claim_check}
              disabled={!isAssigned}
            >
              <Select.Option value="Approved">
                <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
                &nbsp;Approved
              </Select.Option>
              <Select.Option value="Rejected">
                <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
                &nbsp;Rejected
              </Select.Option>
              <Select.Option value="Doubtful">
                <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
                &nbsp;Doubtful
              </Select.Option>
            </Select>
          </div>
        </div>
        {/*<div className={styles['block']}>*/}
        {/*  <Typography.Text className={styles['block__title']}>*/}
        {/*    Notice of Transfer Filed Against*/}
        {/*  </Typography.Text>*/}
        {/*  <Typography.Text className={styles['block__desc']}>*/}
        {/*    {data['objection_filed_against_poc'] ?? 'N/A'}*/}
        {/*  </Typography.Text>*/}
        {/*</div>*/}
        <div className={styles["block"]}>
          <Typography.Text className={styles["block__title"]}>
            Claim Number
          </Typography.Text>
          <Typography.Text className={styles["block__desc"]}>
            {data["poc_file_number"] ?? "N/A"}
          </Typography.Text>
        </div>
        <div className={styles["block"]}>
          <Typography.Text className={styles["block__title"]}>
            Name as per Notice of Transfer
          </Typography.Text>
          <Typography.Text className={styles["block__desc"]}>
          {data?.["name_appear_poc"]}
          </Typography.Text>
        </div>
        <div className={styles["block"]}></div>
        <Divider style={{ margin: 0 }} />
        <div className={styles["block_lg"]}>
          <Typography.Text className={styles["block__title"]}>
            Notice of Transfer PDF
          </Typography.Text>
          <div className={styles["block__desc"]}>
            {data["poc_link"] ? (
              <div className={styles["action-wrapper"]}>
                <Button
                  className={"file-action"}
                  type="link"
                  onClick={() => setPreview(baseURL +"/files?name=" +
                  encodeURIComponent(data?.poc_link?.[0]?.name) + "&token=" + localStorage.getItem('token'))}
                >
                  <img src={EYE_ICON} alt="EYE_ICON" />
                  View
                </Button>
                <Button
                  className={"file-action"}
                  type="link"
                  style={{
                    marginLeft: 16,
                  }}
                >
                  <a  target="_blank"
                  rel="noreferrer"
                  href={baseURL +"/files?name=" +
                  encodeURIComponent(data?.poc_link?.[0]?.name) + "&token=" + localStorage.getItem('token')}>
                  <img src={DOWNLOAD_ICON} alt="DOWNLOAD_ICON" />
                  Download
                  </a>
                </Button>
              </div>
            ) : (
              <div className={styles["action-wrapper"]}>
                <span>
                  <Typography.Text className={styles["block__desc"]}>
                    N/A
                  </Typography.Text>
                </span>
              </div>
            )}
          </div>
        </div>
        <Divider style={{ margin: 0 }} />
        <div className={styles["block_lg"]}>
          <Typography.Text className={styles["block__title"]}>
            Remarks
          </Typography.Text>
          <div className={styles["block__desc"]}>
            {proof_of_claim_remark &&
              (proof_of_claim_remark.text !== undefined ||
                proof_of_claim_remark.pdf !== undefined) && (
                <NoteList
                  withContainer={false}
                  dataList={proof_of_claim_remark || {}}
                  onDelete={() => {
                    updateLevelDetailStatus("proof_of_claim_remark", null);
                  }}
                />
              )}
          </div>
        </div>
        <Divider style={{ margin: 0 }} />
        <div className={styles["block_lg"]}>
          <Remarks
            updateLevelDetailStatus={updateLevelDetailStatus}
            name="proof_of_claim_remark"
            claimData={claimData}
          ></Remarks>
        </div>
      </div>
    </>
  );
};

export default ProofOfClaim;

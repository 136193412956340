import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Typography,
} from "antd";
import React, { FC } from "react";
import { adminAPI } from "../../../api/admin";
import useNewAdmin, { Props, ReceivedProps } from "./hook";
import styles from "./styles.module.scss";

interface FormNewAdmin {
  email: string;
  password: string;
  level: number;
  address: string;
}

const NewAdminLayout: FC<Props> = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  return (
    <Row gutter={40} className={styles.container}>
      {contextHolder}
      <Col span={24}>
        <Row justify={"center"}>
          <Col>
            <Typography.Title level={2}>Add new admin</Typography.Title>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify={"center"}>
          <Col span={18}>
            <Form
              layout="vertical"
              form={form}
              className={styles["form-container"]}
              onFinish={async ({ email, password, level, address }: FormNewAdmin) => {
                try {
                  const login_email = localStorage.getItem("email");
                  const login_token = localStorage.getItem("token");

                  let response = await adminAPI("post", "/register", {
                    email,
                    password,
                    level: level.toString(),
                    login_email,
                    login_token,
                    address,
                  });

                  if (!response.message)
                    throw new Error("Something wrong with your request");
                  if (response.status === "FAILED")
                    throw new Error(response.message);
                  api.success({
                    message: `Success`,
                    description: "Your account has been created successfully",
                    placement: "top",
                  });
                  form.resetFields();
                } catch (error: any) {
                  api.error({
                    message: `Error`,
                    description: error.message,
                    placement: "top",
                  });
                }
              }}
            >
              <Typography.Title level={3}>Admin information</Typography.Title>
              <Form.Item
                className={styles["form-container__item"]}
                label="Amin email "
                name="email"
                rules={[
                  { required: true, message: "Please input admin email!" },
                  { type: "email", message: "Email is not a valid email!" },
                ]}
              >
                <Input autoComplete="username" />
              </Form.Item>
              <Form.Item
                className={styles["form-container__item"]}
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input admin password!" },
                ]}
              >
                <Input.Password autoComplete="current-password" />
              </Form.Item>
              <Form.Item
                className={styles["form-container__item"]}
                label="Level (1-4)"
                name="level"
                rules={[
                  { required: true, message: "Please input admin level!" },
                ]}
              >
                <InputNumber min={1} max={4} />
              </Form.Item>
              <Form.Item
                className={styles["form-container__item"]}
                label="Address"
                name="address"
                rules={[
                  { required: false, message: "Please input admin address!" },
                ]}
              >
                <Input autoComplete="address" />
              </Form.Item>
              <Form.Item className={styles["form-container__item"]}>
                <Button type="primary" htmlType="submit">
                  Add new
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const NewAdmin: FC<ReceivedProps> = (props) => (
  <NewAdminLayout {...useNewAdmin(props)} />
);

export default NewAdmin;

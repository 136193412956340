import {
  Button,
  Col,
  message,
  Row,
  Table,
  Tag,
  Typography,
  notification,
  TabsProps,
  Tabs,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useSearchParams } from 'react-router-dom';
import React, { FC, useEffect, useMemo, useState } from 'react';
import useMintToken, { Props, ReceivedProps } from './hook';
import styles from './styles.module.scss';
import CHEVRON_UP_ICON from '../../statics/images/icon-chevron-up-thin.svg';
import CHEVRON_DOWN_ICON from '../../statics/images/icon-chevron-down-thin.svg';
import COPY_ICON from '../../statics/images/icon-copy.svg';
import { useCallback } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import {
  useAccount,
  useContractWrite as contractWrite,
  useDisconnect,
  usePrepareContractWrite as prepareContractWrite,
  useProvider,
  useWaitForTransaction,
} from 'wagmi';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
// import QUESTION_MARK_ICON from '../../statics/images/icon-question-mark.svg';
// import REJECTED_ICON from '../../statics/images/icon-rejected.svg';
// import VERIFIED_ICON from '../../statics/images/icon-verified.svg';
// import CHECK_ICON from '../../statics/images/icon-check.svg';
// import X_ICON from '../../statics/images/icon-x.svg';
import {
  ADMIN_HEX,
  ADMIN_STATUS,
  APPROVAL_HEX,
  BURNER_HEX,
  DENOMINATOR,
  MINT_REQUEST_HEX,
  NATIVE_TOKEN_SYMBOL,
} from '../../common/constants';
import { BigNumber } from 'ethers';
import BcTokenABi from '../../common/BCToken.json';
import { BcTokenFactory } from '../../typechain/BcTokenFactory';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { adminAPI } from '../../api/admin';
import { useSelector } from 'react-redux';

interface MintRequest {
  claimId: string;
  mintTo: string;
  minted_amount: BigNumber;
  amount: BigNumber;
  minting_fee: number;
  multiSignatureSize: number;
  approvedBy: string[];
  hash: string;
}

interface ButtonProps {
  claimId: string;
  setRequesting: any;
  amount: any;
  minting_fee: any;
  approvedBy: string[]
  multiSignatureSize: number;
  requesting: boolean;
  messageApi: any
  adminlevel: number
}

const ApproveButton: FC<ButtonProps> = (props) => {
  console.log(props);
  const { address } = useAccount();
  const { config: configApproval } = prepareContractWrite({
    address: `0x${process.env.REACT_APP_FTX_COMMON_ADDRESS!.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: 'approve_mint_by_claim_id',
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [props.claimId],
    onError(error: any) {
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setErrorMessage("");
    },
  });
  const {
    data,
    write,
    isLoading: writeLoading,
  } = contractWrite(configApproval);
  const { isSuccess, isLoading: waitLoading } = useWaitForTransaction(
    {
      hash: data?.hash,
    }
  );

  const addTxsHash = useCallback(async () => {
    try {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');

      let response = await adminAPI('post', '/addTxsHashFtx', {
        email,
        token,
        claim_id: props.claimId,
        hash: data?.hash,
        token_minted: props.amount || '0',
        minting_fee: props.minting_fee || 0
      });
      if (response.status === 'FAILED')
        throw new Error(response.message);
      props.messageApi?.success('Approval successful')
    } catch (error: any) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.claimId, isSuccess]);
  
  useEffect(() => {
    if (isSuccess && props.approvedBy.length === +props.multiSignatureSize - 1) {
      addTxsHash();
      props.setRequesting(true);
    }
  }, [isSuccess]);

  const approve = useCallback(async () => {
    if((props?.adminlevel > 3)) {
      props.messageApi?.info('You are not admin level 1, 2, 3')
      return
    }
    if (address && write) {
      write();
    }
  }, [address, write, props]);

  return (
    <Button onClick={approve} loading={writeLoading || waitLoading}>
      Approve
    </Button>
  );
};

const size = 5

type TableData = MintRequest & { key: number };

const MintTokenLayout: FC<Props> = (_props) => {
  const provider = useProvider();
  const { address } = useAccount();
  const [contractAddess, setContractAddress] = useState<string>(process.env.REACT_APP_FTX_COMMON_ADDRESS!);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isApproval, setIsApproval] = useState<boolean>(false);
  const [isBurner, setIsBurner] = useState<boolean>(false);
  const [isMintRequest, setIsMintRequest] = useState<boolean>(false);
  const [mintRequests, setMintRequests] = useState<TableData[]>([]);
  const [totalMintRequest, setTotalMintRequest] = useState<number>(0);
  const [mintingFee, setMintingFee] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [feeCollector, setFeeCollector] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setloadingTable] = useState(false)

  const getMintRequest = useCallback(async () => {
    setloadingTable(true)
    const response = await adminAPI('post', '/claimsFtxMintable', {limit: +size,
      offset: (+currentPage - 1) * +size,});
    if(response.status === 'OK') {
      const claims = response.message
      const mintRequests: any = await Promise.all(claims.map(async (claim: any, index: number) => {
        const resOn = await BcTokenFactory.connect(process.env.REACT_APP_FTX_COMMON_ADDRESS!, provider).get_mint_by_claim_id(
          claim?.claim_id
        );
        let data = {
          key: index + 1,
          mintTo: resOn['0'],
          claimId: resOn['1'],
          amount: resOn['2'],
          multiSignatureSize: resOn['3'],
          approvedBy: resOn['4'],
          minting_fee: resOn['5'],
          minted_amount: resOn['7']
        };
        return {
          ...claim,
          ...data,
          // claim_amount: survey?.totalClaim || 0,
        };
      }))
      setMintRequests(mintRequests);
      setTotalMintRequest(response.total);
      setloadingTable(false)
    }
  }, [provider, currentPage]);

  const getMintingFee = useCallback(async () => {
    const data = await BcTokenFactory.connect(
      process.env.REACT_APP_FTX_COMMON_ADDRESS!,
      provider
    ).get_minting_fee();
    setMintingFee(data / DENOMINATOR);
  }, [provider]);

  const getFeeCollector = useCallback(async () => {
    const data = await BcTokenFactory.connect(
      process.env.REACT_APP_FTX_COMMON_ADDRESS!,
      provider
    ).get_fee_collector();
    setFeeCollector(data);
  }, [provider]);

  const setAddress = (key: string) => {
    if (key === '1') {
      setContractAddress(process.env.REACT_APP_FTX_COMMON_ADDRESS!);
    } else {
      setContractAddress(process.env.REACT_APP_FTX_COMMON_ADDRESS!);
    }
  };

  const checkApprovalRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(
        process.env.REACT_APP_FTX_COMMON_ADDRESS!,
        provider
      ).hasRole(APPROVAL_HEX, address);
      console.log('checkApprovalRole', role, address);
      setIsApproval(role);
    }
  }, [address, provider]);

  const checkAdminRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(
        process.env.REACT_APP_FTX_COMMON_ADDRESS!,
        provider
      ).hasRole(ADMIN_HEX, address);
      setIsAdmin(role);
    }
  }, [address, provider]);

  const checkBurner = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(
        process.env.REACT_APP_FTX_COMMON_ADDRESS!,
        provider
      ).hasRole(BURNER_HEX, address);
      setIsBurner(role);
    }
  }, [address, provider]);

  const checkMintRequest = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(
        process.env.REACT_APP_FTX_COMMON_ADDRESS!,
        provider
      ).hasRole(MINT_REQUEST_HEX, address);
      setIsMintRequest(role);
    }
  }, [address, provider]);

  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    checkApprovalRole();
    checkAdminRole();
    checkBurner();
    checkMintRequest();
    getMintingFee();
    getFeeCollector();
  }, [
    address,
    checkAdminRole,
    checkApprovalRole,
    checkBurner,
    contractAddess,
    getFeeCollector,
    getMintingFee,
    checkMintRequest,
    requesting,
  ]);

  useEffect(() => {
    getMintRequest();
  }, [address, getMintRequest, currentPage, requesting]);

  const { open: openWeb3 } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect({
    onError(error: any) {
      console.log('Error', error);
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  // const { current = 1, size = 10 } = Object.fromEntries(
  //   Array.from(searchParams)
  // );
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const adminlevel = useSelector((state: any) => state.admin.level);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<TableData> | SorterResult<TableData>[],
    extra: TableCurrentDataSource<TableData>
  ) => {
    console.log('pagination', pagination);
    const paramsObject = Object.fromEntries(Array.from(searchParams));

    switch (extra.action) {
      case 'paginate': {
        if (pagination.current) {
          setCurrentPage(pagination.current);
          paramsObject.current = pagination.current.toString();
        }

        if (pagination.pageSize)
          paramsObject.size = pagination.pageSize.toString();
        break;
      }

      default:
        break;
    }

    setSearchParams(paramsObject);
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState<
    {
      rowKey: number;
      columnKey: 'approval_required';
    }[]
  >([]);

  const columns: ColumnsType<TableData> = useMemo(
    () => [
      {
        title: 'Claim Id',
        key: 'claimId',
        render: ({ claimId }: TableData) => {
          return <Typography.Text>{claimId}</Typography.Text>;
        },
      },
      {
        title: 'Minto',
        key: 'minto',
        render: ({ mintTo }: TableData) => {
          return (
            <Row align="middle" wrap={false}>
              <Typography.Text>
                {mintTo.slice(0, 6)}...{mintTo.slice(-4)}
              </Typography.Text>
              &nbsp;
              <Button
                type="link"
                onClick={() => {
                  navigator.clipboard.writeText(mintTo);
                  messageApi.info(`Copied ${mintTo}`);
                }}
                style={{ padding: 0 }}
              >
                <img src={COPY_ICON} alt="COPY_ICON" />
              </Button>
            </Row>
          );
        },
      },
      {
        title: 'Claim Amount',
        key: 'minted_amount',
        width: 200,
        render: ({ minted_amount,  approvedBy, multiSignatureSize }: TableData) => {
          return (
            <>
              {approvedBy.length == multiSignatureSize ? (
                <Typography.Text>
                  ${(+minted_amount.toString() / 10 ** 18 ).toFixed(2)}
                </Typography.Text>
              ) : (
                <Typography.Text>
                  0.00
                </Typography.Text>
              )}
            </>
          );
        },
      },
      {
        title: 'Amount',
        key: 'amount',
        width: 200,
        render: ({ amount }: TableData) => {
          return (
            <Typography.Text>
              {(+amount.toString() / 10 ** 18 ).toFixed(2)} {' '}
              Common Tokens
            </Typography.Text>
          );
        },
      },
      {
        title: 'Approved by',
        key: 'approved_by',
        render: ({ key, approvedBy }: TableData) => {
          const existKey = expandedRowKeys.findIndex(
            ({ rowKey, columnKey }) =>
              rowKey === key && columnKey === 'approval_required'
          );

          return (
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                existKey < 0
                  ? setExpandedRowKeys((prevState) => {
                    const existState = prevState.findIndex(
                      (item) => item.rowKey === key
                    );
                    if (existState < 0)
                      return [
                        ...prevState,
                        {
                          rowKey: key,
                          columnKey: 'approval_required',
                        },
                      ];
                    prevState[existState].columnKey =
                      'approval_required';
                    return [...prevState];
                  })
                  : setExpandedRowKeys((prevState) =>
                    prevState.filter((item) => item.rowKey !== key)
                  );
              }}
            >
              <Row align="middle" wrap={false}>
                Approval ({approvedBy.length})&nbsp;
                {existKey < 0 ? (
                  <img
                    src={CHEVRON_DOWN_ICON}
                    alt="CHEVRON_DOWN_ICON"
                  />
                ) : (
                  <img src={CHEVRON_UP_ICON} alt="CHEVRON_UP_ICON" />
                )}
              </Row>
            </Button>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        render: ({
          approvedBy,
          multiSignatureSize,
          hash,
        }: TableData) => {
          return (
            <>
              {approvedBy.length < multiSignatureSize ? (
                <Tag icon={<ClockCircleOutlined />} color="default">
                  Pending
                </Tag>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="success"
                    style={{ marginRight: 10 }}
                  >
                    minted
                  </Tag>
                  {hash && (
                    <div
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_EXPLORER_URL}${hash}`
                        );
                      }}
                    >
                      Transaction
                    </div>
                  )}
                </div>
              )}
            </>
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: ({
          approvedBy,
          multiSignatureSize,
          key,
          claimId,
          amount,
          minting_fee
        }: TableData) => {
          return (
            <>
              {isApproval &&
                address &&
                approvedBy.length < multiSignatureSize + 1 &&
                !approvedBy.includes(address || '') && (
                  <ApproveButton
                    claimId={claimId}
                    setRequesting={setRequesting}
                    amount={amount}
                    requesting={requesting}
                    minting_fee={minting_fee}
                    approvedBy={approvedBy}
                    multiSignatureSize={multiSignatureSize}
                    messageApi={messageApi}
                    adminlevel={adminlevel}
                  ></ApproveButton>
                )}
              {approvedBy.length < multiSignatureSize + 1 &&
                approvedBy.includes(address || '') && (
                  <Button disabled>Approved</Button>
                )}
            </>
          );
        },
      },
    ],
    [messageApi, expandedRowKeys, isApproval, address, requesting, adminlevel]
  );

  useEffect(() => {
    // get_stats();
    // getPrices();
    // get_claims_count();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTagChildren = (key: string) => {
    return (
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>
            General Information
          </Typography.Title>
        </Col>
        <Col
          span={24}
          style={{
            borderRadius: 8,
            border: '1px solid #F2F4F7',
            background: '#fff',
            padding: 10,
          }}
        >
          <Row>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                  borderBottom: '1px solid #EDEDED',
                }}
              >
                <Col>
                  <Typography.Text>
                    Token contract addreses
                  </Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false}>
                    <Typography.Text strong>
                      {contractAddess?.slice(0, 6)}...
                      {contractAddess?.slice(-4)}
                    </Typography.Text>
                    &nbsp;
                    <Button
                      type="link"
                      onClick={() => {
                        navigator.clipboard.writeText(process.env.REACT_APP_FTX_COMMON_ADDRESS!);
                        messageApi.info(`Copied ${process.env.REACT_APP_FTX_COMMON_ADDRESS!}`);
                      }}
                      style={{ padding: 0 }}
                    >
                      <img src={COPY_ICON} alt="COPY_ICON" />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                  borderBottom: '1px solid #EDEDED',
                }}
              >
                <Col>
                  <Typography.Text>
                    IS approval role (approve mint request)
                  </Typography.Text>
                </Col>
                <Col>
                  {isApproval ? (
                    <Tag
                      className="outline"
                      style={{
                        color: '#31B43E',
                      }}
                    >
                      ● YES
                    </Tag>
                  ) : (
                    <Tag
                      className="outline"
                      style={{
                        color: '#CF352A',
                      }}
                    >
                      ● NO
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                  borderBottom: '1px solid #EDEDED',
                }}
              >
                <Col>
                  <Typography.Text>
                    IS admin role (Add/ remove approval/burner roles)
                  </Typography.Text>
                </Col>
                <Col>
                  {isAdmin ? (
                    <Tag
                      className="outline"
                      style={{
                        color: '#31B43E',
                      }}
                    >
                      ● YES
                    </Tag>
                  ) : (
                    <Tag
                      className="outline"
                      style={{
                        color: '#CF352A',
                      }}
                    >
                      ● NO
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                  borderBottom: '1px solid #EDEDED',
                }}
              >
                <Col>
                  <Typography.Text>Heimdall Address</Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false}>
                    <Typography.Text strong>
                      {feeCollector.slice(0, 6)}...
                      {feeCollector.slice(-4)}
                    </Typography.Text>
                    &nbsp;
                    <Button
                      type="link"
                      onClick={() => {
                        navigator.clipboard.writeText(feeCollector);
                        messageApi.info(`Copied ${feeCollector}`);
                      }}
                      style={{ padding: 0 }}
                    >
                      <img src={COPY_ICON} alt="COPY_ICON" />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                }}
              >
                <Col>
                  <Typography.Text>
                    IS burner role (Burn token from any account)
                  </Typography.Text>
                </Col>
                <Col>
                  {isBurner ? (
                    <Tag
                      className="outline"
                      style={{
                        color: '#31B43E',
                      }}
                    >
                      ● YES
                    </Tag>
                  ) : (
                    <Tag
                      className="outline"
                      style={{
                        color: '#CF352A',
                      }}
                    >
                      ● NO
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                }}
              >
                <Col>
                  <Typography.Text>Heimdall Fee</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{mintingFee}%</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  height: '100%',
                  padding: 20,
                }}
              >
                <Col>
                  <Typography.Text>
                    IS mint request role (Create mint request)
                  </Typography.Text>
                </Col>
                <Col>
                  {isMintRequest ? (
                    <Tag
                      className="outline"
                      style={{
                        color: '#31B43E',
                      }}
                    >
                      ● YES
                    </Tag>
                  ) : (
                    <Tag
                      className="outline"
                      style={{
                        color: '#CF352A',
                      }}
                    >
                      ● NO
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: 30 }}>
          <Typography.Title level={3}>
            List of mint request
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={mintRequests}
            bordered={false}
            loading={loadingTable}
            className={styles['table-container']}
            expandable={{
              expandedRowRender: ({ approvedBy }: TableData) => {
                return (
                  <Row style={{ padding: 10 }}>
                    {approvedBy.map((admin: string) => (
                      <Col span={24}>
                        <Row align="middle" wrap={false}>
                          {admin.slice(0, 6)}...{admin.slice(-4)}
                          &nbsp;
                          <Button
                            type="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(admin);
                              messageApi.info('Copied ' + admin);
                            }}
                            style={{ padding: 0 }}
                          >
                            <img src={COPY_ICON} alt="COPY_ICON" />
                          </Button>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                );
              },
              expandedRowKeys: expandedRowKeys.map(
                ({ rowKey }) => rowKey
              ),
              showExpandColumn: false,
            }}
            pagination={{
              // position: ['bottomCenter'],
              pageSize: size,
              // current: +current,
              total: totalMintRequest,
            }}
            // loading={loading}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    );
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Common Token`,
      children: renderTagChildren('1'),
    },
    {
      key: '2',
      label: `Preference Risk Token`,
      children: renderTagChildren('2'),
    },
  ];

  return (
    <Row className={styles.container}>
      {contextHolderMessage}
      {contextHolder}
      <Col span={24}>
        <Row gutter={0} style={{ padding: 20 }}>
          <Col span={20}>
            <Typography.Title level={3}>My Account</Typography.Title>
            {address && (
              <Row gutter={[40, 20]}>
                <Col>
                  <Row align={'middle'} style={{ height: '100%' }}>
                    <Row align={'middle'} style={{ height: '100%' }}>
                      <Typography.Text>
                        Account address:&nbsp;
                      </Typography.Text>
                    </Row>

                    <Row align="middle" wrap={false}>
                      <Typography.Text strong>
                        {address.slice(0, 6)}...{address.slice(-4)}
                      </Typography.Text>
                      &nbsp;
                      <Button
                        type="link"
                        onClick={() => {
                          if (address) {
                            navigator.clipboard.writeText(address);
                            messageApi.info(`Copied ${address}`);
                          }
                        }}
                        style={{
                          padding: 0,
                          border: 'none',
                          height: 'fit-content',
                        }}
                      >
                        <img src={COPY_ICON} alt="COPY_ICON" />
                      </Button>
                    </Row>
                  </Row>
                </Col>
                <Col>
                  <Row align={'middle'} style={{ height: '100%' }}>
                    <Typography.Text>
                      Balance:{' '}
                      <Typography.Text strong>
                        {balance / 10000} {NATIVE_TOKEN_SYMBOL}
                      </Typography.Text>
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
          <Col
            span={4}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="primary"
              style={{
                borderRadius: 5,
                boxShadow: 'none',
                minHeight: 40,
                minWidth: 118,
                backgroundColor: '#03ABAB',
              }}
              onClick={async () => {
                if (!address) {
                  openWeb3();
                } else {
                  await disconnectAsync();
                }
              }}
            >
              <Typography.Text
                strong
                style={{
                  color: '#fff',
                }}
              >
                {!address ? 'Connect' : 'Disconnect'}
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ padding: 20 }}>
        <Tabs items={items} onChange={(key) => setAddress} />
      </Col>
    </Row>
  );
};

const FTXMintToken: FC<ReceivedProps> = (props) => (
  <MintTokenLayout {...useMintToken(props)} />
);

export default FTXMintToken;

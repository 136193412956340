import {
  Button,
  Col,
  message,
  Row,
  Table,
  Tag,
  Typography,
  notification,
  Modal,
  Form,
  Carousel,
  Tooltip,
  Input,
  Switch,
} from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { adminAPI } from '../../api/admin';
import useCelsiusDashboard, { Props, ReceivedProps } from './hook';
import styles from './styles.module.scss';
import CHEVRON_UP_ICON from '../../statics/images/icon-chevron-up-thin.svg';
import CHEVRON_DOWN_ICON from '../../statics/images/icon-chevron-down-thin.svg';
import NO_DOT_ICON from '../../statics/images/no-dot.svg';
import YES_DOT_ICON from '../../statics/images/yes-dot.svg';
import COPY_ICON from '../../statics/images/icon-copy.svg';
import { useCallback } from 'react';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import QUESTION_MARK_ICON from '../../statics/images/icon-question-mark.svg';
import REJECTED_ICON from '../../statics/images/icon-rejected.svg';
import VERIFIED_ICON from '../../statics/images/icon-verified.svg';
import CHECK_ICON from '../../statics/images/icon-check.svg';
import X_ICON from '../../statics/images/icon-x.svg';
import CHEVRON_LEFT_ICON from '../../statics/images/icon-chevron-left.svg';
import CHEVRON_RIGHT_ICON from '../../statics/images/icon-chevron-right.svg';
import { CarouselRef } from 'antd/es/carousel';
import CryptoIcon from '../../components/CryptoIcon';
import {
  useAccount,
  // useDisconnect,
  usePrepareContractWrite,
  useContractWrite,
  useProvider,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import BcTokenABi from '../../common/BCToken.json';
import {
  MINT_REQUEST_HEX,
  CELSIUS
} from '../../common/constants';
// import { AiFillCloseCircle } from "react-icons/ai";
// import { HiCheckCircle } from "react-icons/hi";
import {calCulateFlexAmount, survey_obj, truncDigits} from '../../common/utils';
import { BsInfoCircle } from 'react-icons/bs';
import { BcTokenFactory } from '../../typechain/BcTokenFactory';
import debounce from 'lodash.debounce';
import IconSearch from '../../statics/images/icon-search.svg';
import moment from 'moment-timezone';

interface SurveyData {
  celsius_address: string;
  celsius_balances: Array<any>;
  celsius_email: string;
  celsius_relationship: string[];
  celsius_statement: Array<any>;
  celsius_total_deposits: Array<any>;
  celsius_total_withdrawns: Array<any>;
  celsius_transactions: Array<any>;
  celsius_vote: string;
  claim_link: string;
  claim_offset: string;
  claim_schedule: string;
  contact_email: string;
  country: string;
  is_soa_amount_agreed: string;
  is_soa_available: string;
  is_usa: string;
  legal_name: string;
  stretto_message: string[];
  totalClaim: number;
  totalDeposit: number;
  totalWithdrawn: number;
  user_type: string;
  objection_filed_against_poc: string;
  is_poc_general_bar: string;
  poc_link: string;
  claim_schedule_status: string;
  is_poc_filed: string;
  celsius_transactions_manual: Array<any>;
  celsius_transactions_files: Array<any>;
  preference_risk: number;
  preferenceRisk: number;
  celsius_earn_balances?: number;
  account_preference_risk?: number;
  total_celsius_earn_balances?: number;
  total_celsius_custody_balances?: number;
  total_celsius_witheld_balances?: number;
  total_celsius_borrow_balances?: number;
}

type DataType = {
  applicationDate: string;
  claimID: string;
  assignedAdmin: string;
  claimAmount: number;
  preferenceRisk: string;
  celsiusInformation: string;
  currentStatus: string;
  celsiusBalances: any;
  celsiusNewEmail: string;
  celsiusEmail: string;
  celsiusNewPwd: string;
  l2AdminStatus: string;
  level3DetailsCheck1: string;
  celsiusStatementLevel3Check: string;
  proofOfClaimLevel3Check: string;
  status: string;
  agreementStatus: string;
  celsiusAccountAcquired: string;
  hash: string;
  survey: SurveyData;
  note: string[];
  level3Status: string;
};

type TableData = DataType & { key: number };

interface FormMintRequest {
  tokenType: string;
  amount: string;
  claimID: string;
  userName: number;
}

interface ButtonProps {
  contractAddress: string;
  value: BigNumberish;
  isMintRequest: boolean;
  claimId: string;
  claimAmount: BigNumber;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getClaimsHandler: (controller: AbortController) => Promise<void>;
  fetchMintStatus: () => Promise<void>;
}

const MintRequestButton: FC<ButtonProps> = (props) => {
  const { address } = useAccount();
  const [isFirstSuccess, setIsFirstSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { config: configMintRequest } = usePrepareContractWrite({
    address: `0x${process.env.REACT_APP_COMMON_ADDRESS!.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: 'mint_request',
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [
      props.claimId,
      // props.address,
      BigNumber.from(Number(props.value).toFixed(0)).mul(
        BigNumber.from(10).pow(18)
      ),
      props.claimAmount,
    ],
    onError(error: any) {
      console.log('error', error);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setErrorMessage("");
      console.log('ok');
    },
  });
  const {
    write,
    isLoading: writeLoading,
    isSuccess,
    ...rest
  } = useContractWrite(configMintRequest);
  console.log(rest);
  const mint = useCallback(() => {
    if (address && write) {
      write();
    }
  }, [address, write]);

  useEffect(() => {
    if (isSuccess && !isFirstSuccess) {
      setLoading(true);
      setTimeout(() => {
        props.setOpen(false);
        props.fetchMintStatus();
        props.getClaimsHandler(new AbortController());
        setIsFirstSuccess(true);
        setLoading(false);
      }, 5000);
    }
  }, [isFirstSuccess, isSuccess, props]);

  return (
    <>
      {!props.isMintRequest ? (
        <p>* You are not in Mint Request List</p>
      ) : null}

      <Button
        onClick={mint}
        disabled={
          !props.isMintRequest || writeLoading
        }
        loading={loading && writeLoading}
        style={{ color: 'black' }}
      >
        Send Mint Request
      </Button>
    </>
  );
};

type Stat =
  | undefined
  | 'SPV0'
  | 'SPV1'
  | 'Rejected'
  | 'manual_verified'
  | 'manual_pending'
  | 'filed_with_court'
  | 'legal_admin_pending'
  | 'legal_admin_approved'
  | 'level1_pending'
  | 'level1_approved'
  | 'level2_pending'
  | 'level2_approved'
  | 'level3_pending'
  | 'level3_approved'
  | 'SPV_issued'
  | 'SPV_issued_with_preference';

function preferenceRisk(survey: any) {
  return (
      <>
        {parseFloat(survey.account_preference_risk) <= 0 ? (
            <>
              <span>Not Flagged</span>
            </>
        ) : parseFloat(survey.account_preference_risk) > 0 ? (
            <>
              <div className={styles.subTableCell}>
                <div className={styles.info}>
                <span style={{ alignSelf: 'center' }}>
                  Estimated: ${truncDigits(parseFloat(survey.account_preference_risk), 2)}
                </span>
                </div>
              </div>
            </>
        ) : null}
      </>
  );
}

function preference(survey: any) {
  return (
    <>
      {parseFloat(survey.account_preference_risk) <= 0 ? (
        <Row align={'middle'}>
          <img src={REJECTED_ICON} alt={'REJECTED_ICON'} />
          &nbsp;Not Flagged
        </Row>
      ) : parseFloat(survey.account_preference_risk) < 100000 ? (
        <>
          <Row align={'middle'}>
            <img src={VERIFIED_ICON} alt={'VERIFIED_ICON'} />
            &nbsp;Flagged
          </Row>

          <Row wrap={false} align={'middle'}>
            <span style={{ fontSize: '12px' }}>
              Net:{' '}
              {truncDigits(
                parseFloat(survey.account_preference_risk),
                2
              )}{' '}
              USD
            </span>
            &nbsp;
            <Tooltip
              placement="topLeft"
              title={
                'Net amount is Total Withdrawal - Total Deposit within the 90 day window between April 14, 2022 & July 13, 2022'
              }
            >
              <div>
                {' '}
                <BsInfoCircle style={{ cursor: 'pointer' }} />
              </div>
            </Tooltip>
          </Row>
        </>
      ) : null}
    </>
  );
}

const CelsiusDashboardLayout: FC<Props> = (_props) => {
  const { address } = useAccount();
  const { open: openWeb3 } = useWeb3Modal();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { current = 1, size = 10 } = Object.fromEntries(
    Array.from(searchParams)
  );
  const [messageApi, contextHolderMessage] = message.useMessage();
  const [claims, setClaims] = useState<TableData[]>([]);

  const [mintRequested, setMintRequested] = useState<string[]>([]);
  const [minted, setMinted] = useState<string[]>([]);
  const [claimsCount, setClaimCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [prices, setPrices] = useState<any>([]);
  const [claimStat, setClaimStat] = useState<Stat>(undefined);
  const [stats, setStats] = useState<
    | (Record<NonNullable<Stat>, number> & {
      total: number;
    })
    | null
  >(null);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [dataMintRequest, setDataMintRequest] =
    useState<TableData | null>(null);
  const [assginMe, setAssginMe] = useState<boolean>(false);

  const provider = useProvider();
  const [isMintRequest, setIsMintRequest] = useState<boolean>(false);
  const checkMintRequestRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(
        process.env.REACT_APP_COMMON_ADDRESS!,
        provider
      ).hasRole(MINT_REQUEST_HEX, address);
      console.log('checkMintRequestRole', role, address);
      setIsMintRequest(role);
    }
  }, [address, provider]);

  const fetchMintStatus = useCallback(async () => {
    if (claims && claims.length > 0) {
      const data = await Promise.all(
        claims.map((item: any) => {
          return BcTokenFactory.connect(
            process.env.REACT_APP_COMMON_ADDRESS!,
            provider
          ).get_mint_by_claim_id(item.claimID);
        })
      );
      console.log(
        '🚀 ~ file: index.tsx:327 ~ fetchMintStatus ~ data:',
        data
      );

      const result = data
        .filter((item) => item[1] !== '')
        .map((item) => item[1]);
      setMintRequested(result);
      const result2 = data
        .filter((item) => item[1] !== '')
        .filter((item) => item[3] === item[4].length)
        .map((item) => item[1]);
      setMinted(result2);
    }
  }, [claims, provider]);

  useEffect(() => {
    fetchMintStatus();
  }, [fetchMintStatus]);

  useEffect(() => {
    checkMintRequestRole();
    // getMintStatus();
  }, [address, checkMintRequestRole, provider]);

  const [amountFlex, setAmountFlex] = useState<BigNumber>();

  const getFlexAmount = useCallback(async () => {
    const data = await calCulateFlexAmount(
      dataMintRequest?.survey?.total_celsius_earn_balances || 0
    );
    setAmountFlex(
      BigNumber.from((data * 1000).toFixed(0)).mul(
        BigNumber.from(10).pow(15)
      )
    );
  }, [dataMintRequest?.survey?.total_celsius_earn_balances]);

  useEffect(() => {
    getFlexAmount();
  }, [getFlexAmount, dataMintRequest]);

  const getPrices = useCallback(async () => {
    try {
      const result = await fetch(
        'https://heimdallrwa.com/prices.json'
      ).then((response) => response.json());
      if (Array.isArray(result)) setPrices(result);
    } catch (error) {
      //
    }
  }, []);

  // const get_claims_count = useCallback(async () => {
  //   try {
  //     const response = await adminAPI('post', '/claimsCount', {
  //       contain_rejected: 1,
  //     });
  //     if (response.status === 'FAILED' || !response.status) {
  //       throw new Error('Something wrong with your request');
  //     }
  //     setClaimCount(response.message);
  //   } catch (error: any) {
  //     api.error({
  //       message: `Notification`,
  //       description: error.message,
  //     });
  //   }
  // }, [api]);

  const getClaimsHandler = useCallback(
    async (controller: AbortController) => {
      try {
        //will remove
        if (claimStat === 'SPV_issued_with_preference') {
          setClaims([]);
          setClaimCount(0);
          return;
        }
        setLoading(true);

        const {
          current = 1,
          size = 10,
          ...paramsObject
        } = Object.fromEntries(Array.from(searchParams));

        let search: Record<string, any> = {};

        Object.keys(paramsObject).forEach((key) => {
          if (key.startsWith('search_')) {
            search = {
              ...search,
              ...(paramsObject[key] && {
                [key.replace('search_', '')]: paramsObject[key],
              }),
            };
          }
        });

        const token = localStorage.getItem('token');

        const response = await await adminAPI('post', '/claims', {
          token,
          limit: +size,
          offset: (+current - 1) * +size,
          keywords: search.claim_id ?? '',
          contain_rejected: 1,
          filter: (() => {
            let filter = {};

            switch (claimStat) {
              case 'SPV0':
              case 'SPV1':
              case 'Rejected':
                filter = { hash: null,
                  $or: [
                  {status: 'Rejected',},
                  {admin_status: 'Rejected',}
                  ], };
                break;

              case 'manual_pending':
              case 'level3_pending':
                filter = {
                  $and: [
                    {status: { $ne: 'Rejected' }},
                    {level3_status: { $ne: 'level3_doubtful' }},
                    {level3_status: { $ne: 'level3_rejected' }},
                    {level3_status: { $ne: 'level3_approved' }},
                    {admin_status: { $ne: 'Approved' }},
                  ]
                };
                break;

              case 'manual_verified':
              case 'level3_approved':
                filter = {
                  level3_details_check_1: 'Verified',
                  level3_details_check_2: 'Verified',
                  level3_details_check_3: 'Verified',
                  level3_details_check_4: 'Verified',
                };
                break;

              case 'level2_pending':
                filter = {
                  $and: [
                    {status: { $ne: 'Rejected' }},
                    {level3_status: 'level3_approved'},
                    {admin_status: { $ne: 'Approved' }},
                  ]
                };
                break;

              case 'level2_approved':
                filter = {
                  level3_details_check_1: 'Verified',
                  level3_details_check_2: 'Verified',
                  level3_details_check_3: 'Verified',
                  level3_details_check_4: 'Verified',
                  admin_status: 'Approved',
                };
                break;

              case 'level1_pending':
                filter = {
                  $and: [
                    {status: { $ne: 'Rejected' }},
                    {admin_status: 'Approved'},
                    {level3_status: 'level3_approved'},
                    {hash: null}
                  ],
                  $or: [
                    {agreement_status: 'Transfer Approved by Court'},
                    {celsius_account_acquired: { $ne: 'Account Acquired'} },
                  ]
                };
                break;

              case 'SPV_issued':
                filter = {
                  level3_details_check_1: 'Verified',
                  level3_details_check_2: 'Verified',
                  level3_details_check_3: 'Verified',
                  level3_details_check_4: 'Verified',
                  admin_status: 'Approved',
                  is_minted: true,
                };
                break;

              case 'filed_with_court':
                filter = {
                  $and: [
                    { hash: { $ne: null} },
                  ],
                };
                break;

              case 'legal_admin_pending':
                filter = {
                  $and: [
                    {status: { $ne: 'Rejected' }},
                    {admin_status: 'Approved'},
                    {level3_status: 'level3_approved'},
                    {agreement_status: { $ne: 'Transfer Approved by Court' }},
                    {celsius_account_acquired:  'Account Acquired' },
                    {hash: null}
                  ]
                };
                break;

              default:
                break;
            }
            if(assginMe) {
              const email = localStorage.getItem("email");
              filter = {...filter, assigned_to: email}
            }
            return JSON.stringify({ claim_type: CELSIUS, ...filter });
          })(),
        });

        // console.log(JSON.parse(response.message[0].survey));
        // console.log(response.message[0]);

        const data = response.message.map((item: any) => {
          const survey = JSON.parse(item.survey);

          return {
            key: item._id,
            applicationDate: item.created_time
              ? new Date(item.created_time).toLocaleDateString(
                'en-US'
              )
              : '',
            claimID: item.claim_id,
            assignedAdmin: item.assigned_to ?? 'Not Assigned',
            claimAmount: survey.totalClaim,
            preferenceRisk: 'YES',
            celsiusInformation: survey.celsius_email,
            level3Status: item.level3_status,
            currentStatus: (() => {
              // if (item.status !== 'SPV0') return 'Auto - Rejected';
              let current_status = 'L3 - Pending';
              if (item.status === 'Rejected')
                current_status = 'Auto - Rejected';

              let level3Status = item.level3_status;

              // if (item.level3_celsius_statement_check) {
              //   level3Status.push(
              //     item.level3_celsius_statement_check
              //   );
              // }

              // if (item.level3_proof_of_claim_check) {
              //   level3Status.push(item.level3_proof_of_claim_check);
              // }

              if (level3Status === 'level3_doubtful')
                current_status = 'L3 - Doubtful';
              if (level3Status === 'level3_rejected')
                current_status = 'L3 - Rejected';
              if (level3Status === 'level3_approved')
                current_status = 'L3 - Approved';
              // if (level3Status.every((value) => value === 'Verified'))
              //   current_status = 'L3 - Verified';

              if (current_status === 'L3 - Approved') {
                //Check Level 2
                if (item.admin_status !== 'Approved')
                  if (item.admin_status === 'Rejected')
                    current_status = 'L2 - Rejected';
                  else current_status = 'L2 - Pending';
                else current_status = 'L2 - Approved';
              }
              if (current_status === 'L2 - Approved') {
                if (item.agreement_status)
                  current_status = item.agreement_status;
              }

              if (current_status === 'Transfer Agreement Signed') {
                //Check Level 1
                if (
                  item.celsius_account_acquired === 'Account Acquired'
                )
                  current_status = 'L1 - Account Acquired';
                else current_status = 'L1 - Account Not Acquired';
              }

              return current_status;
            })(),
            celsiusBalances: [
              ...(survey.celsius_earn_balances || []),
              // ...(survey.celsius_custody_balances || []),
              // ...(survey.celsius_witheld_balances || []),
              // ...(survey.celsius_borrow_balances || []),
            ],
            celsiusNewEmail: item.new_account_email,
            celsiusNewPwd: item.new_account_password,
            celsiusEmail: survey.celsius_email,
            l2AdminStatus: item.admin_status,
            level3DetailsCheck1: item.level3_details_check_1 || '',
            celsiusStatementLevel3Check:
              item.level3_celsius_statement_check || '',
            proofOfClaimLevel3Check:
              item.level3_proof_of_claim_check || '',
            status: item.status || '',
            agreementStatus: item.agreement_status,
            celsiusAccountAcquired:
              item.celsius_account_acquired || '',
            survey: survey,
            hash: item.hash,
            note: JSON.parse(item.note) || [],
          };
        });

        if (!response.message)
          throw new Error('Something wrong with your request');

        if (!controller.signal.aborted) {
          setClaims(data);
          setClaimCount(response.total);
        }
      } catch (error: any) {
        api.error({
          message: `Notification`,
          description: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [api, searchParams, claimStat, assginMe]
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<TableData> | SorterResult<TableData>[],
    extra: TableCurrentDataSource<TableData>
  ) => {
    const paramsObject = Object.fromEntries(Array.from(searchParams));

    switch (extra.action) {
      case 'paginate': {
        if (pagination.current)
          paramsObject.current = pagination.current.toString();

        if (pagination.pageSize)
          paramsObject.size = pagination.pageSize.toString();
        break;
      }

      default:
        break;
    }

    setSearchParams(paramsObject);
  };

  const renderTagPref = (pref: number | string | undefined) => {
    if (!pref || +pref <= 0) {
      return (
        <Tag
          className="fill"
          style={{
            color: '#CF352A',
            background: '#FFFFFF',
            border: '1px solid #E2E2E2',
          }}
        >
          <Row gutter={6} align="middle">
            <Col>
              <Row align="middle">
                <img src={NO_DOT_ICON} alt={'NO_DOT_ICON'} />
              </Row>
            </Col>
            <Col>
              <Typography.Text style={{ color: '#CF352A' }}>
                NO
              </Typography.Text>
            </Col>
          </Row>
        </Tag>
      );
    }
    if (+pref < 100000) {
      return (
        <Tag
          className="fill"
          style={{
            color: '#31B43E',
            background: '#FFFFFF',
            border: '1px solid #E2E2E2',
          }}
        >
          <Row gutter={6} align="middle">
            <Col>
              <Row align="middle">
                <img src={YES_DOT_ICON} alt={'YES_DOT_ICON'} />
              </Row>
            </Col>
            <Col>
              <Typography.Text style={{ color: '#31B43E' }}>
                YES
              </Typography.Text>
            </Col>
          </Row>
        </Tag>
      );
    }

    return null;
  };

  const renderTagCheckingDetails = (status: string | undefined) => {
    switch (status) {
      case 'Approved':
        return (
          <Tag
            className="fill"
            style={{
              color: 'white',
              backgroundColor: '#31B43E',
            }}
          >
            <Row gutter={4} align="middle">
              <Col>
                <Row align="middle">
                  <img src={CHECK_ICON} alt={'CHECK_ICON'} />
                </Row>
              </Col>
              <Col>
                <Typography.Text style={{ color: 'white' }}>
                  Approved
                </Typography.Text>
              </Col>
            </Row>
          </Tag>
        );
      case 'Doubtful':
        return (
          <Tag
            className="fill"
            style={{
              color: 'white',
              backgroundColor: '#EF7F18',
            }}
          >
            <Row gutter={4} align="middle">
              <Col>
                <Row align="middle">
                  <img
                    src={QUESTION_MARK_ICON}
                    alt={'QUESTION_MARK_ICON'}
                  />
                </Row>
              </Col>
              <Col>
                <Typography.Text style={{ color: 'white' }}>
                  Doubtful
                </Typography.Text>
              </Col>
            </Row>
          </Tag>
        );
      case 'SPV0':
        return (
          <Tag
            className="fill"
            style={{
              color: 'white',
              backgroundColor: '#EE7F28',
            }}
          >
            <Row gutter={4} align="middle">
              <Col>
                <Row align="middle">
                  <img src={CHECK_ICON} alt={'CHECK_ICON'} />
                </Row>
              </Col>
              <Col>
                <Typography.Text style={{ color: 'white' }}>
                  SPV0
                </Typography.Text>
              </Col>
            </Row>
          </Tag>
        );
      case 'SPV1':
        return (
          <Tag
            className="fill"
            style={{
              color: 'white',
              backgroundColor: '#EE7A18',
            }}
          >
            <Row gutter={4} align="middle">
              <Col>
                <Row align="middle">
                  <img src={CHECK_ICON} alt={'CHECK_ICON'} />
                </Row>
              </Col>
              <Col>
                <Typography.Text style={{ color: 'white' }}>
                  SPV1
                </Typography.Text>
              </Col>
            </Row>
          </Tag>
        );
      case 'Rejected': {
        return (
          <Tag
            className="fill"
            style={{
              color: 'white',
              backgroundColor: '#F04438',
            }}
          >
            <Row gutter={4} align="middle">
              <Col>
                <Row align="middle">
                  <img src={X_ICON} alt={'X_ICON'} />
                </Row>
              </Col>
              <Col>
                <Typography.Text style={{ color: 'white' }}>
                  Rejected
                </Typography.Text>
              </Col>
            </Row>
          </Tag>
        );
      }
      default:
        return (
          <Tag
            className="fill"
            style={{
              color: 'white',
              backgroundColor: '#BCBCBC',
            }}
          >
            Pending
          </Tag>
        );
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getClaimsHandler(controller);

    return () => {
      controller.abort();
    };
  }, [getClaimsHandler, claimStat, assginMe]);

  const [expandedRowKeys, setExpandedRowKeys] = useState<
    {
      rowKey: number;
      columnKey:
      | 'claim_amount'
      | 'celsius_information'
      | 'current_status';
    }[]
  >([]);

  const agreementStatusOptions = [
    'Transfer Agreement filed with Court',
    'Filed at Court',
    'Transfer Agreement Signed',
    'Awaiting Transfer Agreement Submission',
  ];
  
  const getStatusText = (agreementStatus: string, hash: string) => {
    if (agreementStatus === 'Transfer Approved by Court' && hash) {
      return 'Token Minted';
    } else if (agreementStatusOptions.includes(agreementStatus)) {
      return agreementStatus;
    } else if (!agreementStatus) {
      return 'Transfer agreement not generated';
    } else {
      return '';
    }
  };

  const get_stats = async () => {
    const [
      total,
      SPV0,
      SPV1,
      Rejected,
      manual_pending,
      manual_verified,
      filed_with_court,
      legal_admin_pending,
      legal_admin_approved,
      level1_pending,
      level1_approved,
      level2_pending,
      level2_approved,
      level3_pending,
      level3_approved,
      SPV_issued,
      SPV_issued_with_preference,
    ] = await Promise.all([
      //all
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
      }),
      //SPV0
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          status: 'SPV0',
        }),
      }),
      //SPV1
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          status: 'SPV1',
        }),
      }),
      //Rejected
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          hash: null,
          $or: [
          {status: 'Rejected',},
          {admin_status: 'Rejected',}
          ],
        }),
      }),
      //manual_pending
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $or: [
            { level3_details_check_1: { $ne: 'Verified' } },
            { level3_details_check_2: { $ne: 'Verified' } },
            { level3_details_check_3: { $ne: 'Verified' } },
            { level3_details_check_4: { $ne: 'Verified' } },
          ],
        }),
      }),
      //manual_verified
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          level3_details_check_1: 'Verified',
          level3_details_check_2: 'Verified',
          level3_details_check_3: 'Verified',
          level3_details_check_4: 'Verified',
        }),
      }),
      //filed_with_court
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $and: [
            { hash: { $ne: null} },
          ],
        }),
      }),
      //legal_admin_pending
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $and: [
            {status: { $ne: 'Rejected' }},
            {admin_status: 'Approved'},
            {level3_status: 'level3_approved'},
            {agreement_status: { $ne: 'Transfer Approved by Court' }},
            {celsius_account_acquired:  'Account Acquired' },
            {hash: null}
          ]
        }),
      }),
      //legal_admin_approved
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $or: [
            {current_status: 'legal_admin_approved'}
          ]
        }),
      }),
      //level1_pending
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $and: [
            {status: { $ne: 'Rejected' }},
            {admin_status: 'Approved'},
            {level3_status: 'level3_approved'},
            {hash: null}
          ],
          $or: [
            {agreement_status: 'Transfer Approved by Court'},
            {celsius_account_acquired: { $ne: 'Account Acquired'} },
          ]
        }),
      }),
      //level1_approved
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $or: [
            {current_status: 'level1_approved'}
          ]
        }),
      }),
      //level2_pending
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $and: [
            {status: { $ne: 'Rejected' }},
            {level3_status: 'level3_approved'},
            {admin_status: { $ne: 'Approved' }},
          ]
        }),
      }),
      //level2_approved
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $or: [
            {current_status: 'level2_approved'}
          ]
        }),
      }),
      //level3_pending
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $and: [
            {status: { $ne: 'Rejected' }},
            {level3_status: { $ne: 'level3_doubtful' }},
            {level3_status: { $ne: 'level3_rejected' }},
            {level3_status: { $ne: 'level3_approved' }},
            {admin_status: { $ne: 'Approved' }},
          ]
        }),
      }),
      //level3_approved
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          $or: [
            {current_status: 'level3_approved'}
          ]
        }),
      }),
      //SPV_issued
      adminAPI('post', '/stats', {
        claim_type: CELSIUS,
        filter: JSON.stringify({
          level3_details_check_1: 'Verified',
          level3_details_check_2: 'Verified',
          level3_details_check_3: 'Verified',
          level3_details_check_4: 'Verified',
          admin_status: 'Approved',
          is_minted: true,
        }),
      }),
      //SPV_issued_with_preference
      (() => ({ message: 0 }))(),
    ]);

    setStats({
      total: total.message ?? 0,
      SPV0: SPV0.message ?? 0,
      SPV1: SPV1.message ?? 0,
      Rejected: Rejected.message ?? 0,
      manual_pending: manual_pending.message ?? 0,
      manual_verified: manual_verified.message ?? 0,
      filed_with_court: filed_with_court.message ?? 0,
      legal_admin_pending: legal_admin_pending.message ?? 0,
      legal_admin_approved: legal_admin_approved.message ?? 0,
      level1_pending: level1_pending.message ?? 0,
      level1_approved: level1_approved.message ?? 0,
      level2_pending: level2_pending.message ?? 0,
      level2_approved: level2_approved.message ?? 0,
      level3_pending: level3_pending.message ?? 0,
      level3_approved: level3_approved.message ?? 0,
      SPV_issued: SPV_issued.message ?? 0,
      SPV_issued_with_preference:
        SPV_issued_with_preference.message ?? 0,
    });
  };

  const columns: ColumnsType<TableData> = useMemo(
    () => [
      {
        title: 'Application Date',
        key: 'application_date',
        dataIndex: 'applicationDate',
        render: (data) => {
          return moment(data, 'M/D/YYYY').format('D.M.YYYY');
        },
      },
      {
        title: 'Claim ID',
        key: 'claim_id',
        width: 200,
        render: ({ claimID, key }: TableData, record, index) => {
          return (
            <Row align="middle" wrap={false}>
              {claimID}&nbsp;
              <Button
                type="link"
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(claimID);
                  messageApi.info('Copied ' + claimID);
                }}
                style={{ padding: 0 }}
              >
                <img src={COPY_ICON} alt="COPY_ICON" />
              </Button>
            </Row>
          );
        },
      },
      {
        title: 'Assigned Admin',
        key: 'assigned_admin',
        dataIndex: 'assignedAdmin',
      },
      {
        title: 'Claim Amount',
        key: 'claim_amount',
        render: ({ survey, key }: TableData, record, index) => {
          const existKey = expandedRowKeys.findIndex(
            ({ rowKey, columnKey }) =>
              rowKey === key && columnKey === 'claim_amount'
          );

          const total_celsius_earn_balances = survey?.[
            'total_celsius_earn_balances'
          ]
            ? (+survey?.['total_celsius_earn_balances']).toFixed(2)
            : 0;

          return (
            <Button
              type="text"
              style={{ width: '100%' }}
              onClick={(e) => {
                e.stopPropagation();
                existKey < 0
                  ? setExpandedRowKeys((prevState) => {
                    const existState = prevState.findIndex(
                      (item) => item.rowKey === key
                    );
                    if (existState < 0)
                      return [
                        ...prevState,
                        { rowKey: key, columnKey: 'claim_amount' },
                      ];
                    prevState[existState].columnKey =
                      'claim_amount';
                    return [...prevState];
                  })
                  : setExpandedRowKeys((prevState) =>
                    prevState.filter((item) => item.rowKey !== key)
                  );
              }}
            >
              <Row
                align="middle"
                wrap={false}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                ${total_celsius_earn_balances}
                &nbsp;
                {existKey < 0 ? (
                  <img
                    src={CHEVRON_DOWN_ICON}
                    alt="CHEVRON_DOWN_ICON"
                  />
                ) : (
                  <img src={CHEVRON_UP_ICON} alt="CHEVRON_UP_ICON" />
                )}
              </Row>
            </Button>
          );
        },
      },
      {
        title: 'Preference Risk',
        key: 'account_preference_risk',
        // width: 200,
        render: ({ survey }: DataType) => {
          // return preference(survey);
          // return renderTagPref(survey.account_preference_risk);
          // return `Estimated: $${survey.account_preference_risk}`;
          return preferenceRisk(survey);
        },
      },
      {
        title: 'Celsius Information',
        key: 'celsius_information',
        render: (
          { celsiusInformation, key }: TableData,
          record,
          index
        ) => {
          const existKey = expandedRowKeys.findIndex(
            ({ rowKey, columnKey }) =>
              rowKey === key && columnKey === 'celsius_information'
          );

          return (
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                existKey < 0
                  ? setExpandedRowKeys((prevState) => {
                    const existState = prevState.findIndex(
                      (item) => item.rowKey === key
                    );
                    if (existState < 0)
                      return [
                        ...prevState,
                        {
                          rowKey: key,
                          columnKey: 'celsius_information',
                        },
                      ];
                    prevState[existState].columnKey =
                      'celsius_information';
                    return [...prevState];
                  })
                  : setExpandedRowKeys((prevState) =>
                    prevState.filter((item) => item.rowKey !== key)
                  );
              }}
            >
              <Row align="middle" wrap={false}>
                {celsiusInformation}&nbsp;
                {existKey < 0 ? (
                  <img
                    src={CHEVRON_DOWN_ICON}
                    alt="CHEVRON_DOWN_ICON"
                  />
                ) : (
                  <img src={CHEVRON_UP_ICON} alt="CHEVRON_UP_ICON" />
                )}
              </Row>
            </Button>
          );
        },
      },
      {
        title: 'Current Status',
        key: 'current_status',
        render: (
          { currentStatus, hash, key, note }: TableData,
          record,
          index
        ) => {
          console.log(note, 'hash');
          
          const existKey = expandedRowKeys.findIndex(
            ({ rowKey, columnKey }) =>
              rowKey === key && columnKey === 'current_status'
          );

          return (
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                existKey < 0
                  ? setExpandedRowKeys((prevState) => {
                    const existState = prevState.findIndex(
                      (item) => item.rowKey === key
                    );
                    if (existState < 0)
                      return [
                        ...prevState,
                        {
                          rowKey: key,
                          columnKey: 'current_status',
                        },
                      ];
                    prevState[existState].columnKey =
                      'current_status';
                    return [...prevState];
                  })
                  : setExpandedRowKeys((prevState) =>
                    prevState.filter((item) => item.rowKey !== key)
                  );
              }}
            >
              <Row align="middle" wrap={false}>
                {!hash ? currentStatus: 'Tokens Minted'}&nbsp;
                {existKey < 0 ? (
                  <img
                    src={CHEVRON_DOWN_ICON}
                    alt="CHEVRON_DOWN_ICON"
                  />
                ) : (
                  <img src={CHEVRON_UP_ICON} alt="CHEVRON_UP_ICON" />
                )}
                {note?.includes('Duplicated claim') && (
                    <Row
                      wrap={false}
                      align={'middle'}
                      style={{ position: 'relative' }}
                    >
                      <Typography.Text>
                        {note.toString()}
                      </Typography.Text>
                      &nbsp;
                      <Tooltip
                        placement="topLeft"
                        title={'...'}
                      >
                        <div>
                          <BsInfoCircle
                          color='red'
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </Tooltip>
                    </Row>
                  )}
              </Row>
            </Button>
          );
        },
      },
    ],
    [expandedRowKeys, messageApi]
  );

  useEffect(() => {
    get_stats();
    getPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMatchedCoin = (value: any) => {
    const matchedCoin =
      prices &&
      (prices
        .find((item: any) => `${item.price}` === `${value}`)
        ?.coin?.toLowerCase() ||
        'btc');
    return matchedCoin;
  };

  const carouselRef: React.Ref<CarouselRef> | undefined =
    useRef(null);

  const handleChangeStat = (stat: Stat) => {
    const paramsObject = Object.fromEntries(Array.from(searchParams));

    paramsObject.current = '1';

    setSearchParams(paramsObject);
    setClaimStat(stat);
  };

  const debouncedResults = useMemo(() => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      e
    ) => {
      const paramsObject = Object.fromEntries(
        Array.from(searchParams)
      );
      paramsObject[`search_${e.target.name}`] = e.target.value;

      Object.keys(paramsObject).forEach((key) => {
        if (!paramsObject[key]) delete paramsObject[key];
      });

      setSearchParams(paramsObject);
    };
    return debounce(handleChange, 300);
  }, [searchParams, setSearchParams]);

  return (
    <Row className={styles.container}>
      {contextHolderMessage}
      {contextHolder}
      <Col span={24}>
        <Row
          gutter={0}
          style={{ background: '#037C7C', padding: 20 }}
        >
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <Typography.Title level={3} style={{ color: '#fff' }}>
                  Claims Stats
                </Typography.Title>
              </Col>
              <Col>
                {/* <Row>
                  <Button
                    onClick={() => carouselRef.current?.prev()}
                    style={{
                      background: 'transparent',
                      height: 34,
                      width: 34,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #13A2A2',
                      borderRadius: 4,
                    }}
                  >
                    <img
                      src={CHEVRON_LEFT_ICON}
                      alt="CHEVRON_LEFT_ICON"
                    />
                  </Button>
                  <Button
                    onClick={() => carouselRef.current?.next()}
                    style={{
                      background: 'transparent',
                      height: 34,
                      width: 34,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #13A2A2',
                      borderRadius: 4,
                      marginLeft: 8,
                    }}
                  >
                    <img
                      src={CHEVRON_RIGHT_ICON}
                      alt="CHEVRON_RIGHT_ICON"
                    />
                  </Button>
                </Row> */}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {/* <Carousel
              ref={carouselRef}
              dots={false}
              style={{ marginTop: 8 }}
            > */}
              <Row
                gutter={[16, 16]}
                justify="space-between"
                className={styles['claim-stats']}
                wrap={false}
              >
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                    className={`${styles['claim-stats__item']} ${claimStat === undefined ? styles.active : ''
                      }`}
                    onClick={() => handleChangeStat(undefined)}
                  >
                    <Typography.Text>All Claims</Typography.Text>
                    <Row>
                      <Typography.Title level={3}>
                        {stats?.total}
                      </Typography.Title>
                    </Row>
                  </Button>
                </Col>
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                      className={`${styles['claim-stats__item']} ${claimStat === 'filed_with_court' ? styles.active : ''
                      }`}
                      onClick={() => handleChangeStat('filed_with_court')}
                  >
                    <Typography.Text> Accepted Claims</Typography.Text>
                    <Typography.Title level={3}>
                      {stats?.filed_with_court}
                    </Typography.Title>
                  </Button>
                </Col>
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                      className={`${styles['claim-stats__item']} ${claimStat === 'level3_pending'
                          ? styles.active
                          : ''
                      }`}
                      onClick={() => handleChangeStat('level3_pending')}
                  >
                    <Typography.Text>
                      <Typography.Text>L3</Typography.Text>
                      <br />
                      <Typography.Text>Pending Claims</Typography.Text>
                    </Typography.Text>
                    <Typography.Title level={3}>
                      {stats?.level3_pending}
                    </Typography.Title>
                  </Button>
                </Col>
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                      className={`${styles['claim-stats__item']} ${claimStat === 'level2_pending'
                          ? styles.active
                          : ''
                      }`}
                      onClick={() => handleChangeStat('level2_pending')}
                  >
                    <Typography.Text>
                      <Typography.Text>L2</Typography.Text>
                      <br />
                      <Typography.Text>Pending Claims</Typography.Text>
                    </Typography.Text>
                    <Typography.Title level={3}>
                      {stats?.level2_pending}
                    </Typography.Title>
                  </Button>
                </Col>
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                      className={`${styles['claim-stats__item']} ${claimStat === 'level1_pending'
                          ? styles.active
                          : ''
                      }`}
                      onClick={() => handleChangeStat('level1_pending')}
                  >
                    <Typography.Text>
                      <Typography.Text>L1</Typography.Text>
                      <br />
                      <Typography.Text>Pending Claims</Typography.Text>
                    </Typography.Text>
                    <Typography.Title level={3}>
                      {stats?.level1_pending}
                    </Typography.Title>
                  </Button>
                </Col>
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                      className={`${styles['claim-stats__item']} ${claimStat === 'legal_admin_pending'
                          ? styles.active
                          : ''
                      }`}
                      onClick={() => handleChangeStat('legal_admin_pending')}
                  >
                    <Typography.Text>
                      <Typography.Text>Legal Admin</Typography.Text>
                      <br />
                      <Typography.Text>Pending Claims</Typography.Text>
                    </Typography.Text>
                    <Typography.Title level={3}>
                      {stats?.legal_admin_pending}
                    </Typography.Title>
                  </Button>
                </Col>
                <Col flex={1} style={{ minWidth: 'fit-content' }}>
                  <Button
                      className={`${styles['claim-stats__item']} ${claimStat === 'Rejected' ? styles.active : styles.rejected
                      }`}
                      onClick={() => handleChangeStat('Rejected')}
                  >
                    {/*<Typography.Text>Rejected Claims</Typography.Text>*/}
                    <Typography.Text>Rejected</Typography.Text>
                    <Typography.Title level={3}>
                      {stats?.Rejected}
                    </Typography.Title>
                  </Button>
                </Col>
                {/*<Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
                {/*  <Button*/}
                {/*    className={`${styles['claim-stats__item']} ${claimStat === 'SPV0' ? styles.active : ''*/}
                {/*      }`}*/}
                {/*    onClick={() => handleChangeStat('SPV0')}*/}
                {/*  >*/}
                {/*    <Typography.Text>*/}
                {/*      <Typography.Text>Auto-Approved</Typography.Text>*/}
                {/*      <br />*/}
                {/*      <Typography.Text>*/}
                {/*        Claim with SPV0*/}
                {/*      </Typography.Text>*/}
                {/*    </Typography.Text>*/}
                {/*    <Typography.Title level={3}>*/}
                {/*      {stats?.SPV0}*/}
                {/*    </Typography.Title>*/}
                {/*  </Button>*/}
                {/*</Col>*/}
                {/*<Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
                {/*  <Button*/}
                {/*    className={`${styles['claim-stats__item']} ${claimStat === 'SPV1' ? styles.active : ''*/}
                {/*      }`}*/}
                {/*    onClick={() => handleChangeStat('SPV1')}*/}
                {/*  >*/}
                {/*    <Typography.Text>*/}
                {/*      <Typography.Text>Auto-Approved</Typography.Text>*/}
                {/*      <br />*/}
                {/*      <Typography.Text>*/}
                {/*        Claim with SPV1*/}
                {/*      </Typography.Text>*/}
                {/*    </Typography.Text>*/}
                {/*    <Typography.Title level={3}>*/}
                {/*      {stats?.SPV1}*/}
                {/*    </Typography.Title>*/}
                {/*  </Button>*/}
                {/*</Col>*/}
              </Row>
              {/*<Row*/}
              {/*  gutter={[16, 16]}*/}
              {/*  justify="space-between"*/}
              {/*  className={styles['claim-stats']}*/}
              {/*  wrap={false}*/}
              {/*>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'manual_verified'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() =>*/}
              {/*        handleChangeStat('manual_verified')*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Typography.Text>Manual Verified</Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.manual_verified}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'manual_pending'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() => handleChangeStat('manual_pending')}*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>*/}
              {/*          Manual Pending*/}
              {/*        </Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.manual_pending}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'level2_pending'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() => handleChangeStat('level2_pending')}*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>L2</Typography.Text>*/}
              {/*        <br />*/}
              {/*        <Typography.Text>*/}
              {/*          Verification Pending*/}
              {/*        </Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.level2_pending}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'level2_approved'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() =>*/}
              {/*        handleChangeStat('level2_approved')*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>L2</Typography.Text>*/}
              {/*        <br />*/}
              {/*        <Typography.Text>Approved</Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.level2_approved}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              {/*<Row*/}
              {/*  gutter={[16, 16]}*/}
              {/*  justify="space-between"*/}
              {/*  className={styles['claim-stats']}*/}
              {/*  wrap={false}*/}
              {/*>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'level3_pending'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() => handleChangeStat('level3_pending')}*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>L3</Typography.Text>*/}
              {/*        <br />*/}
              {/*        <Typography.Text>*/}
              {/*          Verification Pending*/}
              {/*        </Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.level3_pending}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'level3_approved'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() =>*/}
              {/*        handleChangeStat('level3_approved')*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>L3</Typography.Text>*/}
              {/*        <br />*/}
              {/*        <Typography.Text>Approved</Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.level3_approved}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'SPV_issued' ? styles.active : ''*/}
              {/*        }`}*/}
              {/*      onClick={() => handleChangeStat('SPV_issued')}*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>SPV Issued</Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.SPV_issued}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*  <Col flex={1} style={{ minWidth: 'fit-content' }}>*/}
              {/*    <Button*/}
              {/*      className={`${styles['claim-stats__item']} ${claimStat === 'SPV_issued_with_preference'*/}
              {/*          ? styles.active*/}
              {/*          : ''*/}
              {/*        }`}*/}
              {/*      onClick={() =>*/}
              {/*        handleChangeStat('SPV_issued_with_preference')*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <Typography.Text>*/}
              {/*        <Typography.Text>SPV Issued</Typography.Text>*/}
              {/*        <br />*/}
              {/*        <Typography.Text>*/}
              {/*          with Preference*/}
              {/*        </Typography.Text>*/}
              {/*      </Typography.Text>*/}
              {/*      <Typography.Title level={3}>*/}
              {/*        {stats?.SPV_issued_with_preference}*/}
              {/*      </Typography.Title>*/}
              {/*    </Button>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            {/* </Carousel> */}
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ padding: 20 }}>
        <Typography.Title level={3}>All Claims</Typography.Title>
        <Row gutter={32} justify="space-between" align={'middle'}>
          <Col span={12}>
            <Input
              suffix={
                <img src={IconSearch} width={20} alt="IconSearch" />
              }
              placeholder="Search here"
              size="large"
              name="claim_id"
              onChange={debouncedResults}
              defaultValue={
                Object.fromEntries(Array.from(searchParams))
                  .search_claim_id
              }
              allowClear
            />
          </Col>
          <Col span={4}>
            <Row gutter={6} >
              <Col>
                <Typography.Text>Assigned to me</Typography.Text>
              </Col>
              <Col>
                <Switch
                  checked={assginMe}
                  onChange={(value) => {
                    setAssginMe(value)
                  }}
                />
              </Col>
              </Row>
              </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={claims}
          bordered={false}
          className={styles['table-container']}
          expandable={{
            expandedRowRender: (tableData: TableData) => {
              const {
                key,
                celsiusBalances,
                celsiusNewEmail,
                celsiusNewPwd,
                celsiusEmail,
                l2AdminStatus,
                level3DetailsCheck1,
                celsiusStatementLevel3Check,
                proofOfClaimLevel3Check,
                status,
                agreementStatus,
                celsiusAccountAcquired,
                note,
                hash,
                level3Status,
              } = tableData;

              console.log(level3Status);

              const existKey = expandedRowKeys.find(
                (item) => item.rowKey === key
              );

              // const level3DetailsCheck = (() => {
              //   let detailsCheck = [level3DetailsCheck1];

              //   if (celsiusStatementLevel3Check !== '') {
              //     detailsCheck.push(celsiusStatementLevel3Check);
              //   }

              //   if (proofOfClaimLevel3Check !== '') {
              //     detailsCheck.push(proofOfClaimLevel3Check);
              //   }

              //   if (
              //     detailsCheck.every(
              //       (status) => status === 'Verified'
              //     )
              //   ) {
              //     return 'Verified';
              //   } else if (detailsCheck.every((status) => !status)) {
              //     return 'Pending';
              //   } else if (
              //     detailsCheck.some((status) => status === 'Rejected')
              //   ) {
              //     return 'Rejected';
              //   } else {
              //     return 'Doubtful';
              //   }
              // })();

              switch (existKey?.columnKey) {
                case 'celsius_information':
                  return (
                    <>
                      <Typography.Title level={4}>
                        Celsius Information
                      </Typography.Title>
                      <table>
                        <thead>
                          <tr>
                            <th>Celsius Email</th>
                            <th>Creditor Celsius Pwd</th>
                            <th>New Celsius Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{celsiusEmail || 'N/A'}</td>
                            <td>{celsiusNewPwd || 'N/A'}</td>
                            <td>{celsiusNewEmail || 'N/A'}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  );

                case 'current_status':
                  return (
                    <>
                      <Typography.Title level={4}>
                        Current Status
                      </Typography.Title>
                      <table>
                        <thead>
                          <tr>
                            <th>Auto Admin Status</th>
                            <th>L3 Admin Status</th>
                            <th>L2 Admin Status</th>
                            <th>Transfer Agreement Status</th>
                            <th>L1 Admin Status</th>
                            <th>Court Filling Status</th>
                            <th>Token Mint Status</th>                        
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {['SPV0', 'SPV1', 'Approved'].includes(
                                status
                              ) ? (
                                // ? renderTagCheckingDetails(status)
                                <>
                                  <img
                                    src={VERIFIED_ICON}
                                    alt={'VERIFIED_ICON'}
                                  />
                                  &nbsp;Approved
                                </>
                              ) : (
                                <>
                                  <img
                                    src={REJECTED_ICON}
                                    alt={'REJECTED_ICON'}
                                  />
                                  &nbsp;Rejected
                                </>
                                // renderTagCheckingDetails('Rejected')
                              )}
                              {!!note.length && (
                                <Row
                                  wrap={false}
                                  align={'middle'}
                                  style={{ position: 'relative' }}
                                >
                                  <Typography.Text>
                                    {note.toString()}
                                  </Typography.Text>
                                  &nbsp;
                                  <Tooltip
                                    placement="topLeft"
                                    title={'...'}
                                  >
                                    <div>
                                      <BsInfoCircle
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </Tooltip>
                                </Row>
                              )}
                            </td>
                            <td>
                              {level3Status === 'level3_approved'
                                ? renderTagCheckingDetails('Approved')
                                : level3Status === 'level3_rejected'
                                  ? renderTagCheckingDetails('Rejected')
                                  : level3Status === 'level3_doubtful'
                                    ? renderTagCheckingDetails('Doubtful')
                                    : renderTagCheckingDetails('Pending')}
                            </td>
                            <td>
                              {l2AdminStatus === 'Approved' ? (
                                <>
                                  <img
                                    src={VERIFIED_ICON}
                                    alt={'VERIFIED_ICON'}
                                  />
                                  &nbsp;Approved
                                </>
                              ) : l2AdminStatus === 'Rejected' ? (
                                <>
                                  <img
                                    src={REJECTED_ICON}
                                    alt={'REJECTED_ICON'}
                                  />
                                  &nbsp;Rejected
                                </>
                              ) : (
                                renderTagCheckingDetails('Pending')
                              )}
                            </td>
                            <td>{agreementStatus}</td>

                            <td>
                              {celsiusAccountAcquired ===
                                'Account Acquired'
                                ? celsiusAccountAcquired
                                : 'Account Not Acquired'}
                            </td>
                            <td>
                              {getStatusText(agreementStatus, hash)}
                            </td>
                            <td>
                              {/* <Button
                                type="default"
                                disabled={
                                  !celsiusAccountAcquired ||
                                  agreementStatus !==
                                    'Transfer Agreement filed with Court' ||
                                  mintRequested.includes(claimID)
                                }
                                onClick={async () => {
                                  if (!address) {
                                    openWeb3();
                                  } else {
                                    setDataMintRequest(tableData);
                                    setOpen(true);
                                  }
                                }}
                              >
                                <Typography.Text style={{ color: '#03ABAB' }}>
                                  {!address
                                    ? 'Connect Metamask'
                                    : minted.includes(claimID)
                                    ? 'Minted'
                                    : mintRequested.includes(claimID)
                                    ? 'Requested'
                                    : !celsiusAccountAcquired ||
                                      agreementStatus !==
                                        'Transfer Agreement filed with Court'
                                    ? 'Not accepted'
                                    : 'Mint Request'}
                                </Typography.Text>
                              </Button> */}
                              {hash
                                ? `${hash.slice(0, 4)}...${hash.slice(
                                  -6
                                )}`
                                : ''}
                              &nbsp;
                              {hash && (
                                <Button
                                  type="link"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (hash) {
                                      navigator.clipboard.writeText(
                                        hash
                                      );
                                      messageApi.info(
                                        'Copied ' + hash
                                      );
                                    }
                                  }}
                                  style={{ padding: 0 }}
                                >
                                  <img
                                    src={COPY_ICON}
                                    alt="COPY_ICON"
                                  />
                                </Button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  );
                default:
                case 'claim_amount':
                  console.log({ celsiusBalances });
                  return (
                    <>
                      <Typography.Title level={4}>
                        Claim Amount
                      </Typography.Title>
                      <table>
                        <thead>
                          <tr>
                            <th>Coin</th>
                            <th>Price as of July 13</th>
                            <th>Quantity</th>
                            <th>Claim Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {celsiusBalances &&
                            celsiusBalances.length &&
                            celsiusBalances.map(
                              (item: any, index: number) => (
                                <tr
                                  key={`claim_amount_table_${index}`}
                                >
                                  <td>
                                    <Row align="middle">
                                      <CryptoIcon
                                        name={getMatchedCoin(
                                          item.price
                                        )}
                                      />
                                      &nbsp;&nbsp;
                                      <Typography.Text>
                                        {getMatchedCoin(
                                          item.price
                                        ).toUpperCase()}
                                      </Typography.Text>
                                      {/* <Icon
                                      name={getMatchedCoin(item.price)}
                                      size={20}
                                    /> */}
                                    </Row>
                                  </td>
                                  <td>${item.price}</td>
                                  <td>{item.Quantity}</td>
                                  <td>${item['Claim Value']}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </>
                  );
              }
            },
            expandedRowKeys: expandedRowKeys.map(
              ({ rowKey }) => rowKey
            ),
            showExpandColumn: false,
          }}
          onRow={({ claimID }) => {
            return {
              onClick: () => {
                navigation('/admin/' + claimID);
              },
            };
          }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: +size,
            showSizeChanger: false,
            current: +current,
            total: claimsCount,
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Col>

      <Modal
        title={
          <Typography.Title level={2}>Mint request</Typography.Title>
        }
        open={open}
        onCancel={() => setOpen(false)}
        width="min(360px, calc(100% - 32px))"
        centered
        footer={false}
        className={styles['modal-container']}
      >
        <Form
          layout="vertical"
          className={styles['form-container']}
          form={form}
          onFinish={async (values: FormMintRequest) => {
            try {
            } catch (error: any) {
              api.error({
                message: `Error`,
                description: error.message,
                placement: 'top',
              });
            }
          }}
        >
          <Row>
            <Col span={24}>
              <Row
                style={{
                  borderBottom: '1px solid #E2E2E2',
                  padding: '16px 0',
                }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Token type</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>
                    Common Token
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                style={{
                  borderBottom: '1px solid #E2E2E2',
                  padding: '16px 0',
                }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Total Claim</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>
                    $
                    {dataMintRequest?.survey?.total_celsius_earn_balances?.toFixed(
                      0
                    ) || 0}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                style={{
                  borderBottom: '1px solid #E2E2E2',
                  padding: '16px 0',
                }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Claim ID</Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false}>
                    {dataMintRequest?.claimID}&nbsp;
                    <Button
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (dataMintRequest?.claimID) {
                          navigator.clipboard.writeText(
                            dataMintRequest?.claimID
                          );
                          messageApi.info(
                            'Copied ' + dataMintRequest?.claimID
                          );
                        }
                      }}
                      style={{ padding: 0 }}
                    >
                      <img src={COPY_ICON} alt="COPY_ICON" />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                style={{
                  borderBottom: '1px solid #E2E2E2',
                  padding: '16px 0',
                }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>Reduce claim Fee</Typography.Text>
                </Col>
                <Col>
                  <Row align="middle" wrap={false}>
                    {(amountFlex
                      ?.div(BigNumber.from(10).pow(15))
                      .toNumber() || 0) / 1000}{' '}
                    OX
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row
                style={{ padding: '20px 0' }}
                justify={'space-between'}
                align={'middle'}
              >
                <Col>
                  <Typography.Text>User Email</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text strong>
                    {dataMintRequest?.celsiusEmail}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.Item
            className={styles['form-container__item']}
            style={{ marginTop: 10 }}
          >
            <MintRequestButton
              value={
                dataMintRequest?.survey
                  ?.total_celsius_earn_balances || 0
              }
              contractAddress={
                dataMintRequest?.status === 'SPV0' ? process.env.REACT_APP_COMMON_ADDRESS! : process.env.REACT_APP_COMMON_ADDRESS!
              }
              isMintRequest={isMintRequest}
              claimId={dataMintRequest?.claimID || ''}
              getClaimsHandler={getClaimsHandler}
              fetchMintStatus={fetchMintStatus}
              setOpen={setOpen}
              claimAmount={amountFlex || BigNumber.from(0)}
            ></MintRequestButton>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

const CelsiusDashboard: FC<ReceivedProps> = (props) => (
  <CelsiusDashboardLayout {...useCelsiusDashboard(props)} />
);

export default CelsiusDashboard;

import React, { useState, useEffect } from 'react'
import { Space, Button, Typography, Tag } from 'antd';

import { classNames, survey_obj } from '../../../common/utils';
import TagOutline from '../../../components/Tag/TagOutline';

import IconChevronDown from '../../../statics/images/icon-chevron-down.svg';
import IconBTC from '../../../statics/images/icon-btc.svg';
// import IconETH from '../../../statics/images/icon-eth.svg'
import { Link } from "react-router-dom";
import styles from './TableRow.module.scss';

interface ClaimLayout {
  claim: any,
  index: number
}

function TableRow(props: ClaimLayout) {

  useEffect(() => {
    //console.log(props)
  }, [props]);


  const get_status = (status: string, admin_status: string) => {
    if (admin_status === "Rejected" || admin_status === "" || !admin_status) {
      return <Tag color="#EAE9FF"><span style={{ color: "#6558FF" }}>Under Review</span></Tag>
    }
    else if (admin_status === "Approved") {
      return <Tag color="#E3FAE5"><span style={{ color: "#31B43E" }}>Transfer in Progress</span></Tag>
    }
  }

  const [isOpenClaimInfo, setIsOpenClaimInfo] = useState(false);
  const [isOpenClaimAmount, setIsOpenClaimAmount] = useState(false)

  function claimClass(admin_status: any, survey: any) {

    return (
      <>
      {admin_status === "Approved" ?
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            General unsecured
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Non priority
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#F04438" />
              <path d="M13 7L7 13M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <s>Priority</s>
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#F04438" />
              <path d="M13 7L7 13M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <s>Offset</s>
          </div><br />
        </>
      : null}
      {
        survey.claim_schedule_status === "YES" ?
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Disputed
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Contingent
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#31B43E" />
              <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Unliquidated
          </div><br />
        </>
        :
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#F04438" />
              <path d="M13 7L7 13M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <s>Disputed</s>
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#F04438" />
              <path d="M13 7L7 13M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <s>Contingent</s>
          </div><br />
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#F04438" />
              <path d="M13 7L7 13M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <s>Unliquidated</s>
          </div><br />
        </>
      }

      </>
    )
  }
  function preference(survey: any) {
    return (
      <>
        {
          parseFloat(survey.totalWithdrawn) - parseFloat(survey.totalDeposit) < 0 ?
          <>

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="10" fill="#F04438" />
                <path d="M13 7L7 13M7 7L13 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <s>No Preference</s>
            </div><br />
          </>
          :
            parseFloat(survey.totalWithdrawn) - parseFloat(survey.totalDeposit) < 100000
            ?
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="10" fill="#31B43E" />
                  <path d="M14 7L8.5 12.5L6 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                Preference
              </div><br />
              <p>(Net: {parseFloat(survey.totalWithdrawn) - parseFloat(survey.totalDeposit)} USD )</p>
            </>
            :
            null
        }
      </>
    )
  }

  // const submit = (claimID) => {
    // history.push({
    //   pathname: '/transfer-agreement/'+claimID,
    //   state: { payload: '' },
    // });
  // }
  return (
    <div className={styles.wrapper} key={props.index}>
      <div className={styles.row}>
        <div className={styles.cell7}>
          <Button size='large'>Claim Information</Button>
          <img
            src={IconChevronDown}
            width={24}
            style={{ transform: `rotate(${isOpenClaimInfo ? '180' : '0'}deg)` }}
            onClick={() => setIsOpenClaimInfo(!isOpenClaimInfo)}
            alt="icon_image"
          />
        </div>
        <div className={styles.cell6}>
          <label>
            Agreement
          </label>
          {
            props.claim.admin_status === "Approved" && props.claim.submit_status === "Not Submitted" ?
            <Link to={"/transfer-agreement/"+props.claim.claim_id}>
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Submit
              </button>
            </Link>

            :
            null
          }
        </div>
        <div className={styles.cell1}>
          <label>
            Bankruptcy Case
          </label>
          <span>
            {props.claim.claim_type === "celsius" ? "Celsius Ch.11 in Southern District of NY" : null}
          </span>

        </div>
        <div className={styles.cell2}>
          <label>
            Claim ID
          </label>
          <Typography.Text copyable={{
            text: props.claim.claim_id,
            icon: (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_313_2599)">
                <path d="M3.33337 10H2.66671C2.31309 10 1.97395 9.85952 1.7239 9.60947C1.47385 9.35943 1.33337 9.02029 1.33337 8.66667V2.66667C1.33337 2.31304 1.47385 1.97391 1.7239 1.72386C1.97395 1.47381 2.31309 1.33333 2.66671 1.33333H8.66671C9.02033 1.33333 9.35947 1.47381 9.60952 1.72386C9.85956 1.97391 10 2.31304 10 2.66667V3.33333M7.33337 6H13.3334C14.0698 6 14.6667 6.59695 14.6667 7.33333V13.3333C14.6667 14.0697 14.0698 14.6667 13.3334 14.6667H7.33337C6.59699 14.6667 6.00004 14.0697 6.00004 13.3333V7.33333C6.00004 6.59695 6.59699 6 7.33337 6Z" stroke="#03ABAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_313_2599">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            )
          }}
          >
            {props.claim.claim_id}
          </Typography.Text>
        </div>
        <div className={styles.cell3}>
          <label>
            Claim amount
            <a href="home">More info</a>
          </label>
          <Space>
            <span>{props.claim.survey ? "$" + survey_obj(props.claim.survey).totalClaim : "N/A"}</span>
            <svg
              onClick={() => setIsOpenClaimAmount(!isOpenClaimAmount)}
              style={{
                cursor: 'pointer',
                marginTop: '4px',
                transform: `rotate(${isOpenClaimAmount ? '180' : '0'}deg)`
              }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 6L8 10L12 6" stroke="#03ABAB" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Space>
        </div>
        <div className={styles.cell4}>
          <label>
            Claim Status
          </label>
          {
            get_status(props.claim.status, props.claim.admin_status)
          }
        </div>

        <div className={styles.cell5}>
          <label>
            Tokenization TX
          </label>
        </div>


      </div>
      {isOpenClaimInfo && (
        <div className={styles.claimInfo}>
          <label className={styles.heading}>
            Claim Information
          </label>
          <div className={styles.subTable}>
            <div className={styles.subTableHeader}>
              Claim Details matches with Admin Schedule
            </div>
            <div className={styles.subTableHeader}>
              Claim Classification
            </div>
            <div className={styles.subTableHeader}>
              Preference Risk
            </div>
            <div className={styles.subTableHeader}>
              Creditor's Relatioship with Celsius
            </div>
            <div className={styles.subTableHeader}>
              Creditor's Location
            </div>
            <div className={styles.subTableHeader}>
              Creditor KYC Status
            </div>
            <div className={styles.subTableHeader}>
              Account Balances Confirmed
            </div>

            <div className={styles.subTableCell}>
              {props.claim.claim_details_matched === "Yes" ? <TagOutline color="#31B43E">● YES</TagOutline> :
                props.claim.claim_details_matched === "No" ? <TagOutline color="#CF352A">● NO</TagOutline> :
                  "N/A"}

            </div>
            <div className={styles.subTableCell}>
              {claimClass(props.claim.admin_status,survey_obj(props.claim.survey))}
            </div>
            <div className={styles.subTableCell}>
              {preference(survey_obj(props.claim.survey))}
            </div>
            <div className={styles.subTableCell}>
              {survey_obj(props.claim.survey).celsius_relationship ? survey_obj(props.claim.survey).celsius_relationship.map((relationship: any) => (
                <>
                {relationship === "Depositor" ? <TagOutline color="#D63696">Depositor</TagOutline> :
                 relationship === "Vendor" ? <TagOutline color="#31B43E">Vendor</TagOutline> :
                 relationship === "Other" ? <TagOutline color="#1FB5D6">Other</TagOutline> :
                 null}<br/>
                </>
              )) : "N/A"}
            </div>
            <div className={styles.subTableCell}>
              {survey_obj(props.claim.survey).country}
            </div>
            <div className={styles.subTableCell}>
              {props.claim.kyc_status ? props.claim.kyc_status : "N/A"}
            </div>
            <div className={styles.subTableCell}>
              { props.claim.balance_confirmed ?
                props.claim.balance_confirmed === "No" ? <TagOutline color="#CF352A">● NO</TagOutline> :
                props.claim.balance_confirmed === "Yes" ? <TagOutline color="#31B43E">● YES</TagOutline>
               : "N/A" : "N/A"  }
            </div>
          </div>
        </div>
      )
      }

      {
        isOpenClaimAmount && (
          <div className={styles.claimInfo}>
            <label className={styles.heading}>
              Claim Amount
            </label>
            <div className={classNames(styles.subTable, styles.amountTable)}>
              <div className={styles.subTableHeader}>
                Coin
              </div>
              <div className={styles.subTableHeader}>
                Price
              </div>
              <div className={styles.subTableHeader}>
                Quantity
              </div>
              <div className={styles.subTableHeader}>
                Claim Value
              </div>
              <div></div>
              {survey_obj(props.claim.survey).celsius_balances.map((claimAmount: any, index: any) => (
                <>
                  <div className={styles.subTableCell}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <span>{claimAmount["Claim Value"]}</span>
                      <img src={IconBTC} width={20} alt="BTC" />
                    </div>
                  </div>
                  <div className={styles.subTableCell}>
                    {claimAmount.Coin}
                  </div>
                  <div className={styles.subTableCell}>
                    {claimAmount.Quantity}
                  </div>
                  <div className={styles.subTableCell}>
                    ${claimAmount.price}
                  </div>
                  <div></div>
                </>

              ))}
            </div>
          </div>
        )
      }
    </div >
  )
}

TableRow.propTypes = {}

export default TableRow

import { useCallback, useContext, useMemo, useState } from "react";
import { omit } from "../../../../common/utils";
import { DetailContext } from "../DetailContextProvider";

export type ReceivedProps = Record<string, any>;

const useTransferAgreement = () => {
  const {
    claimData,
    aggreement,
    account,
    surveyData,
    exportAgreement,
    messageApi,
    celciusScheduleData,
  } = useContext(DetailContext);
  const [openAgreement, setOpenAgreement] = useState<boolean>(false);

  const transferAgreementData = useMemo(() => {
    if (claimData) {
      const keys = [
        "agreement_status",
        "celsius_account_acquired",
        "submit_status",
        "celsius_email",
        "celsius_password",
        "signed_date",
        "new_account_email",
        "new_account_password",
        "agreement_signed_time",
      ];

      return omit(
        {
          ...claimData,
          ...aggreement,
          ...account,
          ...surveyData,
        },
        keys
      );
    }
  }, [claimData, aggreement, account, surveyData]);

  const toggleAgreementModal = useCallback(() => {
    setOpenAgreement(!openAgreement);
  }, [openAgreement]);

  return {
    claimData,
    transferAgreementData,
    surveyData,
    toggleAgreementModal,
    openAgreement,
    exportAgreement,
    messageApi,
    celciusScheduleData,
  };
};

export type Props = ReturnType<typeof useTransferAgreement>;

export default useTransferAgreement;

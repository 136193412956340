import React, { FC } from "react";
import useViewAdmin, { Props, ReceivedProps } from "./hook";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";

import type { ColumnsType } from "antd/es/table";
import { adminAPI } from "../../../api/admin";
import styles from "./index.module.scss";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import {
  useAccount,
  useContractWrite,
  useDisconnect,
  usePrepareContractWrite,
  useProvider,
  useWaitForTransaction,
} from "wagmi";
import {
  ADMIN_HEX,
  APPROVAL_HEX,
  BURNER_HEX,
  MINT_REQUEST_HEX,
} from "../../../common/constants";
import BcTokenABi from "../../../common/BCToken.json";
import { LoadingOutlined } from "@ant-design/icons";
import { BcTokenFactory } from "../../../typechain/BcTokenFactory";
import { useWeb3Modal } from "@web3modal/react";

interface DataType {
  key: React.Key;
  id: string;
  email: string;
  level: number;
  address: string;
}

interface Admin {
  email: string;
  level: number;
  password: string;
  token: string;
  address: string;
  __v: number;
  _id: string;
}

interface FormEditAdmin {
  id: string;
  email: string;
  password: string;
  level: number;
  address: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const GrantApprovalRoleButton: FC<{ address: string; isAdmin: boolean, contractAddress: string }> = (
  props
) => {
  const {contractAddress} = props
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [isApproval, setIsApproval] = useState<boolean>(false);
  const provider = useProvider();

  const checkApprovalRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(contractAddress, provider).hasRole(
        APPROVAL_HEX,
        props.address
      );
      setIsApproval(role);
    }
  }, [address, props.address, provider]);

  useEffect(() => {
    checkApprovalRole();
  }, [address, checkApprovalRole, provider]);

  const { config: configApproval } = usePrepareContractWrite({
    address: `0x${contractAddress.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: "grantRole",
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [APPROVAL_HEX, props.address],
    onError(error: any) {
      // setLoading(false);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setLoading(false);
      // setErrorMessage("");
    },
  });
  const {
    data: dataGrant,
    write: grant,
    isError: isGrantError,
    isLoading: writeApprovalLoading,
  } = useContractWrite(configApproval);
  const { isSuccess: isGrantSuccess, isLoading: waitApproveLoading } =
    useWaitForTransaction({
      hash: dataGrant?.hash,
    });

  const grantRole = useCallback(async () => {
    if (address && grant) {
      setLoading(true);
      grant();
    }
  }, [address, grant]);

  const { config: configRevoke } = usePrepareContractWrite({
    address: `0x${contractAddress.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: "revokeRole",
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [APPROVAL_HEX, props.address],
    onError(error: any) {
      // setLoading(false);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setLoading(false);
      // setErrorMessage("");
    },
  });
  const {
    data: dataRevoke,
    write: revoke,
    isError: isRevokeError,
    isLoading: writeRevokeLoading,
  } = useContractWrite(configRevoke);

  const { isSuccess: isRevokeSuccess, isLoading: waitRevokeLoading } =
    useWaitForTransaction({
      hash: dataRevoke?.hash,
    });

  const revokeRole = useCallback(async () => {
    if (address && revoke) {
      setLoading(true);
      revoke();
    }
  }, [address, revoke]);

  useEffect(() => {
    (isGrantSuccess || isRevokeError || isRevokeSuccess || isGrantError) &&
      setLoading(false);
  }, [isGrantSuccess, dataGrant, isRevokeError, isRevokeSuccess, isGrantError]);

  return (
    <>
      {address && props.isAdmin ? (
        <Button
          onClick={isApproval ? revokeRole : grantRole}
          disabled={
            loading ||
            (isApproval
              ? writeApprovalLoading || waitApproveLoading
              : writeRevokeLoading || waitRevokeLoading)
          }
          loading={
            loading ||
            (isApproval
              ? writeApprovalLoading || waitApproveLoading
              : writeRevokeLoading || waitRevokeLoading)
          }
        >
          {isApproval ? (
            "Revoke"
          ) : !loading ? (
            "Grant"
          ) : (
            <Spin indicator={antIcon} />
          )}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

const GrantBurnerRoleButton: FC<{ address: string; isAdmin: boolean, contractAddress: string }> = (
  props
) => {
  const {contractAddress} = props
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [isBurner, setIsBurner] = useState<boolean>(false);
  const provider = useProvider();
  const { config: configBurn } = usePrepareContractWrite({
    address: `0x${contractAddress.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: "grantRole",
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [BURNER_HEX, props.address],
    onError(error: any) {
      // setLoading(false);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setLoading(false);
      // setErrorMessage("");
    },
  });
  const {
    data: dataGrant,
    write: grant,
    isError: isGrantError,
    isLoading: writeGrantLoading,
  } = useContractWrite(configBurn);
  const { isSuccess: isGrantSuccess, isLoading: waitGrantLoading } =
    useWaitForTransaction({
      hash: dataGrant?.hash,
    });

  const { config: configRevoke } = usePrepareContractWrite({
    address: `0x${contractAddress.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: "revokeRole",
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [BURNER_HEX, props.address],
    onError(error: any) {
      // setLoading(false);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setLoading(false);
      // setErrorMessage("");
    },
  });
  const {
    data: dataRevoke,
    write: revoke,
    isError: isRevokeError,
    isLoading: writeRevokeLoading,
  } = useContractWrite(configRevoke);
  const { isSuccess: isRevokeSuccess, isLoading: waitRevokeLoading } =
    useWaitForTransaction({
      hash: dataRevoke?.hash,
    });

  const checkMintRequestRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(contractAddress, provider).hasRole(
        BURNER_HEX,
        props.address
      );
      setIsBurner(role);
    }
  }, [address, props.address, provider]);

  useEffect(() => {
    checkMintRequestRole();
  }, [address, checkMintRequestRole, provider]);

  const grantRole = useCallback(async () => {
    if (address && grant) {
      setLoading(true);
      grant();
    }
  }, [address, grant]);

  const revokeRole = useCallback(async () => {
    if (address && revoke) {
      setLoading(true);
      revoke();
    }
  }, [address, revoke]);

  useEffect(() => {
    (isGrantSuccess || isRevokeError || isRevokeSuccess || isGrantError) &&
      setLoading(false);
  }, [isGrantSuccess, dataGrant, isRevokeError, isRevokeSuccess, isGrantError]);

  return (
    <>
      {address && props.isAdmin ? (
        <Button
          onClick={isBurner ? revokeRole : grantRole}
          disabled={
            loading ||
            (isBurner
              ? writeRevokeLoading || waitRevokeLoading
              : writeGrantLoading || waitGrantLoading)
          }
          loading={
            loading ||
            (isBurner
              ? writeRevokeLoading || waitRevokeLoading
              : writeGrantLoading || waitGrantLoading)
          }
        >
          {isBurner ? (
            "Revoke"
          ) : !loading ? (
            "Grant"
          ) : (
            <Spin indicator={antIcon} />
          )}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

const GrantMintRequestRoleButton: FC<{ address: string; isAdmin: boolean , contractAddress: string}> = (
  props
) => {
  const {contractAddress} = props
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);
  const [isBurner, setIsBurner] = useState<boolean>(false);
  const provider = useProvider();
  const { config: configBurn } = usePrepareContractWrite({
    address: `0x${contractAddress.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: "grantRole",
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [MINT_REQUEST_HEX, props.address],
    onError(error: any) {
      // setLoading(false);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setLoading(false);
      // setErrorMessage("");
    },
  });
  const {
    data: dataGrant,
    write: grant,
    isError: isGrantError,
    isLoading: writeGrantLoading,
  } = useContractWrite(configBurn);
  const { isSuccess: isGrantSuccess, isLoading: waitGrantLoading } =
    useWaitForTransaction({
      hash: dataGrant?.hash,
    });

  const { config: configRevoke } = usePrepareContractWrite({
    address: `0x${contractAddress.substring(2)}`,
    abi: BcTokenABi.abi,
    functionName: "revokeRole",
    chainId: +process.env.REACT_APP_CHAIN_ID!,
    cacheTime: 100,
    args: [MINT_REQUEST_HEX, props.address],
    onError(error: any) {
      // setLoading(false);
      // if (error?.data?.code === -32000)
      //   setErrorMessage(TRANSACTION_ERRORS.INSUFFICIENT_FUNDS_FOR_GAS_FEE);
    },
    onSuccess() {
      // setLoading(false);
      // setErrorMessage("");
    },
  });
  const {
    data: dataRevoke,
    write: revoke,
    isError: isRevokeError,
    isLoading: writeRevokeLoading,
  } = useContractWrite(configRevoke);
  const { isSuccess: isRevokeSuccess, isLoading: waitRevokeLoading } =
    useWaitForTransaction({
      hash: dataRevoke?.hash,
    });

  const checkMintRequestRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(contractAddress, provider).hasRole(
        MINT_REQUEST_HEX,
        props.address
      );
      setIsBurner(role);
    }
  }, [address, props.address, provider]);

  useEffect(() => {
    checkMintRequestRole();
  }, [address, checkMintRequestRole, provider]);

  const grantRole = useCallback(async () => {
    if (address && grant) {
      setLoading(true);
      grant();
    }
  }, [address, grant]);

  const revokeRole = useCallback(async () => {
    if (address && revoke) {
      setLoading(true);
      revoke();
    }
  }, [address, revoke]);

  useEffect(() => {
    (isGrantSuccess || isRevokeError || isRevokeSuccess || isGrantError) &&
      setLoading(false);
  }, [isGrantSuccess, dataGrant, isRevokeError, isRevokeSuccess, isGrantError]);

  return (
    <>
      {address && props.isAdmin ? (
        <Button
          onClick={isBurner ? revokeRole : grantRole}
          disabled={
            loading ||
            (isBurner
              ? writeRevokeLoading || waitRevokeLoading
              : writeGrantLoading || waitGrantLoading)
          }
          loading={
            loading ||
            (isBurner
              ? writeRevokeLoading || waitRevokeLoading
              : writeGrantLoading || waitGrantLoading)
          }
        >
          {isBurner ? (
            "Revoke"
          ) : !loading ? (
            "Grant"
          ) : (
            <Spin indicator={antIcon} />
          )}
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

const ViewAdminLayout: FC<Props> = (props) => {
  const provider = useProvider();
  const [api, contextHolder] = notification.useNotification();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { address } = useAccount();
  const { open: openWeb3 } = useWeb3Modal();
  const { disconnectAsync } = useDisconnect({
    onError(error: any) {
      console.log("Error", error);
    },
  });
  const navigate = useNavigate();

  const [admins, setAdmins] = useState<Admin[]>([]);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const getAdmins = useCallback(async () => {
    try {
      setLoading(true);
      const response = await adminAPI("post", "/admins", {limit: 5});
      if (response.status === "FAILED" || !response.status) {
        throw new Error("Something wrong with your request");
      }
      setAdmins(response.message);
    } catch (error: any) {
      api.error({
        message: `Notification`,
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [api]);

  const checkAdminRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(process.env.REACT_APP_COMMON_ADDRESS!, provider).hasRole(
        ADMIN_HEX,
        address
      );

      setIsAdmin(role);
    }
  }, [address, provider]);

  useEffect(() => {
    getAdmins();
  }, [checkAdminRole, getAdmins, address]);

  useEffect(() => {
    checkAdminRole();
  }, [address, checkAdminRole]);

  const columns: ColumnsType<DataType> = useMemo(
    () => [
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        width: 150,
        fixed: "left",
      },
      {
        title: "Level",
        key: "level",
        width: 150,
        ellipsis: true,
        render: ({ level }) => {
          return `Level ${level}`;
        },
      },
      {
        title: "Address",
        key: "address",
        ellipsis: true,
        render: ({ address }) => {
          return address && `${address.slice(0, 6)}...${address.slice(-4)}`;
        },
      },
      {
        title: "Approval Celsius",
        key: "approval",
        ellipsis: true,
        render: ({ address, isAdmin }) => {
          return (
            address && (
              <GrantApprovalRoleButton
                address={address}
                contractAddress={process.env.REACT_APP_COMMON_ADDRESS || ''}
                isAdmin={isAdmin}
              ></GrantApprovalRoleButton>
            )
          );
        },
      },
      {
        title: "Mint Request Celsius",
        key: "mint",
        ellipsis: true,
        render: ({ address, isAdmin }) => {
          return (
            address && (
              <GrantMintRequestRoleButton
                address={address}
                isAdmin={isAdmin}
                contractAddress={process.env.REACT_APP_COMMON_ADDRESS || ''}
              ></GrantMintRequestRoleButton>
            )
          );
        },
      },
      {
        title: "Approval FTX",
        key: "approval",
        ellipsis: true,
        render: ({ address, isAdmin }) => {
          return (
            address && (
              <GrantApprovalRoleButton
                address={address}
                contractAddress={process.env.REACT_APP_FTX_COMMON_ADDRESS || ''}
                isAdmin={isAdmin}
              ></GrantApprovalRoleButton>
            )
          );
        },
      },
      {
        title: "Mint Request FTX",
        key: "mint",
        ellipsis: true,
        render: ({ address, isAdmin }) => {
          return (
            address && (
              <GrantMintRequestRoleButton
                address={address}
                contractAddress={process.env.REACT_APP_FTX_COMMON_ADDRESS || ''}
                isAdmin={isAdmin}
              ></GrantMintRequestRoleButton>
            )
          );
        },
      },
      {
        title: " ",
        key: "edit",
        ellipsis: true,
        fixed: "right",
        render: ({ id, email, password, level }) => {
          return (
            <Row justify={"end"}>
              <Button
                type="link"
                onClick={() => {
                  form.setFieldValue("id", id);
                  form.setFieldValue("email", email);
                  // form.setFieldValue("password", password);
                  form.setFieldValue("level", level);
                  setOpen(true);
                }}
              >
                <Typography.Link underline style={{ color: "#03ABAB" }}>
                  Edit
                </Typography.Link>
              </Button>
            </Row>
          );
        },
      },
    ],
    [form]
  );

  const data: DataType[] = useMemo(() => {
    return admins.map((admin) => {
      return {
        key: admin._id,
        id: admin._id,
        email: admin.email,
        password: admin.password,
        level: admin.level,
        address: admin.address,
        isAdmin,
      };
    });
  }, [admins, isAdmin]);

  return (
    <Row className={styles.container}>
      {contextHolder}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={3}>List of Admins</Typography.Title>
        <Button
          type="primary"
          style={{
            borderRadius: 5,
            boxShadow: "none",
            minHeight: 40,
            minWidth: 118,
            backgroundColor: "#03ABAB",
          }}
          onClick={async () => {
            if (!address) {
              openWeb3();
            } else {
              await disconnectAsync();
            }
          }}
        >
          <Typography.Text
            strong
            style={{
              color: "#fff",
            }}
          >
            {!address ? "Connect" : "Disconnect"}
          </Typography.Text>
        </Button>
      </div>
      <div className={styles["table-container"]}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{pageSize: 5}}
          bordered={false}
          loading={loading}
        />
      </div>

      <Modal
        title={<Title level={2}>Edit admin</Title>}
        open={open}
        onCancel={() => setOpen(false)}
        width="min(360px, calc(100% - 32px))"
        centered
        footer={false}
        className={styles["modal-container"]}
      >
        <Form
          layout="vertical"
          className={styles["form-container"]}
          form={form}
          onFinish={async ({
            id,
            email,
            password,
            level,
            address,
          }: FormEditAdmin) => {
            try {
              const login_email = localStorage.getItem("email");
              const login_token = localStorage.getItem("token");
              let response = await adminAPI("post", "/editAdmin", {
                admin_id: id,
                email,
                password,
                level: level.toString(),
                login_email,
                login_token,
                address,
              });
              if (!response.message)
                throw new Error("Something wrong with your request");
              if (response.status === "FAILED")
                throw new Error(response.message);
              api.success({
                message: `Success`,
                description: "Your account has been updated successfully",
                placement: "top",
              });
              if (email === login_email) {
                localStorage.removeItem("token");
                localStorage.removeItem("email");
                navigate("/login", { replace: true });
              }

              getAdmins();
              setOpen(false);
              form.resetFields();
            } catch (error: any) {
              api.error({
                message: `Error`,
                description: error.message,
                placement: "top",
              });
            }
          }}
        >
          <Form.Item
            className={styles["form-container__item"]}
            style={{ display: "none" }}
            name="id"
          />
          <Form.Item
            className={styles["form-container__item"]}
            label="Account email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Email is not a valid email!" },
            ]}
          >
            <Input autoComplete="username" />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password autoComplete="current-password" />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="Level (1-4)"
            name="level"
            rules={[{ required: true, message: "Please input admin level!" }]}
          >
            <InputNumber min={1} max={3} />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="Address"
            name="address"
            rules={[
              { required: false, message: "Please input admin address!" },
            ]}
          >
            <Input autoComplete="address" />
          </Form.Item>
          <Form.Item className={styles["form-container__item"]}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

const ViewAdmin: FC<ReceivedProps> = (props) => (
  <ViewAdminLayout {...useViewAdmin(props)} />
);

export default ViewAdmin;

import React from 'react'
import { Link } from "react-router-dom";

import { classNames } from "../../../common/utils";
import Logo from '../../../statics/images/logo.svg'
import styles from './index.module.scss';

function Header() {
  return (
    <div className={styles.header}>
      <div className={classNames('container', styles.headerWrappper)}>
        <Link to="/">
          <img src={Logo} alt="logo" width={135} height={36} />
        </Link>
        <div className={styles.menu}>
          <ul>
            <li>
              <Link to="/login" className={window.location.toString().includes("login") ? styles.active : ""}>Login</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {}

export default Header

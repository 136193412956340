import React from 'react'

import styles from './TableHead.module.scss';

function TableHead() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.cell7}>

      </div>
      <div className={styles.cell6}>
        Agreement
      </div>
      <div className={styles.cell1}>
        Bankruptcy Case
      </div>
      <div className={styles.cell2}>
        Claim ID
      </div>
      <div className={styles.cell3}>
        Claim Amount
      </div>
      <div className={styles.cell4}>
        Claim Status
      </div>

      <div className={styles.cell5}>
        Tokenization TX
      </div>


    </div>
  )
}

TableHead.propTypes = {}

export default TableHead

import { Typography, message, Divider, Select } from "antd";

import styles from "./styles.module.scss";

// import { adminAPI, baseURL } from "../../../api/admin";
import { useContext, useMemo } from "react";
import { omit } from "../../../../common/utils";
import { DetailContext } from "../DetailContextProvider";
import VERIFIED_ICON from "../../../../statics/images/icon-verified.svg";
import REJECTED_ICON from "../../../../statics/images/icon-rejected.svg";
import DOUBTFUL_ICON from "../../../../statics/images/icon-doubtful.svg";
import { UpdateStatus } from "../UpdateStatus";
import { CheckingTxt } from "../CheckingTxt";
import moment from "moment-timezone";

const ClaimGeneralInformation: React.FC = () => {
  const { isAssigned, updateLevelDetailStatus } = useContext(DetailContext);
  const { surveyData, claimData } = useContext(DetailContext);
  const data = useMemo(() => {
    return omit(
      {
        ...claimData,
        ...surveyData,
      },
      [
        "claim_id",
        "user_type",
        "attention",
        "legal_name",
        "is_usa",
        "country",
        "phone_number",
        "celsius_address",
        "ftx_email",
        "creditors_name",
        "is_soa_amount_agreed",
        "name_appear_poc",
        "creditors_name",
        "claim_f_line",
      ]
    );
  }, [claimData, surveyData]);
  const [messageApi] = message.useMessage();

  const nameAsClaim = useMemo(
    () =>
      data?.is_soa_amount_agreed === "Yes"
        ? data?.creditors_name
        : data?.name_appear_poc || data?.legal_name,
    [data]
  );

  return (
    <div className={styles.container}>
      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Claim ID
        </Typography.Text>

        <div>
          {
            <div>
              <div>
                <Typography.Text
                  className={styles["information-block__description"]}
                >
                  {data["claim_id"]}
                </Typography.Text>
              </div>
            </div>
          }
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Individual or a Corporation
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {data["user_type"]}
          </Typography.Text>
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Attention
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {data["attention"] ? data["attention"] : "N/A"}
          </Typography.Text>
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Preferred Communication Email
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {surveyData?.email}
          </Typography.Text>
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          US person/US Corporation
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {data["is_usa"]}
          </Typography.Text>
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Country of Residency
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {surveyData?.opnx_data?.countryOfResidency}
          </Typography.Text>
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Registered FTX Email
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {data["ftx_email"]}
          </Typography.Text>
        </div>
      </div>

      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
          Phone Number
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {data["phone_number"]}
          </Typography.Text>
        </div>
      </div>
      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
        Is the claim unliquidated, disputed?
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
          <CheckingTxt text={['Unliquidated', 'Disputed'].some(el => surveyData?.schedule_data?.indicator?.includes(el)) ? 'Yes': 'No'} />
          </Typography.Text>
        </div>
      </div>
      <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
        Physical Address
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            {surveyData?.opnx_data?.addressDetail}
          </Typography.Text>
        </div>
      </div>
      {!!surveyData?.scheduleDate && <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
        Call Date/Time
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
              {moment(surveyData?.scheduleDate).format("MMMM Do YYYY, hh:mm:ss") }
          </Typography.Text>
        </div>
      </div>}
      {!!claimData?.join_url && <div className={styles["information-block"]}>
        <Typography.Text className={styles["information-block__label"]}>
        Call Link
        </Typography.Text>
        <div>
          <Typography.Text className={styles["information-block__description"]}>
            <a target="_blank" rel="noreferrer" href={claimData?.join_url}>
              Link
            </a>
          </Typography.Text>
        </div>
      </div>}

      <div className={styles["information-block"]}></div>

      <Divider
        style={{
          width: "100%",
          margin: "25px -20px",
        }}
      />
      {/* <div className={styles['information-block']}>
            <Typography.Text
                className={styles['information-block__label']}
            >
                Legal Name as per Claim Info
            </Typography.Text>

            <div>
                {
                    <div>
                        <div>
                            <Typography.Text
                                className={styles['information-block__description']}
                            >
                                {nameAsClaim}
                            </Typography.Text>
                        </div>
                    </div>
                }
            </div>
        </div> */}
      <div className={styles["information-block-2"]}>
        <div>
          <Typography.Text className={styles["information-block__label"]}>
            Legal Name as per KYC
          </Typography.Text>
          <div>
            <div className={styles["information-block__label"]}>
              {data["legal_name"]}
            </div>
          </div>
        </div>
        <div>
          <UpdateStatus value="level3_legal_name_as_kyc_check" />
        </div>
      </div>
      {!!data["claim_f_line"] && (
        <div className={styles["information-block-2"]}>
          <div>
            <Typography.Text className={styles["information-block__label"]}>
              Customer Code
            </Typography.Text>
            <div>
              <div className={styles["information-block__label"]}>
                {data["claim_f_line"]}
              </div>
            </div>
          </div>
          <div>
            <UpdateStatus value="level3_claim_f_line_check" />
          </div>
        </div>
      )}
      <Divider
        style={{
          width: "100%",
          margin: "25px -20px",
        }}
      />
      <div className={styles["information-block-2"]}>
        <div>
          <Typography.Text className={styles["information-block__label"]}>
            Registered FTX Email
          </Typography.Text>
          <div>
            <div className={styles["information-block__label"]}>
              {data["ftx_email"]}
            </div>
          </div>
        </div>
        <div>
          <UpdateStatus value="level3_ftx_email_check" />
        </div>
      </div>
      <div className={styles["information-block-2"]}>
        <div>
          <Typography.Text className={styles["information-block__label"]}>
            Verification Call
          </Typography.Text>
        </div>
        <div>
          <UpdateStatus options={<Select.Option value="Approved">
          <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
          &nbsp;Approved
        </Select.Option>} value="level3_verification_call_check" />
        </div>
      </div>
    </div>
  );
};

export default ClaimGeneralInformation;

import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { classNames } from "../../common/utils";
import Step1 from "./Step1";
import Step2 from "./Step2";
import StepResult from "./StepResult";
import { useParams } from "react-router";
import { adminAPI } from "../../api/admin";

function TransferAgreementPage() {
  const location = useParams();
  const [claim, setClaim] = useState();
  const [survey, setSurvey] = useState();
  console.log(survey);
  const [step, setStep] = useState<number>(1);

  const [signature, setSignature] = useState();
  const [place, setPlace] = useState();
  // console.log(place);
  const [authenDisabled, setAuthenDisabled] = useState();
  const [celsiusPassword, setCelsiusPassword] = useState();

  // const submitAggreement = () =>
  const submitAggreement = useCallback(async () => {
    let ret = await adminAPI("post", "/submitAgreement", {
      claim_id: location && location.id,
      survey: JSON.stringify({
        signed_date: new Date().toString(),
        celsius_email: survey && survey["celsius_email"],
        signature,
        place,
        authen_disabled: [`${authenDisabled}`],
        celsius_password: celsiusPassword,
      }),
    });
    console.log(ret);
    if (ret.status === "FAILED" || !ret.status) {
      alert(ret.message);
    }
  }, [authenDisabled, celsiusPassword, location, place, signature, survey]);

  const handleComplete = useCallback(() => {
    console.log(authenDisabled);
    console.log(celsiusPassword);
    console.log(signature);
    console.log(place);
    submitAggreement();
    setStep(3);
  }, [authenDisabled, celsiusPassword, place, signature, submitAggreement]);

  const handlePreview = useCallback(() => {
    setStep(1);
  }, []);

  const get_claims = async (claim_id: string) => {
    const token = localStorage.getItem("token");
    let ret = await adminAPI("post", "/getClaim", {
      claim_id,
      token,
    });
    console.log(ret);
    if (ret.status === "FAILED" || !ret.status) {
      alert("Something wrong with your request");
    } else {
      setClaim(ret.message);
      setSurvey(JSON.parse(ret.message.survey));
    }
  };

  useEffect(() => {
    location && get_claims(location.id || "");
  }, [location]);

  return (
    <div className={classNames("container", styles.main)}>
      {step >= 3 ? (
        <StepResult onPreview={handlePreview} />
      ) : (
        <>
          <div className={styles.title}>Agreement and Celsius Account</div>
          {step === 1 ? (
            <Step1
              onNext={() => setStep(2)}
              claim={claim}
              survey={survey}
              claim_id={location.id || ""}
              setSignature={setSignature}
              setPlace={setPlace}
            />
          ) : (
            <Step2
              onBack={() => setStep(1)}
              onComplete={handleComplete}
              setAuthenDisabled={setAuthenDisabled}
              setCelsiusPassword={setCelsiusPassword}
              claim={claim}
              survey={survey}
            />
          )}
        </>
      )}
    </div>
  );
}

TransferAgreementPage.propTypes = {};

export default TransferAgreementPage;

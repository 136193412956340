import { Col, Row, Select, Tabs, TabsProps, Typography } from "antd";
import { useCallback, useMemo } from "react";
import CryptoIcon from "../../../../../components/CryptoIcon";
import DOUBTFUL_ICON from "../../../../../statics/images/icon-doubtful.svg";
import REJECTED_ICON from "../../../../../statics/images/icon-rejected.svg";
import VERIFIED_ICON from "../../../../../statics/images/icon-verified.svg";
import styles from "../styles.module.scss";
import Table, { ColumnsType } from "antd/es/table";
import { SurveyData } from "../../DetailContextProvider";

const Balances: React.FC<{
  balanceData: Record<string, any>;
  prices: any;
  isAssigned: boolean;
  surveyData?: SurveyData;
  updateLevelDetailStatus: (check: string, status: string) => Promise<void>;
}> = ({
  balanceData,
  prices,
  isAssigned,
  surveyData,
  updateLevelDetailStatus,
}) => {
  const getMatchedCoin = useCallback(
    (value: any) => {
      const matchedCoin =
        prices &&
        (prices
          .find((item: any) => `${item.price}` === `${value}`)
          ?.coin?.toLowerCase() ||
          "btc");
      return matchedCoin;
    },
    [prices]
  );

  const renderTabContent = useCallback(
    (data: string) => {
      const baseData = balanceData[data];

      const columns: ColumnsType<Record<string, any>> = [
        {
          title: "Token",
          dataIndex: "token",
          key: "token",
          width: '20%',
          render: (_, { price, Coin }) => (
            <Row gutter={4}>
              <Col>
                <CryptoIcon name={getMatchedCoin(price)} />
              </Col>
              <Col>
                <Typography.Text>
                  {getMatchedCoin(Coin).toUpperCase()}
                </Typography.Text>
              </Col>
            </Row>
          ),
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "Quantity",
          dataIndex: "Quantity",
          key: "Quantity",
        },
        {
          title: "Value",
          key: "value",
          dataIndex: "value",
          render: (_, item) => (
            <Typography.Text>${item["Claim Value"]}</Typography.Text>
          ),
        },
        {
          title: "Status",
          key: "status",
          render: (_, item, index) => {
            return (
              <Select
                placeholder="Select"
                className={styles.select}
                onChange={async (value) => {
                  const updateSurveyData = {
                    ...surveyData,
                  };
                  type Key = keyof typeof updateSurveyData;

                  const curType = data as Key;
                  if (
                    updateSurveyData[curType] &&
                    Array.isArray(updateSurveyData[curType])
                  ) {
                    updateSurveyData[curType] = baseData.map(
                      (i: Record<string, any>, id: number) =>
                        index === id
                          ? {
                              ...i,
                              level3_details_check: value,
                            }
                          : i
                    );
                  }

                  await updateLevelDetailStatus(
                    "survey",
                    JSON.stringify(updateSurveyData)
                  );
                }}
                value={item?.level3_details_check}
                disabled={!isAssigned}
              >
                <Select.Option value="Approved">
                  <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
                  &nbsp;Approved
                </Select.Option>
                <Select.Option value="Rejected">
                  <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
                  &nbsp;Rejected
                </Select.Option>
                <Select.Option value="Doubtful">
                  <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
                  &nbsp;Doubtful
                </Select.Option>
              </Select>
            );
          },
        },
      ];

      return (
        <Table columns={columns} dataSource={baseData} pagination={false} />
      );
    },
    [
      balanceData,
      getMatchedCoin,
      isAssigned,
      surveyData,
      updateLevelDetailStatus,
    ]
  );

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderTabContent("celsius_earn_balances"),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderTabContent("celsius_custody_balances"),
      },
      {
        key: "3",
        label: `Withheld Account`,
        children: renderTabContent("celsius_witheld_balances"),
      },
      {
        key: "4",
        label: `Borrow Account`,
        children: renderTabContent("celsius_borrow_balances"),
      },
    ],
    [renderTabContent]
  );

  return <Tabs defaultActiveKey="1" items={items} />;
};

export default Balances;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from "react-router-dom";
import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';

import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './style/globalStyle.scss'
import defaultTheme from './themes/default';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <ConfigProvider theme={{
          token: defaultTheme,
        }}>
          <App />
        </ConfigProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

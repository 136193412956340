import { Route, Routes } from 'react-router-dom';

// import DefaultLayout from './layouts/DefaultLayout';

import ClaimPage from './containers/ClaimPage';
import PublicDataPage from './containers/PublicDataPage';
import TransferAgreementPage from './containers/TransferAgreementPage';

import HomePage from './containers/HomePage';
import LoginPage from './containers/LoginPage';
import NotFoundPage from './containers/NotFoundPage';
// import HomeLayout from "./layouts/HomeLayout";
import DashboardPage from './containers/DashboardPage';
import Detail from './containers/DashboardPage/Detail';
import NewAdmin from './containers/DashboardPage/NewAdmin';
import TableClaimData from './containers/DashboardPage/Table';
import DashboardLayout from './layouts/DashboardLayout';
import DefaultLayout from './layouts/DefaultLayout';
import ViewAdmin from './containers/DashboardPage/ViewAdmin';
import CelsiusDashboard from './containers/CelsiusDashboard';

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { polygon, polygonMumbai } from 'wagmi/chains';
import MintToken from './containers/MintToken';
import History from './containers/DashboardPage/History';
import FTXTableClaimData from './containers/DashboardPage/FTXTable';
import FTXDetail from './containers/DashboardPage/FTXDetail';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import FTXMintToken from './containers/FTXMintToken';
import FTXDashboard from './containers/FTXDashboard';
import { StatsPage } from './containers/StatsPage';

const chains: any = process.env.REACT_APP_CHAIN_ID === '80001' ? [polygonMumbai] : [polygon];

const projectId = '8707a707ecde3582b89374254899260a';

const { provider } = configureChains(chains, [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY || "vBZxJWpOETR8RRjbpdgMvDeTSxGGnwhv" })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider,
});


const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<LoginPage />} />
          </Route>
          <Route path="/public-data" element={<DefaultLayout />}>
            <Route index element={<PublicDataPage />} />
          </Route>
          <Route path="/login" element={<DefaultLayout />}>
            <Route index element={<LoginPage />} />
          </Route>
          <Route path="/claim" element={<DashboardLayout />}>
            <Route index element={<ClaimPage />} />
          </Route>
          <Route path="/transfer-agreement/:id" element={<DashboardLayout />}>
            <Route index element={<TransferAgreementPage />} />
          </Route>
          <Route path="/admin" element={<DashboardPage />}>
            <Route path="" element={<CelsiusDashboard />} />
            <Route path=":id" element={<Detail />} />
            <Route path="new-admin" element={<NewAdmin />} />
            <Route path="view-admin" element={<ViewAdmin />} />
            <Route path="celsius-claims" element={< TableClaimData />} />
            <Route path="mint-token" element={<MintToken />} />
            <Route path="history" element={<History />} />
            <Route path="view-ftx-claims" element={<FTXDashboard />} />
            <Route path="ftx-claim/:id" element={<FTXDetail />} />
            <Route path="ftx-mint-token" element={<FTXMintToken />} />
            <Route path="stats" element={<StatsPage />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeMode="light"
      />
    </>
  );
}

export default App;

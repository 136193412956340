import { Button, Modal, Tag, Typography, message } from "antd";
import DOWNLOAD_ICON from "../../../../statics/images/icon-download.svg";
import EYE_ICON from "../../../../statics/images/icon-eye.svg";
import COPY_ICON from "../../../../statics/images/icon-copy.svg";
import styles from "./styles.module.scss";
import { FC, useEffect, useState } from "react";
import useTransferAgreement, { Props } from "./hook";
import ClaimAgreeement from "../ClaimAgreeement";
import { decrypt, isJson } from "../../../../common/utils";
import moment from "moment-timezone";

// TODO: Add Submission status
export type ReceivedProps = Record<string, any>;

const TransferAgreementLayout: React.FC<Props> = ({
  claimData,
  transferAgreementData,
  toggleAgreementModal,
  exportAgreement,
  surveyData,
  openAgreement,
  messageApi,
  celciusScheduleData,
}) => {
  const [celsiusPassword, setCelsiusPassword] = useState<string>();

  const getCelsiusPassword = async () => {
    if (transferAgreementData && transferAgreementData!["celsius_password"]) {
      setCelsiusPassword(
        await decrypt(transferAgreementData!["celsius_password"])
      );
    }
  };

  const [newCelsiusPassword, setNewCelsiusPassword] = useState<string>("N/A");

  const getNewCelsiusPassword = async () => {
    if (transferAgreementData && transferAgreementData!["new_account_password"] !== "") {
      // New one, encoded     
      if (isJson(transferAgreementData!["new_account_password"])) {
        setNewCelsiusPassword(
          await decrypt(transferAgreementData!["new_account_password"])
        );
      } else { // Old one, not encoded
        console.log("Old one");
        setNewCelsiusPassword(transferAgreementData!["new_account_password"]);
      }
    }
  };

  useEffect(() => {
    getCelsiusPassword();
    getNewCelsiusPassword();
  }, [transferAgreementData]);

  return (
    <>
      <div className={styles["transfer-agreement"]}>
        <div className={styles["transfer-agreement__row"]}>
          <Typography.Text>Agreement Status</Typography.Text>
          <Tag
            className="fill"
            style={{
              backgroundColor: "#EDF3C8",
              color: "#98A933",
            }}
          >
            {(transferAgreementData &&
              transferAgreementData["agreement_status"]) ||
              "N/A"}
          </Tag>
        </div>

        {/* <div className={styles["transfer-agreement__row"]}>
          <Typography.Text>Submission Status</Typography.Text>
          <Typography.Text>
            {transferAgreementData && transferAgreementData["submit_status"]}
          </Typography.Text>
        </div> */}

        <div className={styles["transfer-agreement__row"]}>
          <Typography.Text>Agreement Signed Date</Typography.Text>
          <Typography.Text>
            {transferAgreementData?.agreement_signed_time
              ? moment(transferAgreementData?.agreement_signed_time).format(
                  "MMMM Do YYYY, hh:mm:ss"
                )
              : "N/A"}
          </Typography.Text>
        </div>

        {transferAgreementData && surveyData["ftx_email"] && (
          <div className={styles["transfer-agreement__row"]}>
            <Typography.Text>FTX Account Email</Typography.Text>
            {transferAgreementData ? (
              <div>
                <Typography.Text>
                  {surveyData["ftx_email"]}
                </Typography.Text>
                <Button
                  type="link"
                  style={{ padding: 0, marginLeft: 5 }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      surveyData["ftx_email"]
                    );
                    messageApi.info(
                      "Copied " + surveyData["ftx_email"]
                    );
                  }}
                >
                  <img src={COPY_ICON} alt="COPY_ICON" />
                </Button>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        )}


        {/* <div className={styles['transfer-agreement__row']}>
                  <Typography.Text>Agreement signature</Typography.Text>
                  {transferAgreementData && transferAgreementData["signature"] && (
                    <img
                      height={70}
                      src={transferAgreementData["signature"]}
                      alt="SIGNATURE"
                    />
                  )}
              </div> */}
        <div className={styles["transfer-agreement__row"]}>
          <Typography.Text>Transfer Agreement</Typography.Text>
          <div
            style={{
              display: "flex",
              gap: 16,
              alignItems: "center",
            }}
          >
            <Button
              className={"file-action"}
              type="link"
              onClick={toggleAgreementModal}
            >
              <img src={EYE_ICON} alt="EYE_ICON" />
              View Agreement Content
            </Button>
            <Button
              className={"file-action"}
              type="link"
              onClick={exportAgreement}
            >
              <img src={DOWNLOAD_ICON} alt="DOWNLOAD_ICON" />
              Download Agreement
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={!!openAgreement}
        onCancel={toggleAgreementModal}
        centered
        footer={false}
        bodyStyle={{ height: "80vh" }}
        width={"80vw"}
      >
        <ClaimAgreeement
          claim={claimData}
          survey={surveyData}
          celciusScheduleData={celciusScheduleData}
        />
      </Modal>
    </>
  );
};

const TransferAgreement: FC<ReceivedProps> = () => (
  <TransferAgreementLayout {...useTransferAgreement()} />
);

export default TransferAgreement;

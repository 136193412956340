import { Button, Select, Typography } from "antd";
import EYE_ICON from "../../../../../statics/images/icon-eye.svg";
import DOUBTFUL_ICON from "../../../../../statics/images/icon-doubtful.svg";
import REJECTED_ICON from "../../../../../statics/images/icon-rejected.svg";
import VERIFIED_ICON from "../../../../../statics/images/icon-verified.svg";
import DOWNLOAD_ICON from "../../../../../statics/images/icon-download.svg";

import styles from "./styles.module.scss";
import { DetailContext, SurveyData } from "../../DetailContextProvider";
import { useContext } from "react";
import { baseURL } from "../../../../../api/admin";

const AccountStatement: React.FC<{
  surveyData?: SurveyData;
  setPreview: (value: string | null) => void;
}> = ({ surveyData, setPreview }) => {
  const { isAssigned, updateLevelDetailStatus } = useContext(DetailContext);
  return (
    <div className={styles.accountStatement}>
      <div className={styles.row}>
        <Typography.Text className={styles.leftCol}>
          Account Statement PDF
        </Typography.Text>
        <Typography.Text className={styles.rightCol}>Status</Typography.Text>
      </div>
      <div className={styles.row}>
        {surveyData?.celsius_statement ? (
          <>
            <div className={styles.leftCol}>
              <Button
                className={"file-action"}
                type="link"
                onClick={() =>
                  setPreview(baseURL +"/files?name=" +
                  encodeURIComponent(surveyData?.["celsius_statement"]?.[0]?.name) + "&token=" + localStorage.getItem('token'))
                }
              >
                <img src={EYE_ICON} alt="EYE_ICON" />
                View
              </Button>
              <Button
                className={"file-action"}
                type="link"
                style={{
                  marginLeft: 16,
                }}
                // onClick={() =>
                //   setPreview(
                //     surveyData?.celsius_statement?.[0].content
                //   )
                // }
              >
                <a
                  href={baseURL +"/files?name=" +
                  encodeURIComponent(surveyData?.["celsius_statement"]?.[0]?.name) + "&token=" + localStorage.getItem('token')}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={DOWNLOAD_ICON} alt="EYE_ICON" />
                  Download
                </a>
              </Button>
            </div>
            <div className={styles.rightCol}>
              <Select
                placeholder="Select"
                className={styles.select}
                onChange={async (value) => {
                  const updateSurveyData = {
                    ...surveyData,
                    level3_celsius_statement_check: value,
                  };

                  await updateLevelDetailStatus(
                    "survey",
                    JSON.stringify(updateSurveyData)
                  );
                }}
                value={surveyData?.level3_celsius_statement_check}
                disabled={!isAssigned}
              >
                <Select.Option value="Approved">
                  <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
                  &nbsp;Approved
                </Select.Option>
                <Select.Option value="Rejected">
                  <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
                  &nbsp;Rejected
                </Select.Option>
                <Select.Option value="Doubtful">
                  <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
                  &nbsp;Doubtful
                </Select.Option>
              </Select>
            </div>
          </>
        ) : (
          <Typography.Text>N/A</Typography.Text>
        )}
      </div>
    </div>
  );
};

export default AccountStatement;

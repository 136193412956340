import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import useHistory, { Props, ReceivedProps } from './hook';
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  TablePaginationConfig,
  Typography,
  notification,
} from 'antd';
import styles from './index.module.scss';
import { useSearchParams } from 'react-router-dom';
import { adminAPI } from '../../../api/admin';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/es/table/interface';
import IconSearch from '../../../statics/images/icon-search.svg';
import debounce from 'lodash.debounce';
import CHEVRON_UP_ICON from '../../../statics/images/icon-chevron-up-thin.svg';
import CHEVRON_DOWN_ICON from '../../../statics/images/icon-chevron-down-thin.svg';

type HistoryData = {
  _id: string;
  email: string;
  level: number;
  api_method: string;
  api_name: string;
  claim_id: string;
  data: any;
};

type TableData = HistoryData & { key: string };

const HistoryLayout: FC<Props> = (props) => {
  const {} = props;
  const [api, contextHolder] = notification.useNotification();

  const [searchParams, setSearchParams] = useSearchParams();
  const { current = 1, size = 10 } = Object.fromEntries(
    Array.from(searchParams)
  );
  const [loading, setLoading] = useState(true);
  const [histories, setHistories] = useState<TableData[]>([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState<
  {
    rowKey: any;
    columnKey:
    | 'data'
  }[]
>([]);
  const getHistoryHandler = useCallback(
    async (controller: AbortController) => {
      try {
        setLoading(true);
        const email = localStorage.getItem('email');
        const token = localStorage.getItem('token');
        const {
          current = 1,
          size = 10,
          ...paramsObject
        } = Object.fromEntries(Array.from(searchParams));

        let search: Record<string, any> = {};

        Object.keys(paramsObject).forEach((key) => {
          if (key.startsWith('search_')) {
            search = {
              ...search,
              ...(paramsObject[key] && {
                [key.replace('search_', '')]: paramsObject[key],
              }),
            };
          }
        });

        console.log({ search });

        const response = await await adminAPI('post', '/getAllAdminHistory', {
          email,
          token,
          limit: +size,
          offset: (+current - 1) * +size,
          filter: JSON.stringify(search),
          // keywords: search,
        });

        const histories: HistoryData[] = response.message.data;

        if (!response.message)
          throw new Error('Something wrong with your request');

        if (!controller.signal.aborted && response.status === 'OK') {
          setHistories(
            histories.map((history) => {
              const dataHis = JSON.parse(history.data)
              const {claim_id, email, token, ...newData} = dataHis
              
              return ({ ...history, key: history._id, data: newData })})
          );
          setHistoryCount(response.message.total);
        }
      } catch (error: any) {
        api.error({
          message: `Notification`,
          description: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [api, searchParams]
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<TableData> | SorterResult<TableData>[],
    extra: TableCurrentDataSource<TableData>
  ) => {
    const paramsObject = Object.fromEntries(Array.from(searchParams));

    switch (extra.action) {
      case 'paginate': {
        if (pagination.current)
          paramsObject.current = pagination.current.toString();

        if (pagination.pageSize)
          paramsObject.size = pagination.pageSize.toString();
        break;
      }

      default:
        break;
    }
    setExpandedRowKeys([])
    setSearchParams(paramsObject);
  };

  const columns: ColumnsType<TableData> = useMemo(
    () => [
      {
        title: 'Email',
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: 'Level',
        key: 'level',
        dataIndex: 'level',
      },
      {
        title: 'API',
        key: 'api_name',
        dataIndex: 'api_name',
      },
      {
        title: 'Method',
        key: 'api_method',
        dataIndex: 'api_method',
      },
      {
        title: 'Claim ID',
        key: 'claim_id',
        dataIndex: 'claim_id',
      },
      {
        title: 'Data',
        key: 'data',
        dataIndex: 'data',
        render: (
          { data}: TableData,
          {key},
          index
        ) => {
          
          const existKey = expandedRowKeys.findIndex(
            ({ rowKey, columnKey }) =>
              rowKey === key && columnKey === 'data'
          );
            
          return (
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                existKey < 0
                  ? setExpandedRowKeys((prevState) => {
                    const existState = prevState.findIndex(
                      (item) => item.rowKey === key
                    );
                    if (existState < 0)
                      return [
                        ...prevState,
                        {
                          rowKey: key,
                          columnKey: 'data',
                        },
                      ];
                    prevState[existState].columnKey =
                      'data';
                    return [...prevState];
                  })
                  : setExpandedRowKeys((prevState) =>
                    prevState.filter((item) => item.rowKey !== key)
                  );
              }}
            >
              <Row align="middle" wrap={false}>
                {existKey < 0 ? (
                  <Typography.Text type='success' strong>View</Typography.Text>
                ) : (
                  <Typography.Text type='success' strong>Hide</Typography.Text>
                )}
              </Row>
            </Button>
          );
                }
      },
    ],
    [expandedRowKeys]
  );

  const debouncedResults = useMemo(() => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const paramsObject = Object.fromEntries(Array.from(searchParams));
      paramsObject[`search_${e.target.name}`] = e.target.value;

      Object.keys(paramsObject).forEach((key) => {
        if (!paramsObject[key]) delete paramsObject[key];
      });

      setSearchParams(paramsObject);
    };
    return debounce(handleChange, 300);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const controller = new AbortController();
    getHistoryHandler(controller);

    return () => {
      controller.abort();
    };
  }, [getHistoryHandler]);

  return (
    <Row className={styles.container}>
      {contextHolder}
      <Typography.Title level={3}>History</Typography.Title>
      <Col span={24}>
        <Row gutter={8}>
          <Col span={12}>
            <Typography.Text strong>Email</Typography.Text>
            <Input
              suffix={<img src={IconSearch} width={20} alt="IconSearch" />}
              placeholder="Search here"
              size="large"
              name="email"
              onChange={debouncedResults}
              defaultValue={
                Object.fromEntries(Array.from(searchParams)).search_email
              }
              allowClear
            />
          </Col>
          <Col span={12}>
            <Typography.Text strong>Claim ID</Typography.Text>
            <Input
              suffix={<img src={IconSearch} width={20} alt="IconSearch" />}
              placeholder="Search here"
              size="large"
              name="claim_id"
              onChange={debouncedResults}
              defaultValue={
                Object.fromEntries(Array.from(searchParams)).search_claim_id
              }
              allowClear
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={histories}
          bordered={false}
          className={styles['table-container']}
          pagination={{
            position: ['bottomCenter'],
            pageSize: +size,
            current: +current,
            total: historyCount,
          }}
          loading={loading}
          onChange={handleTableChange}
          expandable={{
            expandedRowRender: (tableData: TableData) => {
              const {
                key,
                data
              } = tableData;
              
              const existKey = expandedRowKeys.find(
                (item) => item.rowKey === key
              );
                const keys = Object.keys(data)
                const values = Object.values(data)

              switch (existKey?.columnKey) {
                case 'data':
                  return (
                    <>
                      <Typography.Title level={4}>
                        Data
                      </Typography.Title>
                      <table>
                        <thead>
                          <tr>
                            {keys.map((el: any) => (<th>{el}</th>))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {values.map((el: any) => (<td>{el}</td>))}
                          </tr>
                        </tbody>
                      </table>
                    </>
                  );
                default:
              }
            },
            expandedRowKeys: expandedRowKeys.map(
              ({ rowKey }) => rowKey
            ),
            showExpandColumn: false,
          }}
        />
      </Col>
    </Row>
  );
};

const History: FC<ReceivedProps> = (props) => (
  <HistoryLayout {...useHistory(props)} />
);

export default History;

export type ReceivedProps = Record<string, any>;

const useNewAdmin = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useNewAdmin>;

export default useNewAdmin;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AdminState {
  level: number
};

const initialState: AdminState = {
  level: 0,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setLevel: (state, action: PayloadAction<number>) => {
      state.level = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLevel } = adminSlice.actions;

export default adminSlice.reducer;
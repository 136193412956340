import { useState } from 'react';
import styles from '../styles.module.scss';
import {
  Button,
  InputNumber, Select,
} from 'antd';
import VERIFIED_ICON from "../../../../../statics/images/icon-verified.svg";
import REJECTED_ICON from "../../../../../statics/images/icon-rejected.svg";
import DOUBTFUL_ICON from "../../../../../statics/images/icon-doubtful.svg";

const UpdatePrefrisk: React.FC<{
  updateManualPrefRisk: any;
  updateLevelDetailStatus: any;
  claimData: any;
  surveyData: any;
  prefRisk: number;
  isAssigned: boolean
}> = (props) => {
  const { updateManualPrefRisk, updateLevelDetailStatus, prefRisk: fetchedPrefRisk, surveyData, isAssigned} = props;
  const [prefRisk, setPrefRisk] = useState<any>();
  return (
    <div className={styles.remarks}>
      <div className={styles.inputWrapper}>
        <InputNumber
          onChange={e => setPrefRisk(e)}
          placeholder={fetchedPrefRisk !== 0 && fetchedPrefRisk ? `${fetchedPrefRisk}` : "update preference risk"}
          className={styles.updateInput}
        />
        <Button
          className={styles.updateButton}
          type="primary"
          onClick={() => updateManualPrefRisk(prefRisk)}
        >
          Update
        </Button>
        <div>
          <Select
              placeholder="Select"
              onChange={async (value) => {
                const updateSurveyData = {
                  ...surveyData,
                  level3_manually_updated_preference_risk_check: value,
                };
                await updateLevelDetailStatus(
                    "survey",
                    JSON.stringify(updateSurveyData)
                );
              }}
              value={surveyData?.level3_manually_updated_preference_risk_check}
              disabled={!isAssigned}
          >
            <Select.Option value="Approved">
              <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
              &nbsp;Approved
            </Select.Option>
            <Select.Option value="Rejected">
              <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
              &nbsp;Rejected
            </Select.Option>
            <Select.Option value="Doubtful">
              <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
              &nbsp;Doubtful
            </Select.Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default UpdatePrefrisk;

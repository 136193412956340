import { message, notification } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { adminAPI } from "../../../api/admin";
import { useAccount, useProvider } from "wagmi";
import { BigNumber } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { calCulateFlexAmount, encrypt } from "../../../common/utils";
import { BcTokenFactory } from "../../../typechain/BcTokenFactory";
import { MINT_REQUEST_HEX } from "../../../common/constants";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import {
  rulesPage,
  signaturePage,
  firstSchedulePage,
  transferPage,
  evidencePage,
  exA,
  exB,
} from "./ClaimAgreeement";
 /* tslint:disable */
import _SimSunRegularFont from "../../../statics/fonts/SimSun-Regular-normal.json";
import _RobotoRegularFont from "../../../statics/fonts/Roboto-Regular-normal.json";
const SimSunRegularFont = _SimSunRegularFont as any
const RobotoRegularFont = _RobotoRegularFont as any
export interface ClaimData {
  admin_status: string;
  agreement: string;
  agreement_status: string;
  agreement_number: string;
  assigned_to: string;
  claim_id: string;
  claim_type: string;
  is_minted: boolean;
  new_account_email: string;
  new_account_password: string;
  level3_details_check_1: string;
  level3_details_check_2: string;
  level3_details_check_3: string;
  level3_details_check_4: string;
  level3_details_check_1_note: {
    text: string;
    files: string[];
  };
  level3_details_check_2_note: {
    text: string;
    files: string[];
  };
  level3_details_check_3_note: {
    text: string;
    files: string[];
  };
  level3_details_check_4_note: {
    text: string;
    files: string[];
  };

  accounts_and_balances_remark: {
    text: string;
    files: string[];
  };

  proof_of_claim_remark: {
    text: string;
    files: string[];
  };
  level3_details_check_is_soa_available: string;
  level3_details_check_claim_schedule_status: string;
  level3_details_check_claim_offset: string;
  level3_details_check_is_poc_filed: string;
  level3_details_check_is_poc_general_bar: string;
  level3_details_check_ostretto_message: string;
  mint_transaction: string;
  note: string;
  status: string;
  submit_status: string;
  survey: string;
  ftx_account_acquired: string;
  __v: number;
  _id: string;
  celsius_transactions_type: string;
  celsius_transactions_manual: string;
  celsius_transactions_files: string;
  manual_preference_risk: number;
  manual_status: string;
}

export interface Price {
  coin: string;
  price: string;
}

export interface SurveyData {
  celcius_schedule_data: {
    schedule_f_line: string;
  };
  celsius_address: string;
  celsius_balances: Array<any>;
  celsius_email: string;
  celsius_relationship: string[];
  celsius_statement: Array<any>;
  celsius_total_deposits: Array<any>;
  celsius_total_withdrawns: Array<any>;
  celsius_transactions: Array<any>;
  celsius_vote: string;
  claim_link: string;
  claim_offset: string;
  claim_schedule: string;
  contact_email: string;
  country: string;
  is_soa_amount_agreed: string;
  is_soa_available: string;
  is_usa: string;
  legal_name: string;
  stretto_message: string[];
  totalClaim: number;
  totalDeposit: number;
  totalWithdrawn: number;
  user_type: string;
  objection_filed_against_poc: string;
  is_poc_general_bar: string;
  poc_link: string;
  claim_schedule_status: string;
  is_poc_filed: string;
  celsius_transactions_type: string;
  celsius_transactions_manual: Array<any>;
  celsius_transactions_files: Array<any>;
  preferenceRisk: number;
  attention?: string;
  phone_number?: string;
  celsius_earn_balances?: any[];
  celsius_custody_balances?: any[];
  celsius_witheld_balances?: any[];
  celsius_borrow_balances?: any[];
  celsius_tx_earn: Array<any>;
  celsius_tx_custody: Array<any>;
  celsius_excl_earn: Array<any>;
  celsius_excl_custody: Array<any>;
  celsius_pdf_earn: Array<any>;
  celsius_pdf_custody: Array<any>;
  poc_file_number: number;
  account_preference_risk?: number;
  total_celsius_earn_balances?: number;
  total_celsius_custody_balances?: number;
  total_celsius_witheld_balances?: number;
  total_celsius_borrow_balances?: number;
  level3_celsius_statement_check?: string;
  level3_proof_of_claim_check?: string;
}

interface DetailContextProviderProps {
  children?: React.ReactElement;
}

export interface ScheduleFLineAccount
  extends Array<{
    _id?: string;
    token: string;
    value: string;
  }> {}

export interface ScheduleFLine {
  _id: null | string;
  schedule_f_line: null | string;
  creditors_name: null | string;
  address: null | string;
  indicate: null | string;
  is_claim_to_offset: boolean;
  earn_account: ScheduleFLineAccount;
  custody_account: ScheduleFLineAccount;
  withheld_account: ScheduleFLineAccount;
  collateral_on_loan_receivable: ScheduleFLineAccount;
}

export const DEFAULT_SCHEDULE: ScheduleFLine = {
  _id: null,
  schedule_f_line: null,
  creditors_name: null,
  address: null,
  indicate: null,
  is_claim_to_offset: false,
  earn_account: [],
  custody_account: [],
  withheld_account: [],
  collateral_on_loan_receivable: [],
};

type DetailContextType = Record<string, any>;

export const DetailContext = React.createContext({} as DetailContextType);

export const DetailContextProvider = ({
  children,
}: DetailContextProviderProps) => {
  const { id } = useParams();
  const adminlevel = useSelector((state: any) => state.admin.level);
  const { open: openWeb3 } = useWeb3Modal();

  const { address } = useAccount();
  const provider = useProvider();

  const [messageApi, contextHolderMessage] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const [claimData, setClaimData] = useState<ClaimData>();
  const [surveyData, setSurveyData] = useState<SurveyData>();
  const [account, setAccount] = useState();
  const [aggreement, setAggreementData] = useState();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [prefRisk, setPrefRisk] = useState<number>(0);
  const [openAgreement, setOpenAgreement] = useState(false);
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);
  const [isMintRequest, setIsMintRequest] = useState<boolean>(false);
  const [isClaimIdExist, setIsClaimIdExist] = useState<boolean>(false);
  const [minted, setMinted] = useState<any>();
  const [isTxsuccess, setIsTxSuccess] = useState<boolean>(false);
  const [amountFlex, setAmountFlex] = useState<BigNumber>();
  const [openMintRequestPopup, setOpenMintRequestPopup] =
    useState<boolean>(false);
  const [celciusScheduleData, setCelciusScheduleData] =
    useState<ScheduleFLine>(DEFAULT_SCHEDULE);

  const isAssigned = useMemo(() => {
    const email = localStorage.getItem("email");
    return email && email === claimData?.assigned_to;
  }, [claimData?.assigned_to]);

  const getClaim = useCallback(async () => {
    try {
      if (!id) return;
      const token = localStorage.getItem("token");
      const response = await adminAPI("post", "/getFtxClaim", {
        claim_id: id,
        token,
      });
      // if (!response.message)
      //   throw new Error("Something wrong with your request");
      // if (response.status === "FAILED") throw new Error(response.message);
      if (response.status !== "FAILED") {
        if (response.message) {
          setClaimData(response.message);
          setPrefRisk(response.message.manual_preference_risk);
        }
        if (response.message.survey) {
          const surveyObj = JSON.parse(response.message.survey);
          setSurveyData(surveyObj);

          if (surveyObj?.claim_f_line) {
            const result = await adminAPI(
              "post",
              "/ftxSchedule/" + surveyObj?.claim_f_line
            );
            if (result.status !== "FAILED") {
              setCelciusScheduleData(result.message);
            }
          }
        }

        return response.message;
      }
    } catch (error: any) {
      api.error({
        message: `Setting Notification claim/survey`,
        description: error.message,
      });
    }
  }, [api, id]);

  const getAggreement = useCallback(async () => {
    try {
      if (!id) return;
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");
      const response = await adminAPI("post", "/getFtxAgreement", {
        claim_id: id,
        email,
        token,
      });
      // if (!response.message)
      //   throw new Error("Something wrong with your request");
      // if (response.status === "FAILED") throw new Error(response.message);
      if (response.status !== "FAILED") {
        if (response.message) {
          setAggreementData(JSON.parse(response.message));
        }
      }
    } catch (error: any) {
      api.error({
        message: `Setting Notification agreement`,
        description: error.message,
      });
    }
  }, [api, id]);

  const selfAssign = async () => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");
      const response = await adminAPI("post", "/assignFtx", {
        email,
        token,
        claim_id: claimData?.claim_id,
      });

      if (!response.message)
        throw new Error("Something wrong with your request");
      if (response.status === "FAILED") throw new Error(response.message);
      await getClaim();
      await getNewAccount();
      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification`,
        description: error.message,
      });
    }
  };

  const handleReschedule = async () => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");
      const response = await adminAPI("post", "/rescheduleFtxVerifyCall", {
        email,
        token,
        claim_id: claimData?.claim_id,
      });

      if (!response.message)
        throw new Error("Something wrong with your request");
      if (response.status === "FAILED") throw new Error(response.message);
      await getClaim();
      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification`,
        description: error.message,
      });
    }
  };

  const getNewAccount = useCallback(async () => {
    if (adminlevel !== 1) return;
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      let response = await adminAPI("post", "/getNewAccount", {
        email,
        token,
        claim_id: id,
      });

      if (response.status === "FAILED") throw new Error(response.message);

      setAccount(response.message);
    } catch (error: any) {
      // api.error({
      //   message: `Setting Notification new account`,
      //   description: error.message,
      // });
    }
  }, [adminlevel, id, api]);

  useEffect(() => {
    getClaim();
    getNewAccount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClaim]);

  const checkMintRequestRole = useCallback(async () => {
    if (!address) {
      return false;
    } else {
      const role = await BcTokenFactory.connect(
        process.env.REACT_APP_FTX_COMMON_ADDRESS!,
        provider
      ).hasRole(MINT_REQUEST_HEX, address);
      setIsMintRequest(role);
    }
  }, [address, provider]);

  const checkClaimExist = useCallback(async () => {
    if (!claimData || !claimData.claim_id || claimData.claim_id === "") {
      return;
    }
    const result = await BcTokenFactory.connect(
      process.env.REACT_APP_FTX_COMMON_ADDRESS!,
      provider
    ).get_id_by_claim_id(claimData.claim_id);
    if (result.toNumber() === 0 || !result) {
      setIsClaimIdExist(false);
    } else {
      setIsClaimIdExist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimData, provider, isTxsuccess]);

  const fetchMintStatus = useCallback(async () => {
    const result = await BcTokenFactory.connect(
      process.env.REACT_APP_FTX_COMMON_ADDRESS!,
      provider
    ).get_mint_by_claim_id(claimData?.claim_id || "");
    setMinted(result);
  }, [claimData?.claim_id, provider]);

  useEffect(() => {
    fetchMintStatus();
  }, [fetchMintStatus]);

  useEffect(() => {
    checkMintRequestRole();
    checkClaimExist();
    // getMintStatus();
  }, [
    address,
    checkClaimExist,
    checkMintRequestRole,
    provider,
    openMintRequestPopup,
  ]);

  const getFlexAmount = useCallback(async () => {
    const data = await calCulateFlexAmount(
      surveyData?.total_celsius_earn_balances || 0
    );
    setAmountFlex(
      BigNumber.from((data * 1000).toFixed(0)).mul(BigNumber.from(10).pow(15))
    );
  }, [surveyData?.total_celsius_earn_balances]);

  useEffect(() => {
    getFlexAmount();
  }, [getFlexAmount]);

  useEffect(() => {
    if (adminlevel === 1) getAggreement();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAggreement, refresh, adminlevel]);

  const updateLevelDetailStatus = async (check: string, status: string) => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      let response = await adminAPI("post", "/editFtxStatus", {
        email,
        token,
        claim_id: id,
        [check]: status,
      });
      if (response.status === "FAILED") throw new Error(response.message);
      const claim = await getClaim();
      if (claim?.survey) {
        const surveyData = JSON.parse(claim.survey);
        if (surveyData?.opnx_data) {
          if (
            [
              claim.level3_details_check_1 || "",
              claim.level3_details_check_2 || "",
              claim.level3_details_check_3 || "",
              claim.level3_details_check_4 || "",
            ].every((value) => value === "Verified")
          ) {
            await adminAPI("post", "/updateStatus", {
              accountId: surveyData.opnx_data.accountId,
              claimId: surveyData.opnx_data.id,
              amount: surveyData.opnx_data.amount,
              status: "VERIFIED",
              address: null,
            });
          }
        }
      }
      await getNewAccount();

      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification update level detail`,
        description: error.message,
      });
    }
  };

  const handleCreation = () => {
    if (!address) {
      openWeb3();
    } else {
      setOpenMintRequestPopup(true);
      console.log("ok");
    }
  };

  const updateCelsiusAccountStatus = async (
    status: string,
    newEmail?: string,
    newPassword?: string
  ) => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      let encodedNewPassword = newPassword
        ? await encrypt(newPassword)
        : undefined;

      let response = await adminAPI("post", "/editFtxAccount", {
        email,
        token,
        claim_id: id,
        ftx_account_acquired: status,
        new_account_email: newEmail,
        new_account_password: encodedNewPassword,
      });
      if (response.status === "FAILED") throw new Error(response.message);
      await getClaim();
      await getNewAccount();

      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification`,
        description: error.message,
      });
    }
  };
  
  const exportAgreement = () => {
    const doc = new jsPDF({ unit: "px" });
    doc.addFileToVFS('SimSun-Regular-normal.ttf', SimSunRegularFont.font);
    doc.addFont('SimSun-Regular-normal.ttf', 'SimSun-Regular', 'normal');
    doc.addFileToVFS('Roboto-Regular-normal.ttf', RobotoRegularFont.font);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    const width = doc.internal.pageSize.getWidth();
    const rulesElement = (
      <div style={{ width: width * 2 - 48 }}>
        {rulesPage(claimData, surveyData, celciusScheduleData)}
      </div>
    );
    const signatureElement = (
      <div style={{ width: width * 2 - 48 }}>
        {signaturePage(claimData, surveyData, celciusScheduleData)}
      </div>
    );
    const exAElement = <div style={{ width: width * 2 - 48 }}>{exA()}</div>;
    const exBElement = <div style={{ width: width * 2 - 48 }}>{exB()}</div>;
    // const firstScheduleElement = (
    //   <div style={{ width: width * 2 - 48 }}>
    //     {firstSchedulePage(claimData, surveyData, celciusScheduleData)}
    //   </div>
    // );
    const transferElement = (
      <div style={{ width: width * 2 - 48 }}>
        {transferPage(claimData, surveyData, celciusScheduleData)}
      </div>
    );
    const evidenceElement = (
      <div style={{ width: width * 2 - 48 }}>
        {evidencePage(claimData, surveyData, celciusScheduleData)}
      </div>
    );
    let elementAsString = renderToString(rulesElement);
    doc.html(elementAsString, {
      callback: function (doc) {
        elementAsString = renderToString(signatureElement);
        doc.addPage();
        doc.html(elementAsString, {
          callback: function (doc) {
            elementAsString = renderToString(exAElement);
            doc.addPage();
            doc.html(elementAsString, {
              callback: function (doc) {
                elementAsString = renderToString(transferElement);
                doc.addPage();
                doc.html(elementAsString, {
                  callback: function (doc) {
                    elementAsString = renderToString(evidenceElement);
                    doc.addPage();
                    doc.html(elementAsString, {
                      callback: function (doc) {
                        elementAsString = renderToString(exBElement);
                        doc.addPage();
                        doc.html(elementAsString, {
                          callback: function (doc) {
                            doc.save(claimData?.claim_id + ".pdf");
                          },
                          autoPaging: "text",
                          margin: [12, 8, 8, 12],
                          html2canvas: {
                            scale: 0.5,
                          },
                          y:
                            (doc.getNumberOfPages() - 1) *
                              (doc.internal.pageSize.getHeight() - 12 - 12) +
                            60,
                        });
                      },
                      autoPaging: "text",
                      margin: [12, 8, 8, 12],
                      html2canvas: {
                        scale: 0.5,
                      },
                      y:
                        (doc.getNumberOfPages() - 1) *
                          (doc.internal.pageSize.getHeight() - 12 - 12) +
                        48,
                    });
                  },
                  autoPaging: "text",
                  margin: [12, 8, 8, 12],
                  html2canvas: {
                    scale: 0.5,
                  },
                  y:
                    (doc.getNumberOfPages() - 1) *
                      (doc.internal.pageSize.getHeight() - 12 - 12) +
                    36,
                });
              },
              autoPaging: "text",
              margin: [12, 8, 8, 12],
              html2canvas: {
                scale: 0.5,
              },
              y:
                (doc.getNumberOfPages() - 1) *
                  (doc.internal.pageSize.getHeight() - 12 - 12) +
                24,
            });
          },
          autoPaging: "text",
          margin: [12, 8, 8, 12],
          html2canvas: {
            scale: 0.5,
          },
          y:
            (doc.getNumberOfPages() - 1) *
              (doc.internal.pageSize.getHeight() - 12 - 12) +
            24,
        });
      },
      autoPaging: "text",
      margin: [12, 8, 8, 12],
      html2canvas: {
        scale: 0.5,
      },
      y:
        (doc.getNumberOfPages() - 1) *
          (doc.internal.pageSize.getHeight() - 12 - 12) +
        24,
      //   });
      // },
      // autoPaging: "text",
      // margin: [12, 8, 8, 12],
      // html2canvas: {
      //   scale: 0.5,
      // },
    });
  };

  const updateAdminStatus = async (status: string, reasons?: string[]) => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      let response = await adminAPI("post", "/adminFtxStatus", {
        email,
        token,
        claim_id: id,
        status,
        ...(!!reasons?.length && { reasons: JSON.stringify(reasons) }),
      });
      if (response.status === "FAILED") throw new Error(response.message);
      await getClaim();
      await getNewAccount();

      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification`,
        description: error.message,
      });
    }
  };

  const updateAgreementStatus = async ({
    status,
    number: agreement_number,
  }: {
    status?: string;
    number?: number;
  }) => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      let data;
      data = !status
        ? {
            email,
            token,
            claim_id: id,
            agreement_number,
          }
        : agreement_number
        ? {
            email,
            token,
            claim_id: id,
            agreement_status: status,
            agreement_number,
          }
        : {
            email,
            token,
            claim_id: id,
            agreement_status: status,
          };

      let response = await adminAPI("post", "/agreementFtxStatus", data);

      if (response.status === "FAILED") throw new Error(response.message);
      await getClaim();
      await getNewAccount();

      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification`,
        description: error.message,
      });
    }
  };

  const updateManualPrefRisk = async (manual_preference_risk: number) => {
    try {
      const email = localStorage.getItem("email");
      const token = localStorage.getItem("token");

      let response = await adminAPI("post", "/editPrefRisk", {
        email,
        token,
        claim_id: id,
        prefRisk: manual_preference_risk,
      });
      if (response.status === "FAILED") throw new Error(response.message);
      await getClaim();
      await getNewAccount();

      api.success({
        message: `Setting Notification`,
        description: response.message,
      });
    } catch (error: any) {
      api.error({
        message: `Setting Notification`,
        description: error.message,
      });
    }
  };

  return (
    <DetailContext.Provider
      value={{
        claimData,
        surveyData,
        getClaim,
        api,
        updateAdminStatus,
        contextHolder,
        setClaimData,
        setSurveyData,
        aggreement,
        setAggreementData,
        refresh,
        setRefresh,
        account,
        prefRisk,
        setPrefRisk,
        openAgreement,
        setOpenAgreement,
        updateLevelDetailStatus,
        isAssigned,
        handleCreation,
        open,
        setOpen,
        preview,
        setPreview,
        isMintRequest,
        isClaimIdExist,
        minted,
        setIsTxSuccess,
        amountFlex,
        exportAgreement,
        openMintRequestPopup,
        setOpenMintRequestPopup,
        messageApi,
        contextHolderMessage,
        address,
        selfAssign,
        adminlevel,
        updateAgreementStatus,
        updateCelsiusAccountStatus,
        updateManualPrefRisk,
        celciusScheduleData,
        handleReschedule
      }}
    >
      {children}
    </DetailContext.Provider>
  );
};

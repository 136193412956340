import {
  Button,
  Collapse,
  Divider,
  Modal,
  Row,
  Typography,
  // message,
} from "antd";
import { FC, useContext } from "react";
import styles from "./styles.module.scss";
// import { adminAPI, baseURL } from "../../../api/admin";
// import axios from "axios";
// BcTokenFactory
// import { BcTokenFactory  } from "../../../../typechain/BcTokenFactory";
import TransferAgreement from "./TransferAgreement";
import ClaimGeneralInformation from "./ClaimGenenralInformation";
import { DetailContext, DetailContextProvider } from "./DetailContextProvider";
import Settings from "./Settings";
import ClaimInformation from "./ClaimInformation";
import AccountBalances from "./AccountBalance";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ProofOfClaim from "./ProofClaim";

const panelList = [
  {
    key: "transfer-agreement",
    label: "Transfer Agreement",
    content: <TransferAgreement/>,
    level: [1, 4],
  },
  {
    key: "claim-general",
    label: "Claim General Information",
    content: <ClaimGeneralInformation/>,
    level: [1, 2, 3],
  },
  {
    key: "claim-information",
    label: "Claim Information",
    content: <ClaimInformation />,
    level: [1, 2, 3],
  },
  {
    key: "account-balance",
    label: "Accounts & Balances",
    content: <AccountBalances/>,
    level: [1, 2, 3],
  },
  {
    key: "proof-claim",
    label: "Notice of Transfer",
    content: <ProofOfClaim/>,
    level: [1, 2, 3],
  },
];

const DetailLayout: FC = () => {
  const {
    preview,
    setPreview,
    contextHolderMessage,
    claimData,
    adminlevel,
    selfAssign,
    surveyData,
    contextHolder: contextHolderNotification,
  } = useContext(DetailContext);
  const openPanel = panelList.map((panel) => panel.key);
  let panelListUpdate = panelList;
  if (surveyData !== undefined) {
    if (!surveyData?.is_poc_filed || surveyData?.is_poc_filed === 'No') {
      panelListUpdate = [panelList[0], panelList[1], panelList[2], panelList[3]];
    }
  }

  return (
    <div className={styles["assignment-detail"]}>
      {contextHolderNotification}
      {contextHolderMessage}

      <div className={styles["detail-container"]}>
        <div className={styles["assigned-detail"]}>
          <Typography.Title level={4}>Assignment</Typography.Title>
          {claimData &&
          claimData["assigned_to"] &&
          claimData["assigned_to"] !== "" ? (
            <Typography.Text>
              This claim assigned to{" "}
              <Typography.Link style={{ color: "#03ABAB" }}>
                {claimData && claimData["assigned_to"]}
              </Typography.Link>
            </Typography.Text>
          ) : (
            <Button onClick={selfAssign}>Self Assign</Button>
          )}
        </div>
        <Collapse
          className={styles.container}
          defaultActiveKey={openPanel}
          expandIcon={() => null}
        >
          {panelListUpdate.map((panel) => (
            <>
              {panel.level.includes(adminlevel) ? (
                <Collapse.Panel header={panel.label} key={panel.key}>
                  {panel.content}
                </Collapse.Panel>
              ) : null}
            </>
          ))}
        </Collapse>
      </div>

      <Divider type="vertical" className={styles.divider} />

      {adminlevel !== 3 && <Settings />}

      <Modal
        open={!!preview}
        onCancel={() => setPreview(null)}
        centered
        footer={false}
        width={"80vw"}
        bodyStyle={{ height: "80vh" }}
      >
        <Row style={{ height: "100%" }}>
          {preview && (
            <DocViewer
              documents={[{ uri: preview }]}
              pluginRenderers={DocViewerRenderers}
            />
          )}
        </Row>
      </Modal>
    </div>
  );
};

const Detail: FC = () => (
  <DetailContextProvider>
    <DetailLayout />
  </DetailContextProvider>
);

export default Detail;

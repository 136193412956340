import { useState } from 'react';
import NoteList from '../../../../../components/NoteList';
import DOWNLOAD_ICON from '../../../../../statics/images/icon-download.svg';
import styles from '../styles.module.scss';
import {
  Button,
  Input,
  UploadFile,
  UploadProps,
  Upload,
} from 'antd';
import axios from 'axios';
import { baseURL } from '../../../../../api/admin';

const Remarks: React.FC<{
  updateLevelDetailStatus: any;
  name: string;
  claimData: any;
}> = (props) => {
  const { updateLevelDetailStatus, name, claimData } = props;

  const [file, setFile] = useState<UploadFile>();

  const handleChange: UploadProps['onChange'] = ({
    file: newFile,
  }) => {
    setFile(newFile);
  };
  
  const [text, setText] = useState<string | undefined>(undefined);
  return (
    <div className={styles.remarks}>
      <div className={styles.inputWrapper}>
        <Input
          onChange={(e) => setText(e.target.value)}
          placeholder="Add a remarks"
          className={styles.updateInput}
          suffix={
            <Upload
              listType='picture'
              customRequest={async (options) => {
                const { onSuccess, onError, file } : any = options;
                try {
                  const token = localStorage.getItem("token");
                  const formData = new FormData();
                  formData.append('file', file);
                  const response = await axios({
                    baseURL,
                    url: '/upload',
                    method: 'POST',
                    data: formData,
                    headers: {
                      Accept: '*/*',
                      'Content-Type':
                        'application/x-www-form-urlencoded',
                        Authorization: token
                    },
                  });
                  if (onSuccess){
                  let newFile = {...file}
                    if(response?.data?.fullPath) {
                      newFile.name= response?.data?.fullPath
                    }
                    setFile(newFile)
                    onSuccess(
                      baseURL +
                      '/files/' +
                      response?.data?.message?.replace(
                        'Uploaded the file successfully: ',
                        ''
                      )
                    );
                  }
                } catch (error: any) {
                  if (onError) onError(error);
                }
              }}
              method="POST"
              itemRender={() => null}
            >
              <img src={DOWNLOAD_ICON} alt="TRASH_ICON" />
            </Upload>
          }
        />
        <Button
          className={styles.updateButton}
          type="primary"
          onClick={() => {
            updateLevelDetailStatus(
              name,
              JSON.stringify({
                text,
                files: file?.name,
              })
            );
            setFile(undefined);
            setText(undefined);
          }}
        >
          Update
        </Button>
      </div>
      <div
        style={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}
      >
        {file && (
          <div>
            <span
              role="img"
              aria-label="file"
              className="anticon anticon-file"
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="file"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M534 352V136H232v752h560V394H576a42 42 0 01-42-42z"
                  fill="#e6f7ff"
                ></path>
                <path
                  d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216a42 42 0 0042 42h216v494z"
                  fill="#1890ff"
                ></path>
              </svg>
            </span>
            <span>{file.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Remarks;

import {Typography} from 'antd';
import useAccountBalance, { Props, ReceivedProps } from './hook';
import styles from './styles.module.scss';
import Balances from './Balances';
import TxHistory from './TxHistory';
import AccountStatement from './AccountStatement';
import Remarks from './Remarks';
import UpdatePrefrisk from './UpdatePrefrisk';
import NoteList from '../../../../components/NoteList';

const AccountBalancesLayout: React.FC<Props> = ({
  balanceData,
  prices,
  txData,
  updateLevelDetailStatus,
  isAssigned,
  surveyData,
  exportAgreement,
  preview,
  setPreview,
  claimData,
  updateManualPrefRisk,
  prefRisk,
}) => {
  console.log('claimData accounts_and_balances_remark', claimData?.accounts_and_balances_remark);

  const accounts_and_balances_remark = claimData && {
    id: 1,
    date: 'Jan 17, 2023',
    text: claimData?.accounts_and_balances_remark?.text,
    pdf: claimData?.accounts_and_balances_remark?.files[0],
  };
  console.log("surveyData", surveyData);
  return (
    <div className={styles.container}>
      {/* BALANCES */}
      <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Balances</Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <Balances
            balanceData={balanceData}
            prices={prices}
            isAssigned={isAssigned}
            updateLevelDetailStatus={updateLevelDetailStatus}
            surveyData={surveyData}
          />
        </div>
      </div>

      {/* Account statement */}
      <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Account Statement</Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <AccountStatement
            surveyData={surveyData}
            setPreview={setPreview}
          />
        </div>
      </div>

      {/* TxHistory */}
      <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Tx History</Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <TxHistory
              txData={txData}
              prices={prices}
              isAssigned={isAssigned}
              updateLevelDetailStatus={updateLevelDetailStatus}
              surveyData={surveyData}
              exportAgreement={exportAgreement}
              setPreview={setPreview}
          />
        </div>
      </div>

      {/* Calculated Preference risk */}
      <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>
            Manually updated Preference Risk
          </Typography.Text>
        </div>

        <div className={styles['block__content']}>
          <UpdatePrefrisk
            prefRisk={prefRisk}
            claimData={claimData}
            surveyData={surveyData}
            updateManualPrefRisk={updateManualPrefRisk}
            updateLevelDetailStatus={updateLevelDetailStatus}
            isAssigned={isAssigned}
          />
        </div>
      </div>

      {/* Remarks */}
      <div className={styles.block}>
        <div className={styles['block__title']}>
          <Typography.Text>Remarks</Typography.Text>
        </div>

        {(
          accounts_and_balances_remark &&
          (accounts_and_balances_remark.text !== undefined || accounts_and_balances_remark.pdf !== undefined)) && <NoteList
            dataList={accounts_and_balances_remark || {}}
            onDelete={() => {
              updateLevelDetailStatus('accounts_and_balances_remark', null);
            }}
          />}

        <div className={styles['block__content']}>
          <Remarks
            updateLevelDetailStatus={updateLevelDetailStatus}
            name='accounts_and_balances_remark'
            claimData={claimData}
          />
        </div>
      </div>
    </div>
  );
};

const AccountBalances: React.FC<ReceivedProps> = () => (
  <AccountBalancesLayout {...useAccountBalance()} />
);

export default AccountBalances;

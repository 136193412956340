export type ReceivedProps = Record<string, any>;

const useMintToken = (props: ReceivedProps) => {
  return {
    ...props,
  };
};

export type Props = ReturnType<typeof useMintToken>;

export default useMintToken;

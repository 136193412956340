import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
  Tag,
  Typography,
} from "antd";
import classNames from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiQuestionAnswerFill } from "react-icons/ri";
import REJECTED_ICON from "../../../../statics/images/icon-rejected.svg";
import VERIFIED_ICON from "../../../../statics/images/icon-verified.svg";
import PHONE_ICON from "../../../../statics/images/icon-phone.svg";
import MintRequestPopup from "../../MintRequestPopup";
import styles from "../styles.module.scss";
import useSettings, { Props, ReceivedProps } from "./hook";
import Title from "antd/es/typography/Title";
import { ADMIN_STATUS } from "../../../../common/constants";
import MintFTXRequestPopup from "../../MintFTXRequestPopup";
import moment from "moment-timezone";

const SettingLayout: FC<Props> = ({
  level,
  updateCelsiusAccountStatus,
  claimData,
  updateLevelDetailStatus,
  handleCreation,
  openMintRequestPopup,
  setOpenMintRequestPopup,
  updateAdminStatus,
  isClaimIdExist,
  surveyData,
  updateAgreementStatus,
  setRefresh,
  address,
  handleReschedule,
  claimModal,
  setClaimModal,
}) => {
  const [agreementNumber, setAgreementNumber] = useState<any>();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcquiredModal, setOpenAcquiredModal] = useState(false);

  const checkDisbledLv1 = useMemo(() => {
    if (
      claimData?.agreement_status === ADMIN_STATUS.TransferAgreementSigned &&
      claimData?.current_status === ADMIN_STATUS.level3_approved
    ) {
      return false;
    }
    return true;
  }, [claimData]);

  const checkDisbledLv4 = useMemo(() => {
    // if (claimData?.ftx_account_acquired === ADMIN_STATUS.AccountAcquired) {
    //   return false;
    // }
    return false;
  }, [claimData]);

  const [formReasonsReject] = Form.useForm();

  return (
    <>
      <Col span={7}>
        {/* {level === 1 && (
          <Row className={styles["setting-block"]}>
            <Col span={24}>
              <Typography.Title level={5}>Level 1 Admin</Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Text type="secondary">
                Only for level 1 admin
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text strong>
                Update Celsius Account Status
              </Typography.Text>
            </Col>
            <Row className={styles.row}>
              <Col>
                <Tag
                  className={styles.tag}
                  style={{
                    color: "#31B43E",
                  }}
                >
                  ● Account Acquired
                </Tag>
              </Col>
              <Col>
                <Switch
                  checked={
                    claimData?.ftx_account_acquired === "Account Acquired"
                  }
                  disabled={checkDisbledLv1}
                  onChange={(value) => {
                    if (value) setOpenAcquiredModal(true);
                  }}
                />
              </Col>
            </Row>
            <Row className={styles.row}>
              <Col>
                <Tag
                  className={styles.tag}
                  style={{
                    color: "#F04438",
                  }}
                >
                  ● Cannot Acquire Account
                </Tag>
              </Col>
              <Col>
                <Switch
                  checked={
                    claimData?.celsius_account_acquired ===
                    "Cannot Acquire Account"
                  }
                  disabled={checkDisbledLv1}
                  onChange={(value) => {
                    if (value) {
                      updateCelsiusAccountStatus("Cannot Acquire Account");
                    }
                  }}
                />
              </Col>
            </Row>
          </Row>
        )} */}
        {level <= 2 && (
          <Row className={styles["setting-block"]}>
            <Col span={24}>
              <Typography.Title level={5}>Level 2 Admin</Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Text type="secondary">
                Only for level 2 admin
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text strong>
                Update Application Status
              </Typography.Text>
            </Col>

            <Row className={styles.row} wrap={false}>
              <Col>
                <Row gutter={8} align="middle">
                  <Col>
                    <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
                  </Col>
                  <Col>
                    <Typography.Text>Rejected</Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Switch
                  disabled={isClaimIdExist}
                  checked={claimData?.admin_status === "Rejected"}
                  onChange={(value) => {
                    if (value) {
                      formReasonsReject.resetFields();
                      setOpenRejectModal(true);
                    }
                  }}
                />
              </Col>
            </Row>

            {!!claimData?.level2_reject_reasons?.length && (
              <Row className={styles.rowData}>
                {claimData.level2_reject_reasons.map((reason: string) => (
                  <Tag
                    className="fill"
                    style={{
                      backgroundColor: "#FFE8E8",
                      color: "#CF352A",
                      borderRadius: 36,
                      border: "none",
                      height: 28,
                      lineHeight: "28px",
                    }}
                  >
                    {reason}
                  </Tag>
                ))}
              </Row>
            )}

            <Row className={styles.row} wrap={false}>
              <Col>
                <Row gutter={8} align="middle">
                  <Col>
                    <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
                  </Col>
                  <Col>
                    <Typography.Text>Approved</Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Switch
                  disabled={!isClaimIdExist}
                  checked={claimData?.admin_status === "Approved"}
                  onChange={(value) => {
                    if (value) updateAdminStatus("Approved");
                  }}
                />
              </Col>
            </Row>
            {surveyData?.level3_verification_call_check !== 'Approved' && <Row className={styles.row}>
              {!!surveyData?.rescheduleData && <><Col span={12}>
                <Row gutter={8} align="middle">
                  <Col>
                    <Typography.Text>Last Rescheduled</Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col span={10}><div style={{textAlign: 'right'}}>{moment(surveyData?.rescheduleData).format("MMMM Do YYYY, hh:mm")}</div></Col></>}
              <Col span={24}>
                <Button onClick={handleReschedule} style={{ width: "100%" }}>
                  <img
                    style={{
                      display: "inline-block",
                      paddingBottom: "3px",
                      paddingRight: "6px",
                    }}
                    src={PHONE_ICON}
                    alt={"VERIFIED_ICON"}
                  />
                  Reschedule Call
                </Button>
              </Col>
            </Row>}
            <Row
              className={styles.row}
              wrap={false}
              // onClick={handleCreation}
            >
              <Button onClick={handleCreation} style={{ width: "100%" }}>
                {!address
                  ? "Connect"
                  : isClaimIdExist
                  ? "Requested"
                  : "Approve Fee"}
              </Button>
            </Row>
          </Row>
        )}
        {level === 4 && (
          <Row className={styles["setting-block"]}>
            <Col span={24}>
              <Typography.Title level={5}>Legal Admin</Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Text strong>Agreement status</Typography.Text>
            </Col>

            {claimData && (
              <>
                <Row className={styles.row} wrap={false}>
                  <Col>
                    <div
                      className={classNames(styles.statusWrapper, styles.filed)}
                    >
                      ● Transfer agreement filed with court
                    </div>
                  </Col>
                  <Col>
                    <Switch
                      disabled={checkDisbledLv4}
                      checked={[
                        "Transfer Agreement filed with Court",
                        "Transfer Rejected by Court",
                        "Transfer Approved by Court",
                      ].includes(claimData?.agreement_status)}
                      onChange={(value) => {
                        console.log("value ====", value);
                        if (value)
                          updateAgreementStatus({
                            status: "Transfer Agreement filed with Court",
                          });
                      }}
                    />
                  </Col>
                </Row>
                <>
                  {[
                    "Transfer Agreement filed with Court",
                    "Transfer Rejected by Court",
                    "Transfer Approved by Court",
                  ].includes(claimData?.agreement_status) && (
                    <>
                      <Row className={styles.row} wrap={false}>
                        <Col>
                          <div
                            className={classNames(
                              styles.statusWrapper,
                              styles.approved
                            )}
                          >
                            ● Transfer Approved by Court
                          </div>
                        </Col>
                        <Col>
                          <Switch
                            checked={
                              claimData?.agreement_status ===
                              "Transfer Approved by Court"
                            }
                            disabled={checkDisbledLv4}
                            onChange={(value) => {
                              console.log(value);
                              if (value) {
                                updateAgreementStatus({
                                  status: "Transfer Approved by Court",
                                });
                              }
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className={styles.row} wrap={false}>
                        <Col>
                          <div
                            className={classNames(
                              styles.statusWrapper,
                              styles.rejected
                            )}
                          >
                            ● Transfer Rejected by Court
                          </div>
                        </Col>
                        <Col>
                          <Switch
                            className={styles.rejected}
                            checked={
                              claimData?.agreement_status ===
                              "Transfer Rejected by Court"
                            }
                            disabled={checkDisbledLv4}
                            onChange={(value) => {
                              if (value)
                                updateAgreementStatus({
                                  status: "Transfer Rejected by Court",
                                  number: 0,
                                });
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              </>
            )}

            {claimData?.agreement_status === "Filed at Court" ? (
              <Row className={styles.row} wrap={false}>
                <Col>
                  <Tag
                    className="fill"
                    style={{
                      backgroundColor: "#EDF3C8",
                      color: "#98A933",
                    }}
                  >
                    Filed at Court
                  </Tag>
                </Col>
                <Col>
                  <BsFillCheckCircleFill
                    style={{ fontSize: "20px", color: "#037C7C" }}
                  />
                </Col>
              </Row>
            ) : null}
            {/* {!!claimData?.agreement_number ? (
              <Row
                className={styles.row}
                wrap={false}
                gutter={[4, 0]}
              >
                <Col>
                  <Typography.Text>
                    Approved claim number/docket number:
                  </Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>
                    {claimData?.agreement_number}
                  </Typography.Text>
                </Col>
              </Row>
            ) : null} */}
            {claimData?.agreement_status === "Transfer Agreement Signed" ? (
              <Row className={styles.row} wrap={false}>
                <div className={styles.badgeWrapper}>
                  Transfer Agreement Signed
                </div>
                <Col>
                  <BsFillCheckCircleFill
                    style={{ fontSize: "20px", color: "#037C7C" }}
                  />
                </Col>
              </Row>
            ) : null}
            {claimData?.agreement_status ===
            "Transfer Agreement filed with Court" ? (
              <Row className={styles.row} wrap={false}>
                <Col>
                  <div className={styles.badgeWrapper}>
                    Transfer Agreement filed with Court
                  </div>
                </Col>
                <Col>
                  <BsFillCheckCircleFill
                    style={{ fontSize: "20px", color: "#037C7C" }}
                  />
                </Col>
              </Row>
            ) : null}
            {claimData?.agreement_status ===
            "Awaiting Transfer Agreement Submission" ? (
              <Row className={styles.row} wrap={false}>
                <Col>
                  <div className={styles.badgeWrapper}>
                    Awaiting Transfer Agreement Submission
                  </div>
                </Col>
                <Col>
                  <BsFillCheckCircleFill
                    style={{ fontSize: "20px", color: "#037C7C" }}
                  />
                </Col>
              </Row>
            ) : null}

            {!claimData?.agreement_status ? (
              <Row className={styles.row} wrap={false}>
                <Col>
                  <div className={styles.badgeWrapper}>
                    Transfer agreement not generated
                  </div>
                </Col>
                <Col>
                  <RiQuestionAnswerFill
                    style={{ fontSize: "20px", color: "#037C7C" }}
                  />
                </Col>
              </Row>
            ) : null}
            {/* <Divider style={{ margin: 0 }} /> */}
            <Row
              className={styles.row}
              wrap={false}
              style={{ paddingTop: 8, paddingBottom: 0 }}
            >
              <Col>
                <Typography.Text strong>Docket Number</Typography.Text>
              </Col>
            </Row>
            <Row className={styles.row_fix} wrap={true}>
              <Col
                style={{
                  width: "100%",
                }}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  placeholder={
                    claimData?.agreement_number || "Agreement Number"
                  }
                  className={styles.inputClaimNumber}
                  onChange={(e) => setAgreementNumber(e)}
                />
              </Col>
            </Row>
            <Row className={styles.row_fix} wrap={false}>
              <Col
                style={{
                  width: "100%",
                }}
              >
                <Button
                  onClick={async () => {
                    await updateAgreementStatus({
                      status: "Transfer Agreement filed with Court",
                      number: agreementNumber,
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  className={styles.submitClaimNumber}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Row>
        )}
      </Col>

      <Modal
        open={openRejectModal}
        centered
        title="Reject Reasons"
        closable={false}
        footer={null}
        className={styles.reject_reasons_modal}
        onCancel={() => setOpenRejectModal(false)}
      >
        <Form
          layout="vertical"
          onFinish={async (values) => {
            updateAdminStatus("Rejected", values.reasons ?? []);
            setOpenRejectModal(false);
          }}
          form={formReasonsReject}
        >
          <Form.Item name="reasons">
            <Checkbox.Group
              options={[
                { label: "KYC Mismatch", value: "KYC Mismatch" },
                // { label: "Claim Bal Incorrect", value: "Claim Bal Incorrect" },
                // { label: "Tx Data Mismatch", value: "Tx Data Mismatch" },
                // { label: "Below Claim Amt", value: "Below Claim Amt" },
                // { label: "Other Claim Bal", value: "Other Claim Bal" },
                // { label: "Pref Risk Threshold", value: "Pref Risk Threshold" },
                // { label: "PoC Data Mismatch", value: "PoC Data Mismatch" },
                // { label: "Uploaded File Issue", value: "Uploaded File Issue" },
                {
                  label: "Customer Code Mismatch",
                  value: "Customer Code Mismatch",
                },
                {
                  label: "High Pref Risk",
                  value: "High Pref Risk",
                },
                {
                  label: "U&D Claim",
                  value: "U&D Claim",
                },
                {
                  label: "Bought Claim",
                  value: "Bought Claim",
                },
                {
                  label: "User Data Mismatch",
                  value: "User Data Mismatch",
                },
                { label: "Email Mismatch", value: "Email Mismatch" },
                { label: "Below Claim Amt", value: "Below Claim Amt" },
                { label: "Duplicate Claim", value: "Duplicate Claim" },
              ]}
            />
          </Form.Item>

          <hr />

          <Button type="primary" htmlType="submit">
            Reject
          </Button>
        </Form>
      </Modal>

      <MintFTXRequestPopup
        openMintRequestPopup={openMintRequestPopup}
        setOpenMintRequestPopup={setOpenMintRequestPopup}
        claim={claimData}
        survey={surveyData}
      />

      <Modal
        title={<Title level={2}> New Account Details</Title>}
        open={openAcquiredModal}
        onCancel={() => setOpenAcquiredModal(false)}
        width="min(360px, calc(100% - 32px))"
        centered
        footer={false}
        className={styles["modal-container"]}
      >
        <Form
          layout="vertical"
          className={styles["form-container"]}
          onFinish={async ({ newEmail, newPassword }: any) => {
            await updateCelsiusAccountStatus(
              claimData?.ftx_account_acquired === "Account Acquired"
                ? "Cannot Acquire Account"
                : "Account Acquired",
              newEmail,
              newPassword
            );
            setOpenAcquiredModal(false);
            setRefresh(true);
          }}
        >
          <Form.Item
            className={styles["form-container__item"]}
            label="New Account Email"
            name="newEmail"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Email is not a valid email!" },
            ]}
          >
            <Input autoComplete="username" />
          </Form.Item>
          <Form.Item
            className={styles["form-container__item"]}
            label="New Account Password"
            name="newPassword"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password autoComplete="current-password" />
          </Form.Item>
          <Form.Item className={styles["form-container__item"]}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const Settings: FC<ReceivedProps> = () => <SettingLayout {...useSettings()} />;

export default Settings;

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { omit } from '../../../../common/utils';
import { DetailContext } from '../DetailContextProvider';

export type ReceivedProps = {};

const useAccountBalance = () => {
  const {
    claimData,
    preview,
    setPreview,
    surveyData,
    updateLevelDetailStatus,
    exportAgreement,
    updateManualPrefRisk,
    prefRisk,
  } = useContext(DetailContext);

  const isAssigned = useMemo(() => {
    const email = localStorage.getItem('email');
    return !!email && email === claimData?.assigned_to;
  }, [claimData?.assigned_to]);

  const [prices, setPrices] = useState<any>([]);

  const getPrices = useCallback(async () => {
    try {
      const result = await fetch(
        'https://heimdallrwa.com/prices.json'
      ).then((response) => response.json());
      if (Array.isArray(result)) setPrices(result);
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    getPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const balanceData = useMemo(() => {
    return surveyData
      ? omit(surveyData, [
          'celsius_earn_balances', // earn
          'celsius_custody_balances', // custody
          'celsius_witheld_balances', // withheld
          'celsius_borrow_balances', // borrow])
        ])
      : {};
  }, [surveyData]);

  const txData = useMemo(() => {
    return surveyData
      ? omit(surveyData, [
          'celsius_tx_earn', // earn
          'celsius_tx_custody', // custody
          'celsius_excl_earn',
          'celsius_excl_custody',
          'celsius_pdf_earn',
          'celsius_pdf_custody',
        ])
      : {};
  }, [surveyData]);

  return {
    balanceData,
    prices,
    txData,
    isAssigned,
    surveyData,
    updateLevelDetailStatus,
    exportAgreement,
    preview,
    setPreview,
    claimData,
    updateManualPrefRisk,
    prefRisk,
  };
};

export type Props = ReturnType<typeof useAccountBalance>;

export default useAccountBalance;

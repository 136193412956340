import { Select, Tag } from "antd";
import { useContext } from "react";
import { RxDotFilled } from "react-icons/rx";
import { DetailContext } from "../DetailContextProvider";
import VERIFIED_ICON from "../../../../statics/images/icon-verified.svg";
import REJECTED_ICON from "../../../../statics/images/icon-rejected.svg";
import DOUBTFUL_ICON from "../../../../statics/images/icon-doubtful.svg";

export const UpdateStatus = ({ value }: any) => {
  const { isAssigned, updateLevelDetailStatus, surveyData, claimData } =
    useContext(DetailContext);
    console.log(surveyData?.[value], surveyData, '?.[value]?.[value]');
    
  return (
    <div>
      <Select
        placeholder="Select"
        onChange={async (val) => {
          const updateSurveyData = {
            ...surveyData,
            [value]: val,
          };
          await updateLevelDetailStatus(
            "survey",
            JSON.stringify(updateSurveyData)
          );
        }}
        value={surveyData?.[value]}
        disabled={!isAssigned}
      >
        <Select.Option value="Approved">
          <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
          &nbsp;Approved
        </Select.Option>
        <Select.Option value="Rejected">
          <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
          &nbsp;Rejected
        </Select.Option>
        <Select.Option value="Doubtful">
          <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
          &nbsp;Doubtful
        </Select.Option>
      </Select>
    </div>
  );
};

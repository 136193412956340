import React from 'react'
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import Header from './Header';
import Footer from './Footer';
import styles from "./index.module.scss";

function DefaultLayout() {
  return (
    <Layout className={styles.main}>
      <Header />
      <Layout.Content className="container container-content">
        <Outlet />
      </Layout.Content>
      <Footer />
    </Layout>
  )
}

DefaultLayout.propTypes = {}

export default DefaultLayout

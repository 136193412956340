import { DownOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import {
  Avatar,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Space,
  Typography,
} from 'antd';

import Logo from '../../statics/images/logo.svg';

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { adminAPI } from '../../api/admin';
import { setLevel } from '../../app/adminSlice';
import { RootState } from '../../app/store';
import styles from './index.module.scss';
import { useSwitchNetwork } from 'wagmi';

const { Header, Content, Footer, Sider } = Layout;

function DashboardPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log(useLocation(), 'useLocation()');
  
  const level = useSelector((state: RootState) => state.admin.level);
  const dispatch = useDispatch();

  const { switchNetwork, isSuccess } = useSwitchNetwork()

  useEffect(() => {
    if(switchNetwork) {
      switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID)|| 80001)
    }
  }, [switchNetwork])

  useEffect(() => {
    if(isSuccess) {
      window.location.reload()
    }
  
  }, [isSuccess])

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'g1',
        children: [
          {
            key: '/admin',
            label: 'Dashboard',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.66663 17.5H13.3333M9.99996 14.1667V17.5M3.33329 2.5H16.6666C17.5871 2.5 18.3333 3.24619 18.3333 4.16667V12.5C18.3333 13.4205 17.5871 14.1667 16.6666 14.1667H3.33329C2.41282 14.1667 1.66663 13.4205 1.66663 12.5V4.16667C1.66663 3.24619 2.41282 2.5 3.33329 2.5Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin');
            },
          },
          // {
          //   key: '/admin/celsius-claims',
          //   label: 'View Claims',
          //   icon: (
          //     <svg
          //       width="20"
          //       height="20"
          //       viewBox="0 0 20 20"
          //       fill="none"
          //       xmlns="http://www.w3.org/2000/svg"
          //     >
          //       <path
          //         d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
          //         stroke="#666666"
          //         strokeWidth="1.67"
          //         strokeLinecap="round"
          //         strokeLinejoin="round"
          //       />
          //       <path
          //         d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
          //         stroke="#666666"
          //         strokeWidth="1.67"
          //         strokeLinecap="round"
          //         strokeLinejoin="round"
          //       />
          //       <path
          //         d="M17.5 11.6667H11.6667V17.5H17.5V11.6667Z"
          //         stroke="#666666"
          //         strokeWidth="1.67"
          //         strokeLinecap="round"
          //         strokeLinejoin="round"
          //       />
          //       <path
          //         d="M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z"
          //         stroke="#666666"
          //         strokeWidth="1.67"
          //         strokeLinecap="round"
          //         strokeLinejoin="round"
          //       />
          //     </svg>
          //   ),
          //   onClick: () => {
          //     navigate('/admin/celsius-claims');
          //   },
          // },
          {
            key: '/admin/mint-token',
            label: 'Mint Token',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 4.16667C17.5 5.54738 14.1421 6.66667 10 6.66667C5.85786 6.66667 2.5 5.54738 2.5 4.16667M17.5 4.16667C17.5 2.78595 14.1421 1.66667 10 1.66667C5.85786 1.66667 2.5 2.78595 2.5 4.16667M17.5 4.16667V15.8333C17.5 17.2167 14.1667 18.3333 10 18.3333C5.83333 18.3333 2.5 17.2167 2.5 15.8333V4.16667M17.5 10C17.5 11.3833 14.1667 12.5 10 12.5C5.83333 12.5 2.5 11.3833 2.5 10"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/mint-token');
            },
          },
        ],
        label: 'Celsius',
        type: 'group',
      },
      {
        type: 'divider',
      },
      {
        key: 'g2',
        children: [
          {
            key: '/admin/view-ftx-claims',
            label: 'Dashboard',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 11.6667H11.6667V17.5H17.5V11.6667Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/view-ftx-claims');
            },
          },
          {
            key: '/admin/ftx-mint-token',
            label: 'Mint Token',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 4.16667C17.5 5.54738 14.1421 6.66667 10 6.66667C5.85786 6.66667 2.5 5.54738 2.5 4.16667M17.5 4.16667C17.5 2.78595 14.1421 1.66667 10 1.66667C5.85786 1.66667 2.5 2.78595 2.5 4.16667M17.5 4.16667V15.8333C17.5 17.2167 14.1667 18.3333 10 18.3333C5.83333 18.3333 2.5 17.2167 2.5 15.8333V4.16667M17.5 10C17.5 11.3833 14.1667 12.5 10 12.5C5.83333 12.5 2.5 11.3833 2.5 10"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/ftx-mint-token');
            },
          }
        ],
        label: 'FTX',
        type: 'group',
      },
      {
        type: 'divider',
      },
      {
        key: 'g2',
        children: [
          {
            key: '/admin/new-admin',
            label: 'New Admin',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6667 17.5V15.8333C16.6667 14.9493 16.3155 14.1014 15.6904 13.4763C15.0653 12.8512 14.2174 12.5 13.3334 12.5H6.66671C5.78265 12.5 4.93481 12.8512 4.30968 13.4763C3.68456 14.1014 3.33337 14.9493 3.33337 15.8333V17.5M13.3334 5.83333C13.3334 7.67428 11.841 9.16667 10 9.16667C8.15909 9.16667 6.66671 7.67428 6.66671 5.83333C6.66671 3.99238 8.15909 2.5 10 2.5C11.841 2.5 13.3334 3.99238 13.3334 5.83333Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/new-admin');
            },
          },
          {
            key: '/admin/view-admin',
            label: 'View Admin',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 7.5H17.5M7.5 17.5V7.5M4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/view-admin');
            },
          },
          {
            key: '/admin/history',
            label: 'History',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 7.5H17.5M7.5 17.5V7.5M4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/history');
            },
          },
          {
            key: '/admin/stats',
            label: 'Stats',
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 7.5H17.5M7.5 17.5V7.5M4.16667 2.5H15.8333C16.7538 2.5 17.5 3.24619 17.5 4.16667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5Z"
                  stroke="#666666"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            onClick: () => {
              navigate('/admin/stats');
            },
          },
        ],
        label: 'Administration',
        type: 'group',
      },
    ],
    [navigate]
  );

  useEffect(() => {
    (async () => {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');
      if (!email || !token) {
        navigate('/login', { replace: true });
      } else {
        const response = await adminAPI('post', '/verify', {
          email,
          token,
        });

        if (response.status === 'OK') {
          dispatch(setLevel(response.message.level));
        } else {
          navigate('/login', { replace: true });
        }
      }
    })();
  }, [dispatch, navigate]);

  return (
    <Layout hasSider>
      <Sider className={styles.slider} theme="light" width={240}>
        <div className={styles.logo}>
          <img src={Logo} alt="logo" />
        </div>
        <Divider
          style={{ margin: '0 16px', width: 'auto', minWidth: 'unset' }}
        />

        <Menu
          className={styles.menu}
          theme="light"
          mode="inline"
          defaultSelectedKeys={['/admin']}
          selectedKeys={
            [
              '/admin',
              '/admin/view-admin',
              '/admin/new-admin',
              '/admin/celsius-claims',
              '/admin/mint-token',
              '/admin/history',
              '/admin/view-ftx-claims',
              '/admin/ftx-mint-token',
              '/admin/ftx-claim',
              '/admin/stats'
            ].includes(pathname)
              ? [pathname]
              : (pathname?.includes('ftx')?['/admin/view-ftx-claims'] : ['/admin'])
          }
          items={items}
        />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 240 }}>
        <Header className={styles.header}>
          <Typography.Title level={4}>Dashboard</Typography.Title>
          <Space align="center" size={12}>
            <Space>
              <Avatar size={44} style={{ outline: '2px solid #03ABAB' }}>
                A
              </Avatar>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'dashboard',
                      label: 'Logout',
                      onClick: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('email');
                        navigate('/login', { replace: true });
                      },
                    },
                  ],
                }}
                trigger={['click']}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <strong
                    onClick={(e) => e.preventDefault()}
                    style={{ height: 'auto', lineHeight: '20px' }}
                  >
                    Admin
                    <DownOutlined style={{ marginLeft: 8 }} />
                  </strong>

                  <Typography.Text type="secondary">
                    Admin level {level && level}
                  </Typography.Text>
                </div>
              </Dropdown>
            </Space>
          </Space>
        </Header>
        <Content className={styles.content}>
          <Outlet />
        </Content>
        <Footer className={styles.footer}>
          <Typography.Text type="secondary">
            Copyright © 2023 by Heimdall RWA
          </Typography.Text>
        </Footer>
      </Layout>
    </Layout>
  );
}

DashboardPage.propTypes = {};

export default DashboardPage;

export const API_URL = "https://apimail.heimdallrwa.com/api/v1/mail";

export const APPROVAL_HEX = "0xa7b815c98465fe95fbd83842156c92c111a8223a11dd0ecef5ced0d0b7c01e41";

export const MINT_REQUEST_HEX = "0xe57b7892313cb2073522bea599b0c9e283897e404f37eba58c471e02375f358e";

export const ADMIN_HEX = "0x0000000000000000000000000000000000000000000000000000000000000000";

export const BURNER_HEX = "0x3c11d16cbaffd01df69ce1c404f6340ee057498f5f00246190ea54220576a848";

export const DENOMINATOR = 10000;

export const CELSIUS = 'CELSIUS';

export const FTX = 'FTX';

export const NATIVE_TOKEN_SYMBOL = 'MATIC';

export const ADMIN_STATUS = {
    level3_approved: 'level3_approved',
    Submitted: 'submitted',
    TransferAgreementSigned: "Transfer Agreement Signed",
    AccountAcquired: "Account Acquired",
    TransferApprovedbyCourt: "Transfer Approved by Court"
}

import { Button, Col, Row, Select, Tabs, TabsProps, Typography } from "antd";
import { useCallback, useMemo } from "react";
import CryptoIcon from "../../../../../components/CryptoIcon";
import DOWNLOAD_ICON from "../../../../../statics/images/icon-download.svg";
import DOUBTFUL_ICON from "../../../../../statics/images/icon-doubtful.svg";
import REJECTED_ICON from "../../../../../statics/images/icon-rejected.svg";
import VERIFIED_ICON from "../../../../../statics/images/icon-verified.svg";
import EYE_ICON from "../../../../../statics/images/icon-eye.svg";
import styles from "../styles.module.scss";
import Table, { ColumnsType } from "antd/es/table";
import { SurveyData } from "../../DetailContextProvider";
import { baseURL } from "../../../../../api/admin";

const TxHistory: React.FC<{
  txData: Record<string, any>;
  prices: any;
  isAssigned: boolean;
  surveyData?: SurveyData;
  exportAgreement: () => void;
  updateLevelDetailStatus: (check: string, status: string) => Promise<void>;
  setPreview: (value: string | null) => void;
}> = ({
  txData,
  prices,
  isAssigned,
  surveyData,
  updateLevelDetailStatus,
  exportAgreement,
  setPreview,
}) => {
  const getMatchedCoin = useCallback(
    (value: any) => {
      const matchedCoin =
        prices &&
        (prices
          .find((item: any) => `${item.price}` === `${value}`)
          ?.coin?.toLowerCase() ||
          "btc");
      return matchedCoin;
    },
    [prices]
  );

  const renderTabContent = useCallback(
    (data: string) => {
      const baseData = txData[data];

      const columns: ColumnsType<Record<string, any>> = [
        {
          title: "Date",
          dataIndex: "TxsDate",
          key: "TxsDate",
          render: (_, { TxsDate }, index) => (
            <Row key={index} className={styles["table-row"]}>
              <Row align="middle" gutter={8}>
                <Col>
                  <Typography.Text>{TxsDate}</Typography.Text>
                </Col>
              </Row>
            </Row>
          ),
        },
        {
          title: "Type",
          dataIndex: "Type",
          key: "Type",
        },
        {
          title: "Token",
          dataIndex: "token",
          key: "token",
          render: (_, { price, Coin }) => (
            <Row gutter={4}>
              <Col>
                <CryptoIcon name={getMatchedCoin(Coin)} />
              </Col>
              <Col>
                <Typography.Text>
                  {getMatchedCoin(Coin).toUpperCase()}
                </Typography.Text>
              </Col>
            </Row>
          ),
        },
        {
          title: "Value",
          key: "value",
          dataIndex: "value",
          render: (_, item) => (
            <Typography.Text>${item["Value"]}</Typography.Text>
          ),
        },
        {
          title: "Status",
          key: "status",
          render: (_, item, index) => {
            return (
              <Select
                placeholder="Select"
                className={styles.select}
                onChange={async (value) => {
                  const updateSurveyData = {
                    ...surveyData,
                  };
                  type Key = keyof typeof updateSurveyData;
                  const curType = data as Key;

                  if (
                    updateSurveyData[curType] &&
                    Array.isArray(updateSurveyData[curType])
                  ) {
                    updateSurveyData[curType] = baseData.map(
                      (i: Record<string, any>, id: number) =>
                        index === id
                          ? {
                              ...i,
                              level3_details_check: value,
                            }
                          : i
                    );
                  }

                  await updateLevelDetailStatus(
                    "survey",
                    JSON.stringify(updateSurveyData)
                  );
                }}
                value={item?.level3_details_check}
                disabled={!isAssigned}
              >
                <Select.Option value="Approved">
                  <img src={VERIFIED_ICON} alt={"VERIFIED_ICON"} />
                  &nbsp;Approved
                </Select.Option>
                <Select.Option value="Rejected">
                  <img src={REJECTED_ICON} alt={"REJECTED_ICON"} />
                  &nbsp;Rejected
                </Select.Option>
                <Select.Option value="Doubtful">
                  <img src={DOUBTFUL_ICON} alt={"DOUBTFUL_ICON"} />
                  &nbsp;Doubtful
                </Select.Option>
              </Select>
            );
          },
        },
      ];

      const suffix = data === "celsius_tx_earn" ? "earn" : "custody";

      return (
        <>
          <Table pagination={false} columns={columns} dataSource={baseData} />

          <Row className={styles["table-row"]}>
            <Col span={24}>
              <Typography.Text strong>
                {`${suffix.charAt(0).toUpperCase() + suffix.slice(1)}`} Tx Excel
                File
              </Typography.Text>
            </Col>
            <Row>
              <Col span={24}>
                <div className={styles["file-action-container"]}>
                  {txData?.[`celsius_excl_${suffix}`] ? (
                    <>
                      <Button
                        className={"file-action"}
                        type="link"
                        onClick={() =>
                          setPreview(
                            baseURL +
                              "/files?name=" +
                              encodeURIComponent(
                                txData?.[`celsius_excl_${suffix}`]?.[0]?.name
                              ) +
                              "&token=" +
                              localStorage.getItem("token")
                          )
                        }
                      >
                        <img src={EYE_ICON} alt="EYE_ICON" />
                        View
                      </Button>

                      <Button className={"file-action"} type="link">
                        <a
                          href={baseURL +
                            "/files?name=" +
                            encodeURIComponent(
                              txData?.[`celsius_excl_${suffix}`]?.[0]?.name
                            ) +
                            "&token=" +
                            localStorage.getItem("token")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={DOWNLOAD_ICON} alt="DOWNLOAD_ICON" />
                          Download
                        </a>
                      </Button>
                    </>
                  ) : (
                    // </a>
                    <Typography.Text>N/A</Typography.Text>
                  )}
                </div>
              </Col>
            </Row>
          </Row>

          <Row className={styles["table-row"]}>
            <Col span={24}>
              <Typography.Text strong>
                {`${suffix.charAt(0).toUpperCase() + suffix.slice(1)}`} Tx PDF
                File
              </Typography.Text>
            </Col>
            <Row>
              <Col span={24}>
                <div className={styles["file-action-container"]}>
                  {txData?.[`celsius_pdf_${suffix}`] ? (
                    <>
                      <Button
                        className={"file-action"}
                        type="link"
                        onClick={() =>
                          setPreview(
                            baseURL +
                              "/files?name=" +
                              encodeURIComponent(
                                txData?.[`celsius_pdf_${suffix}`]?.[0]?.name
                              ) +
                              "&token=" +
                              localStorage.getItem("token")
                          )
                        }
                      >
                        <img src={EYE_ICON} alt="EYE_ICON" />
                        View
                      </Button>

                      <Button className={"file-action"} type="link">
                        <a
                          href={baseURL +
                            "/files?name=" +
                            encodeURIComponent(
                              txData?.[`celsius_pdf_${suffix}`]?.[0]?.name
                            ) +
                            "&token=" +
                            localStorage.getItem("token")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={DOWNLOAD_ICON} alt="DOWNLOAD_ICON" />
                          Download
                        </a>
                      </Button>
                    </>
                  ) : (
                    // </a>
                    <Typography.Text>N/A</Typography.Text>
                  )}
                </div>
              </Col>
            </Row>
          </Row>
        </>
      );
    },
    [
      txData,
      getMatchedCoin,
      isAssigned,
      surveyData,
      updateLevelDetailStatus,
      setPreview,
    ]
  );

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: `Earn Account`,
        children: renderTabContent("celsius_tx_earn"),
      },
      {
        key: "2",
        label: `Custody Account`,
        children: renderTabContent("celsius_tx_custody"),
      },
    ],
    [renderTabContent]
  );

  return (
    <>
      <Tabs defaultActiveKey="1" items={items} />
    </>
  );
};

export default TxHistory;

import axios from 'axios';
import EthCrypto from 'eth-crypto';
import { adminAPI } from '../api/admin';
import { opnxAPI } from '../api/opnx';

const hasOwn = {}.hasOwnProperty;

export const classNames: (...params: any) => string = (
  ...params: any
) => {
  const classes = [];

  for (let i = 0; i < params.length; i++) {
    const arg = params[i];
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === 'string' || argType === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      if (arg.length) {
        const inner = classNames(null, arg);
        if (inner) {
          classes.push(inner);
        }
      }
    } else if (argType === 'object') {
      if (arg.toString === Object.prototype.toString) {
        for (const key in arg) {
          if (hasOwn.call(arg, key) && arg[key]) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.join(' ');
};

export const strimId = (id: string) =>
  `${id.slice(0, 6)}...${id.slice(-6)}`;

export const survey_obj = (survey: string) => {
  var surveyString = survey.replace(/\\/g, '');
  // console.log(JSON.parse(surveyString));
  return JSON.parse(surveyString);
};

export const truncDigits = (inputNumber: number, digits: number) => {
  const fact = 10 ** digits;
  return Math.floor(inputNumber * fact) / fact;
};

export const getFlexPriceAverange = async () => {
  let claim = await adminAPI("post", "/getPriceAverange", {
    symbol: "FLEX",
    slug: "flex",
  });
  return (claim.message || 0) / 100;
}; 

export const getOxPrice = async () => {
  try {
    let data = await opnxAPI("get", "/v3/markets?marketCode=OX-USDT");
    return +data.data[0].markPrice;
  } catch (error) {
    console.log("error =", error);
    return 0;
  }
};

export const calCulateFlexAmount = async (claimAmount: number) => {
  const LOC_FACTOR = 1.01;
  const flexPrice = await getOxPrice();
  const MULTIPLIER = 1 / (flexPrice * 300) + 0.15;

  const result = Math.pow((claimAmount * MULTIPLIER), LOC_FACTOR);
  return result;
};

export const omit = (
  base: Record<string, any>,
  keys: string[]
): Record<string, any> => {
  return Object.keys(base).reduce(
    (arr: Record<string, any>, cur: string) => {
      if (
        typeof base[cur] === 'object' &&
        !Array.isArray(base[cur]) &&
        base[cur] !== null
      ) {
        return {
          ...arr,
          ...omit(base[cur], keys),
        };
      }

      if (keys.includes(cur)) {
        return {
          ...arr,
          [cur]: base[cur],
        };
      }

      return arr;
    },
    {}
  );
};

export const encrypt = async(text: string) => {   
  const encrypted = await EthCrypto.encryptWithPublicKey(
    process.env.REACT_APP_PUBLIC_KEY!, // publicKey
    text // message
  );
  
  // console.log("encrypted =", encrypted);
  return JSON.stringify(encrypted);
}

export const decrypt = async(encrypted: string) => {
  const message = await EthCrypto.decryptWithPrivateKey(
    process.env.REACT_APP_PRIVATE_KEY!, // privateKey
    JSON.parse(encrypted) // encrypted-data 
  );  

  // console.log("decrypted =", message);
  return message;
} 

export const isJson = (str: string) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}
import React, { FC, useRef } from 'react';
import styles from './index.module.scss';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Input } from 'antd';

type Step1Type = {
  onNext: () => any;
  claim: any,
  survey: any,
  claim_id: string,
  setSignature: any,
  setPlace: any,
}

const Step1:FC<Step1Type> = ({ onNext, claim, survey, claim_id, setSignature, setPlace }) => {
  const signCanvas = useRef() as React.MutableRefObject<any>;
  // console.log(signCanvas.current.getTrimmedCanvas().toDataURL());
  const next = () => {
    signCanvas && setSignature(signCanvas.current.getTrimmedCanvas().toDataURL())
    onNext();
  };

  function agreementContent(claim: any, survey: any) {
    console.log(claim,survey)
    return (
      <>
        <h1>UNITED STATES BANKRUPTCY COURT</h1>
        <p>Celsius Ch.11 in Southern District of NY</p>

          <ul>
            <li>
              In re: <span>{survey && survey.legal_name}</span>
            </li>
            <li>
            Case Number:
              <span>
                {claim && claim.claim_id}
              </span>
            </li>
          </ul>
        <br/>
        <h1>TRANSFER OF CLAIM OTHER THAN FOR SECURITY</h1>
        <p>A CLAIM HAS BEEN FILED IN THIS CASE or deemed filed under 11 U.S.C. § 1111(a). Transferee
        hereby gives evidence and notice pursuant to Rule 3001(e)(2), Fed. R. Bankr. P., of the transfer, other
        than for security, of the claim referenced in this evidence and notice.</p>
      </>
    )
  }

  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTitle}>
          Transfer Agreement
        </div>
        <div className={styles.contentSubTitle}>
          <span>
            Please read and sign the agreement.
          </span>
          <span>
            You also need to provide ERC-20 Address for the token to be sent to.
          </span>
        </div>

        <div className={styles.contentInfo}>
          {agreementContent(claim, survey)}
          <ul>
            <li>
                Agreement Content for Claim ID:
              <span>
                {claim_id}
              </span>
            </li>
            <li>
              Name: <span>{survey && survey.legal_name}</span>
            </li>
          </ul>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Please sign to agree with the agreement <span className={styles.formLabelRequired}>*</span></div>
          <div className={styles.formValue}>
            <SignatureCanvas
              penColor='green'
              canvasProps={{ className: styles.formSignature}}
              ref={signCanvas}
            />
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Date and Time</div>
          <div className={styles.formValue}>
            Tue Mar 14 2023 08:04:17 GMT+0700 (Indochina Time)
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formLabel}>Place <span className={styles.formLabelRequired}>*</span></div>
          <div className={styles.formValue}>
            <Input className={styles.formInput} name="input" onChange={(e) => setPlace(e.target.value)}/>
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        <Button onClick={next} className={styles.submit}>Next</Button>
      </div>
    </>
  )
}

export default Step1

import { Input, notification, Space, Table, Tag, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./index.module.scss";

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/es/table/interface";
import REJECTED_ICON from "../../../statics/images/icon-rejected.svg";
import IconSearch from "../../../statics/images/icon-search.svg";
import VERIFIED_ICON from "../../../statics/images/icon-verified.svg";
import { CELSIUS } from "../../../common/constants";
import debounce from "lodash.debounce";
import { useNavigate, useSearchParams } from "react-router-dom";
import { adminAPI } from "../../../api/admin";

interface DataType {
  key: React.Key;
  id: string;
  automatedStatus: string;
  adminStatus: string;
  contactEmail: string;
  userType: string;
  legalName: string;
  isUSA: string;
  country: string;
  assignedTo: string;
}

interface Claim {
  admin_status: string;
  agreement: string;
  agreement_status: string;
  assigned_to: string;
  claim_id: string;
  claim_type: string;
  is_minted: boolean;
  level3_details_check_1: string;
  level3_details_check_2: string;
  level3_details_check_3: string;
  level3_details_check_4: string;
  mint_transaction: string;
  note: string;
  status: string;
  submit_status: string;
  survey: string;
  __v: number;
  _id: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    width: 180,
    fixed: "left",
  },
  {
    title: "Automated Admin Status",
    key: "automatedStatus",
    render: ({ automatedStatus }: DataType) => {
      return (
        <Tag
          className="fill"
          style={{
            backgroundColor:
              automatedStatus === "Preference" ? "#FFE9D4" : "#FFEECB",
            color: automatedStatus === "Preference" ? "#EF7F18" : "#DB9712",
          }}
        >
          {automatedStatus}
        </Tag>
      );
    },
    width: 160,
  },
  {
    title: "Level 2 Admin Status",
    key: "adminStatus",
    ellipsis: true,
    render: ({ adminStatus }: DataType) => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {adminStatus && (
            <img
              src={adminStatus === "Approved" ? VERIFIED_ICON : REJECTED_ICON}
              alt="STATUS_ICON"
            />
          )}
          {adminStatus || "Pending"}
        </div>
      );
    },
    width: 140,
  },
  {
    title: "Contact Email",
    dataIndex: "contactEmail",
    key: "contactEmail",
    ellipsis: true,
    width: 200,
  },
  // {
  //   title: 'User Type',
  //   dataIndex: 'userType',
  //   key: 'userType',
  //   ellipsis: true,
  //   width: 120,
  // },
  {
    title: "Legal Name",
    dataIndex: "legalName",
    key: "legalName",
    ellipsis: true,
    width: 120,
  },
  // {
  //   title: 'IS USA',
  //   key: 'isUSA',
  //   ellipsis: true,
  //   render: ({ isUSA }: DataType) => {
  //     return (
  //       <Tag
  //         style={{
  //           backgroundColor: 'transparent',
  //           color: isUSA === 'No' ? ' #CF352A' : '#31B43E',
  //         }}
  //       >
  //         ● {isUSA.toUpperCase()}
  //       </Tag>
  //     );
  //   },
  //   width: 120,
  // },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    ellipsis: true,
    width: 120,
  },
  {
    title: "Assigned to",
    dataIndex: "assignedTo",
    key: "assignedTo",
    ellipsis: true,
    width: 120,
  },
];

function TableClaimData() {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { current = 1, size = 10 } = Object.fromEntries(
    Array.from(searchParams)
  );
  const [api, contextHolder] = notification.useNotification();

  const [claims, setClaims] = useState<Claim[]>([]);
  const [claimsCount, setClaimCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const data = useMemo(() => {
    return claims.map((claim) => {
      const detailSurvey = JSON.parse(claim.survey);
      return {
        key: claim._id,
        id: claim.claim_id,
        automatedStatus: claim.status,
        adminStatus: claim.admin_status,
        contactEmail: detailSurvey.contact_email as string,
        userType: detailSurvey.user_type as string,
        legalName: detailSurvey.legal_name as string,
        isUSA: detailSurvey.is_usa as string,
        country: detailSurvey.country as string,
        assignedTo: claim.assigned_to,
      };
    });
  }, [claims]);

  const get_claims_count = useCallback(async () => {
    try {
      const response = await adminAPI("post", "/claimsCount", {
        contain_rejected: 0,
        filter: JSON.stringify({
          claim_type: CELSIUS,
        }),
      });
      if (response.status === "FAILED" || !response.status) {
        throw new Error("Something wrong with your request");
      }
      setClaimCount(response.message);
    } catch (error: any) {
      api.error({
        message: `Notification`,
        description: error.message,
      });
    }
  }, [api]);

  const debouncedResults = useMemo(() => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const paramsObject = Object.fromEntries(Array.from(searchParams));
      paramsObject[`search`] = e.target.value;

      setSearchParams(paramsObject);
    };
    return debounce(handleChange, 300);
  }, [searchParams, setSearchParams]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<DataType> | SorterResult<DataType>[],
    extra: TableCurrentDataSource<DataType>
  ) => {
    const paramsObject = Object.fromEntries(Array.from(searchParams));

    switch (extra.action) {
      case "paginate": {
        if (pagination.current)
          paramsObject.current = pagination.current.toString();

        if (pagination.pageSize)
          paramsObject.size = pagination.pageSize.toString();
        break;
      }

      default:
        break;
    }

    setSearchParams(paramsObject);
  };

  const getClaimsHandler = useCallback(
    async (controller: AbortController) => {
      try {
        setLoading(true);

        const {
          current = 1,
          size = 10,
          search = "",
        } = Object.fromEntries(Array.from(searchParams));
        const token = localStorage.getItem("token");

        const response = await await adminAPI("post", "/claims", {
          token,
          limit: +size,
          offset: (+current - 1) * +size,
          contain_rejected: 0,
          keywords: search,
          filter: JSON.stringify({
            claim_type: CELSIUS,
          }),
        });

        if (!response.message)
          throw new Error("Something wrong with your request");

        if (!controller.signal.aborted) setClaims(response.message);
      } catch (error: any) {
        api.error({
          message: `Notification`,
          description: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [api, searchParams]
  );

  useEffect(() => {
    get_claims_count();
  }, [get_claims_count]);

  useEffect(() => {
    const controller = new AbortController();
    getClaimsHandler(controller);

    return () => {
      controller.abort();
    };
  }, [getClaimsHandler]);

  return (
    <div className={styles.container}>
      {contextHolder}
      <Typography.Title level={3}>List of Claims</Typography.Title>
      <Space.Compact size="large" block>
        <Input
          suffix={<img src={IconSearch} width={20} alt="IconSearch" />}
          placeholder="Search here"
          size="large"
          onChange={debouncedResults}
        />
        {/* <Select
          size="large"
          placeholder="Filter by"
          options={[
            { value: "Claim Status", label: "Jack" },
            { value: "Bankruptcy Case", label: "Lucy" },
          ]}
          style={{ width: "20%", marginLeft: "10px" }}
        /> */}
      </Space.Compact>
      <div className={styles["table-container"]}>
        <Table
          columns={columns}
          dataSource={data}
          bordered={false}
          pagination={{
            position: ["bottomCenter"],
            pageSize: +size,
            current: +current,
            total: claimsCount,
          }}
          onRow={({ id }) => {
            return {
              onClick: () => {
                navigation('/admin/'+id);
              },
            };
          }}
          onChange={handleTableChange}
          loading={loading}
        />
      </div>
    </div>
  );
}

TableClaimData.propTypes = {};

export default TableClaimData;
